import classNames from "classnames";
import styles from "./styles.module.scss";

export const Testimonial = (props) => {
  const {
    wrapClassName,
    overlayClassName,
    background,
    testimony,
    testifier,
    testifierImg,
    quoteClassName,
    quoteByClassName,
    isSideWays = false,
  } = props;
  return (
    <>
      {isSideWays ? (
        <div
          className={classNames(styles.testimonialSideWays, wrapClassName)}
          style={{ backgroundImage: "url(" + background + ")" }}
        >
          <div className={classNames(styles.overlay, overlayClassName)}></div>
          <div className={styles.contentArea}>
            <div className={styles.wrap}>
              <h3 className={classNames(styles.quote, quoteClassName)}>
                {testimony}
              </h3>
              <div className={styles.testifierArea}>
                <span className={styles.separator}>&#8212;</span>
                <h4 className={classNames(styles.quoteBy, quoteByClassName)}>
                  {testifier}
                </h4>
              </div>
            </div>
            {testifierImg && (
              <div className={styles.testifierImg}>
                <img src={testifierImg} alt={testifier} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={classNames(styles.testimonial, wrapClassName)}
          style={{ backgroundImage: "url(" + background + ")" }}
        >
          <div className={classNames(styles.overlay, overlayClassName)}></div>
          <div className={styles.contentArea}>
            <div className={styles.wrap}>
              <h3 className={styles.quote}>{testimony}</h3>
              <div className={styles.testifierArea}>
                {testifierImg && (
                  <div className={styles.testifierImg}>
                    <img src={testifierImg} alt={testifier} />
                  </div>
                )}
                <span className={styles.separator}>&#8212;</span>
                <h4 className={styles.quoteBy}>{testifier}</h4>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
