import { Helmet } from "react-helmet";

import { TrainBanner } from "component/Train/TrainBanner";
import { TrainBannerBelow } from "component/Train/TrainBannerBelow";
import { FutureAiLeaders } from "component/Train/FutureAiLeaders";
import { BringAi } from "component/Train/BraingAi";
import { BringAiCards } from "component/Train/BringAiCards";
import { Offerings } from "component/Train/Offerings";
import { BoardAdvisors } from "component/Train/BoardAdvisors";
import { PartnersCollaborators } from "component/Train/PartnersCollaborators";
import { TrainDisclaimer } from "component/Train/TrainDisclaimer";

export const Train = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="The Coding School awarded $3 million grant"
        />
        <script src="https://www.cognitoforms.com/f/iframe.js"></script>
      </Helmet>
      <div id="trainPage">
        <TrainBanner />
        <TrainBannerBelow />
        <FutureAiLeaders />
        <Offerings />
        <BringAi />
        <BringAiCards />
        <BoardAdvisors />
        <PartnersCollaborators />
        <TrainDisclaimer />
      </div>
    </>
  );
};
