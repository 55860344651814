import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import styles from './styles.module.scss'

export const Involved = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="The Coding School awarded $3 million grant"
        />
      </Helmet>
      <div classNames={styles.involved} id="Involved">
        <div className={styles.getInvolvedBlock}>
          <h1>Get Involved</h1>
        </div>
        <div className="container mt-80">
          <div className="row">
            <div className={classNames(styles.involvedRow, 'col-3')}>
              Partner with us
            </div>
            <div className={classNames(styles.involvedRow, 'col-3')}>
              Bring a course to your school
            </div>
            <div className={classNames(styles.involvedRow, 'col-3')}>
              Teacher or mentor
            </div>
            <div className={classNames(styles.involvedRow, 'col-3')}>
              volunteer
            </div>
          </div>

          <div className={classNames(styles.details, 'mt-60')}>
            <h2>
              Please fill out the form below if you are interested in learning
              more about our programs and how to get involved!
            </h2>
          </div>

          <iframe className={styles.invovledForm} title="Involved Page Form" src="https://airtable.com/embed/appdS9n7K25uof6m4/shrK1bkwY23NI7kkf?backgroundColor=tealLight"></iframe>

        </div>
      </div>
    </>
  )
}
