export const programDetails = {
  title: 'This five-week program consists of two main components:',
  card: [
    {
      cardTitle: 'Weeks 1-2:',
      cardDesc: [
        'Students receive intensive training in data science, developing all the skills needed to conduct research at a college level including coding skills in R, data visualizations, data cleaning, data analysis and so much more.',
      ],
    },
    {
      cardTitle: 'Weeks 3-5:',
      cardDesc: [
        'Students put their data science skills to action while assisting PhD researchers with real-life research projects that are overseen by university professors and industry professionals.',
      ],
    },
  ],
  disclaimer:
    'Upon completion of the program, students are presented with a certificate and have a research project to showcase on college apps.',
}
