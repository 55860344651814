import { Fragment } from "react";
import classNames from "classnames";

import { Banner } from "component/SummerPrograms/Banner";
import { Offerings } from "component/Courses/Offerings";
import { EmergingCourse } from "component/Courses/EmergingCourse";
import { Testimonial } from "component/SummerPrograms/EmergingTechPrograms/components/Testimonial";
import { CourseComponents } from "component/Courses/CourseComponents";
import { BecomeFutureLeader } from "component/SummerPrograms/BecomeFutureLeader";
import { CourseBenefits } from "component/Courses/CourseBenefits";
import { Accredited } from "component/Courses/Accredited";
import { ForSchools } from "component/TrainCourse/ForSchools";

import bg from "assets/img/involved/volunteer.png";
import bg2 from "assets/img/about/leadership-bg.jpg";
import bg3 from "assets/img/summer-programs/uni-quantum-career.jpg";

import {
  banner,
  offerings,
  emergingTech,
  courseComponents,
  courseBenefits,
  accredited,
  forSchools,
  elevateLearning,
} from "data/courses";

import styles from "./styles.module.scss";

export const Courses = () => {
  return (
    <div className={styles.courses}>
      <Banner
        data={banner}
        isCourses={true}
        bannerClassName={styles.coursesBanner}
      />
      <Offerings data={offerings} />
      <div className={styles.testimony}>
        <div className={classNames(styles.containerBig, "container")}>
          <Testimonial
            background={bg}
            testimony="I am so lucky that I am learning quantum computing as a high school student. Having gone through this course, I feel that I can overcome any academic challenge that awaits in my high school, and later college, STEM classes. I feel so powerful, and this course further developed my interest in conducting research in the field."
            testifier="11th grade student, New York"
          />
        </div>
      </div>
      <EmergingCourse data={emergingTech} />

      <CourseBenefits data={elevateLearning} wrapperClassName={styles.elevateLearningWrapper} />

      <div className={classNames(styles.testimony, "py-0")}>
        <div className={classNames(styles.containerBig, "container")}>
          <Testimonial
            background={bg2}
            testimony="Before this course, I was a bit nervous about pursuing a career in a male-dominated field because I didn't really feel welcomed but after taking this course I don't feel as afraid... Because of this course, I am considering pursuing a master's in artificial intelligence."
            testifier="12th grade student, Illinois"
          />
        </div>
      </div>
      <CourseComponents data={courseComponents} />

      <BecomeFutureLeader
        data={
          <Fragment>
            We Train <span>Future Leaders</span> in Emerging Technologies
          </Fragment>
        }
      />
      <CourseBenefits data={courseBenefits} />
      <Accredited data={accredited} />
      <div className={classNames(styles.testimony, "py-0")}>
        <div className={classNames(styles.containerBig, "container")}>
          <Testimonial
            background={bg3}
            testimony="Qubit by Qubit has given me the opportunity to be introduced to quantum computing at a pace that is digestible and has provided insight on where I see myself in the field of quantum computing…Being surrounded by students that share your similar background is very empowering and has definitely supported my learning process."
            testifier="University student, California"
          />
        </div>
      </div>
      <ForSchools
        schoolText={forSchools.schoolText}
        buttonLink={forSchools.buttonLink}
        logos={forSchools.logos}
        isCourses={true}
      />
    </div>
  );
};
