import classNames from "classnames";
import ReactRotatingText from "react-rotating-text";

import styles from "./styles.module.scss";

export const WhoShouldJoin = (props) => {
  const { words, text } = props;
  return (
    <div className={styles.whoShouldJoin}>
      <div className="container">
        <div className="shadowedBox text-center">
          <h3 className={styles.heading}>
            <span className={classNames(styles.textThin, "d-block")}>
              WHO SHOULD TAKE
            </span>{" "}
            THIS <span className="text-blue-gradient">COURSE</span>
          </h3>
          <div
            className={classNames(
              styles.headingContainer,
              "bg-blue-gradient px-15 py-15 rounded"
            )}
          >
            <h4>
              If you want to become{" "}
              <ReactRotatingText className="text-bold" items={words} /> you
              should take this course!
            </h4>
          </div>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};
