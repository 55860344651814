import { Fragment } from "react";

import aws from "assets/img/common/aws-logo.png";

export const banner = {
  bannerTitle: "AI will change",
  rotatingWords: [
    "Healthcare",
    "Finance",
    "Policymaking",
    "Conservation",
    "Defense",
    "Education",
    "Aerospace",
    "Entertainment",
  ],
  bannerSubTitle: "Prepare your students to be responsible ai leaders",
  secondSubTitle: [
    <Fragment>
      Machine Learning Bootcamps with <img src={aws} alt="Aws" /> <br />
      For Community College, HBCU & MSI Faculty
    </Fragment>,
  ],
  thirdSubTitle: <Fragment>2025 Bootcamps announced soon!</Fragment>,
  button: {
    title: 'Interest Form',
    isExternal: true,
    link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
  },
};
