import { useState } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { ChevronDown } from "component/Common/Icons";

export const LeadInstructors = (props) => {
  const { data, name, about, photo, isMultiple, headingClassName } = props;
  const [show, setShow] = useState(false);
  return (
    <>
      {isMultiple ? (
        <div className={classNames(styles.leadInstructors, styles.multiple)}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <h3 className={classNames(styles.heading, headingClassName)}>{data?.title}</h3>
                {data?.desc ? <p className={styles.desc}>{data?.desc}</p> : null}
                <div
                  className={classNames(
                    styles.innerRow,
                    "row justify-content-center"
                  )}
                >
                  {data?.instructors?.map((instructor, idx) => (
                    <div className="col-12 col-lg-6" key={idx}>
                      <div className={styles.instructorWrap}>
                        <img
                          src={instructor?.img?.src}
                          alt={instructor?.img?.alt}
                          className="img-fluid"
                        />
                        <div className={styles.content}>
                          <h5 className={styles.name}>{instructor?.name}</h5>
                          <p className={styles.about}>{instructor?.about}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.leadInstructors}>
          <div className="container">
            <div className="row align-items-center justify-content-center justify-content-lg-between">
              <div className="col-6 col-lg-5 mb-50 mb-lg-0">
                <div className={styles.imgWrap}>
                  <img
                    src={photo?.src || data?.img?.src}
                    className="img-fluid"
                    alt={photo?.alt || data?.img?.alt}
                  />
                </div>
              </div>
              <div
                className={classNames(
                  styles.about,
                  "col-12 col-lg-6 text-center text-lg-left"
                )}
              >
                <h3 className={styles.heading}>
                  <span className={classNames(styles.textThin, "d-block")}>
                    Lead
                  </span>{" "}
                  Instructor
                </h3>
                <h4
                  className={classNames(
                    styles.instructorName,
                    "text-blue-gradient"
                  )}
                >
                  {name || data?.name}
                </h4>
                <p
                  className={classNames(styles.instructorAbout, {
                    "mb-20": data?.moreText,
                  })}
                >
                  {about || data?.about}
                </p>
                {data?.moreText && (
                  <>
                    {show ? (
                      <p
                        className={classNames(styles.instructorAbout, "mb-30")}
                      >
                        {data?.moreText}
                      </p>
                    ) : (
                      <></>
                    )}
                    <span
                      className={classNames(
                        styles.readMoreBtn,
                        "text-brand cp fs-18"
                      )}
                      onClick={() => setShow(!show)}
                    >
                      Read {show ? "Less" : "More"}
                      <ChevronDown
                        className={classNames(styles.icon, {
                          [styles.open]: show,
                        })}
                        color={"#356e8b"}
                      />
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
