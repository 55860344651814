import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { partnersLogo } from "data";
import latestNews from "assets/img/home/features-news.jpg";

import styles from './styles.module.scss';
import classNames from "classnames";

export const PartnersAndLatestNews = () => {
  return (
    <>
      <div className={styles.partnerSponsor}>
        <div className="container">
          <div className={styles.psWrap}>
            <div className="visual-text text-center mb-30">
              <h3 className={styles.title} data-aos="fade-up" data-aos-duration="500">
                Our Partners &amp; Sponsors
              </h3>
              <div data-aos="fade-down" data-aos-duration="500">
                <p className={styles.desc}>
                  <span>It takes a village to create high quality, high impact STEM education and make it accessible to all.</span>
                  <span>We are grateful to
                    our sponsors and partners for helping make it happen.</span>
                </p>
              </div>
            </div>
            <ul className="row">
              {partnersLogo.map((item, index) => {
                return (
                  <li
                    className={
                      "col-6 col-md-3 " + (index >= 5 ? "hide" : "show")
                    }
                    key={index}
                  >
                    <img src={item["src"]} alt="Partner" />
                  </li>
                );
              })}
            </ul>

            <div className={classNames(styles.homeBanner, "mt-30 p-0")}>
              <HashLink
                className="btn btn-outline link"
                to="/about_us#partners"
              >
                SEE MORE
              </HashLink>
              <Link className="btn btn-outline link" to="/involved">
                PARTNER WITH US
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.newSec}>
        <div className="visual-text text-center mb-30">
          <h3 className={classNames(styles.title, "pt-50 mb-30")}>Highlights</h3>
        </div>

        <div className={styles.latestTcsNews}>
          <div className="container">
            <div className={classNames(styles.ltcsnWrap, " py-50")}>
              <div className="row">
                <div className="col-12 col-md-11 mx-auto">
                  <div className={styles.ltcsnCard}>
                    <img src={latestNews} alt="Featured News" />
                    <div className={styles.ltcsnCardContent}>
                      <h5 className={styles.ltcsnTitle}>
                        <Link to="/news">
                          The coding school awarded $3m grant from department of
                          defense through national defense education program to
                          empower next generation of artificial intelligence
                          (ai) leaders
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
