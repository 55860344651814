import {
  banner,
  aiIntro,
  courseOverview,
  whoShouldJoin,
  coursesPartner,
  forSchools,
  faqs,
  disclaimer,
  leadInstructors,
} from "data/trainDodCourse";

import { TrainCourseBanner } from "component/TrainCourse/TrainCourseBanner";
import { AiIntro } from "component/TrainCourse/AiIntro";
import { CoursesBy } from "component/TrainCourse/CoursesBy";
import { CourseOverview } from "component/TrainCourse/CourseOverview";
import { WhoShouldJoin } from "component/TrainCourse/WhoShouldJoin";
import { LeadInstructors } from "component/TrainCourse/LeadInstructors";
import { ForSchools } from "component/TrainCourse/ForSchools";
import { CourseSupport } from "component/TrainCourse/CourseSupport";
import { Faq } from "component/TrainCourse/Faq";
import { Disclaimer } from "component/TrainCourse/Disclaimer";

export const TrainDodCourse = () => {
  return (
    <div className="train-course-page">
      <TrainCourseBanner
        bannerTitle={banner.bannerTitle}
        rotatingWords={banner.rotatingWords}
        bannerSubTitle={banner.bannerSubTitle}
        button={banner.button}
      />
      <AiIntro
        paragraph={aiIntro.paragraph}
        aiList={aiIntro.aiList}
        tagLine={aiIntro.tagLine}
      />
      <CoursesBy title={coursesPartner.title} logos={coursesPartner.logos} />
      <CourseOverview data={courseOverview} />
      <WhoShouldJoin words={whoShouldJoin.words} text={whoShouldJoin.text} />
      <LeadInstructors isMultiple={true} data={leadInstructors} />
      <ForSchools
        schoolText={forSchools.schoolText}
        buttonLink={forSchools.buttonLink}
        logos={forSchools.logos}
      />
      <CourseSupport />
      <Faq faqs={faqs} />
      <Disclaimer disclaimer={disclaimer.text} />
    </div>
  );
};
