import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const Banner = (props) => {
  const { data, hasOverlay } = props;

  return (
    <section
      className={styles.banner}
      style={{ backgroundImage: "url(" + data?.bg + ")" }}
    >
      {hasOverlay ? <div className={styles.overlay}></div> : null}
      <div className="container">
        <div
          className={classNames(styles.content, {
            [styles.hasOverlay]: hasOverlay,
          })}
        >
          <h1 className={styles.mainHeading}>{data?.heading}</h1>
          <h3 className={styles.subHeading}>{data?.subHeading}</h3>
          <h5 className={styles.description}>{data?.description}</h5>
          <ul className={styles.links}>
            {data?.links?.map((item, idx) => (
              <li>
                {item?.isExternal ? (
                  <a
                    className="btn btn-gradient-2 text-white"
                    href={item?.link}
                    target="_blank"
                    rel="noreferrer"
                    key={idx}
                  >
                    {item?.linkText}
                  </a>
                ) : (
                  <Link
                    className="btn btn-gradient-2 text-white"
                    to={item?.link}
                    key={idx}
                  >
                    {item?.linkText}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
