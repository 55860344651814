import classNames from "classnames";
import styles from "./styles.module.scss";

export const ProgramBenefits = (props) => {
  const { data } = props;
  return (
    <section
      className={styles.programBenefits}
      style={{ backgroundImage: "url(" + data?.background + ")" }}
    >
      <div className="container">
        <h3 className={styles.title}>{data?.title}</h3>
        <ul className={classNames(styles.keypoints, "list-unstyled")}>
          {data?.keypoints?.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
        <p className={styles.desc}>{data?.desc}</p>
      </div>
    </section>
  );
};
