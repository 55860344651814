import classNames from "classnames";
import styles from "./styles.module.scss";

export const AiIntro = (props) => {
  const { paragraph, aiList, paraTwo, tagLine } = props;
  return (
    <div className={styles.AiIntro}>
      <div className="container">
        <h3 className={styles.heading + " text-center mb-30"}>
          COURSE HIGHLIGHTS
        </h3>
        <div className="row align-items-center text-white text-center text-lg-left">
          <div className="col-12 col-lg-7 mb-30 mb-lg-0">
            <p className={styles.desc}>{paragraph}</p>
          </div>
          <div className="col-12 col-lg-5 mb-30">
            <ul className={styles.characteristicsList + " list-unstyled mb-0"}>
              {aiList.map((item, idx) => {
                return (
                  <li key={idx}>
                    <i className="fa-solid fa-code-branch"></i>
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
          {paraTwo ? <div className="col-12">
            {paraTwo.map((para, idx) => (
              <p
                className={styles.desc}
                key={`para-${idx}`}
              >
                {para}
              </p>
            ))}
          </div> : null}

          <div className="col-12">
            <p
              className={classNames(
                styles.tagline,
                styles.desc,
                "text-bold mb-0"
              )}
            >
              {tagLine}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
