import { Fade } from "react-awesome-reveal";
import bgShape from "assets/img/about/bg-shape.png";
import bgShapeTwo from "assets/img/about/bg-shape-2.png";
import leadership from "assets/img/about/leadership.png";
import { boardOfAdvisors } from "data";

import styles from "./styles.module.scss";
import classNames from "classnames";

export const BoardOfAdvisors = () => {
  return (
    <div className={styles.ourLeadershipBlock}>
      <img
        className="position-absolute"
        style={{ top: "-3%", right: "-19%", width: "57%" }}
        src={bgShape}
        alt="Background Shape"
      />
      <Fade top>
        <img
          className="position-absolute d-none d-lg-block"
          style={{ right: "5%", top: "26%", width: "75px" }}
          src={leadership}
          alt="Leadership"
        />
      </Fade>
      <img
        className="position-absolute"
        style={{ top: "36%", left: "-45%" }}
        src={bgShapeTwo}
        alt="Background Shape"
      />
      <div className={classNames(styles.detailBlock, 'container')}>
        <h3 className={styles.title}>Board & Advisors</h3>
        <div className="row justify-content-center">
          {boardOfAdvisors.map((val, index) => {
            return (
              <div
                className="col-xs-3 col-sm-3 col-md-4 col-lg-3 text-center w-100 mb-20"
                key={index}
              >
                <img
                  className={styles.advisors}
                  src={
                    val.avatar ? val.avatar : "https://via.placeholder.com/80"
                  }
                  alt={val.name}
                />
                {val.name ? <p className="bod-name mb-5">{val.name}</p> : ""}
                {val.designation ? (
                  <p className="bod-designation mb-0">{val.designation}</p>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
