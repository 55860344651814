import { Link } from 'react-router-dom'
import { Carousel } from 'react-bootstrap'
import classNames from 'classnames'
import $ from 'jquery'

import styles from './styles.module.scss'

export const Card = props => {
  const {
    cardClassName,
    horizontal,
    cardImg,
    title,
    subTitle,
    keypoints,
    desc,
    button,
    modalData,
    dataTarget,
  } = props

  const closeModal = () => {
    $('.modal').modal('hide')
  }

  return (
    <>
      <div className={classNames(cardClassName)}>
        <div
          className={classNames(styles.cardWrap, {
            [styles.horizontalCard]: horizontal,
          })}
        >
          <div className={styles.cardImage}>
            <img src={cardImg} className="img-fluid" alt="Card Graphics" />
          </div>
          <div className={styles.cardDescription}>
            <h4>{title}</h4>
            <h5>{subTitle && subTitle}</h5>
            {keypoints && (
              <ul>
                {keypoints?.map((item, idx) => {
                  return (
                    <li key={idx}>
                      <span>{item}</span>
                    </li>
                  )
                })}
              </ul>
            )}
            {desc && <p className={styles.desc}>{desc}</p>}
            <div className={styles.buttons}>
              {button.map((item, idx) => {
                return item.external ? (
                  <a
                    key={idx}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classNames(
                      styles.button,
                      'btn btn-gradient-2 text-white rounded-0'
                    )}
                  >
                    {item.text}
                  </a>
                ) : item?.triggerModal ? (
                  <button
                    data-toggle="modal"
                    data-target={`#${dataTarget}`}
                    className={classNames(
                      styles.button,
                      'btn btn-gradient-2 text-white rounded-0'
                    )}
                  >
                    {item.text}
                  </button>
                ) : (
                  <Link
                    key={idx}
                    to={item.link}
                    className={classNames(
                      styles.button,
                      'btn btn-gradient-2 text-white rounded-0'
                    )}
                  >
                    {item.text}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      {modalData && (
        <div
          className={classNames(styles.campsModal, 'modal')}
          id={dataTarget}
          aria-labelledby={`${dataTarget}Label`}
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex flex-column">
                  <h5 className="modal-title">{modalData?.title}</h5>
                  {modalData?.headerButton &&
                  modalData?.headerButton?.isExternal ? (
                    <a
                      href={modalData?.headerButton?.link}
                      onClick={() => closeModal()}
                      className="btn btn-gradient-2 text-white rounded-0 px-15 py-10"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {modalData?.headerButton?.title}
                    </a>
                  ) : (
                    <Link
                      to={modalData?.headerButton?.link}
                      onClick={() => closeModal()}
                      className="btn btn-gradient-2 text-white rounded-0 px-15 py-10"
                    >
                      {modalData?.headerButton?.title}
                    </Link>
                  )}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className={styles.desc}>{modalData?.desc}</div>
                {modalData?.slides && modalData?.slides.length > 1 ? (
                  <Carousel>
                    {modalData?.slides?.map((slide, idx) => (
                      <Carousel.Item interval={2000}>
                        <img
                          src={slide?.src}
                          alt={slide?.alt}
                          className="img-fluid w-100"
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                ) : (
                  modalData?.slides?.map((slide, idx) => (
                    <img
                      src={slide?.src}
                      alt={slide?.alt}
                      className="img-fluid w-100"
                    />
                  ))
                )}
                <div className={classNames(styles.logisticsWrap, 'mt-20')}>
                  <h4>Logistics</h4>
                  <ul className="list-unstyled mb-20">
                    {modalData?.logistics?.map((logistic, idx) => (
                      <li key={idx}>
                        <span className={styles.title}>{logistic?.title}</span>
                        <span className={styles.info}>{logistic?.info}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="text-center">
                  {modalData?.buttons?.map((button, i) =>
                    button?.external ? (
                      <a
                        href={button?.link}
                        className="btn btn-gradient-2 text-white rounded-0 px-15 py-10"
                        target="_blank"
                        rel="noreferrer"
                        key={i}
                        onClick={() => closeModal()}
                      >
                        {button?.text}
                      </a>
                    ) : (
                      <Link
                        to={button?.link}
                        className="btn btn-gradient-2 text-white rounded-0 px-15 py-10"
                        key={i}
                        onClick={() => closeModal()}
                      >
                        {button?.text}
                      </Link>
                    )
                  )}
                </div>
                {modalData?.psTexts && (
                  <div className="mt-20">
                    {modalData?.psTexts?.map((text, idx) => (
                      <div key={idx}>
                        <h5>{text.title}</h5>
                        {text.desc.map((item, ind) => (
                          <p key={ind}>{item}</p>
                        ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
