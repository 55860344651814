import background from 'assets/img/research/logistics-bg.jpg'
import { Fragment } from 'react'

export const logistics = {
  background: background,
  title: 'Logistics',
  logisticsList: [
    {
      subTitle: 'Eligibility',
      desc: 'US high school students or recent graduates. Students from underrepresented backgrounds in STEM are encouraged to apply.',
    },
    {
      subTitle: 'Dates',
      desc: 'June 17 - August 2, 2024',
    },
    {
      subTitle: 'Time',
      desc: (
        <Fragment>
          Monday - Friday
          <span className="d-block">12am - 4pm ET, variable after Week 2.</span>
          <small className="text-italic d-block">
            *Must be able to join live sessions
          </small>
        </Fragment>
      ),
    },
    {
      subTitle: 'Location',
      desc: (
        <Fragment>
          Virtual for Training
          <br />
          In-Person or Remote at University Hosts
        </Fragment>
      ),
    },
    {
      subTitle: 'Prerequisites',
      desc: "Introduction to Artificial Intelligence by The Coding School's TRAIN Course",
    },
    {
      subTitle: 'Cost',
      desc: 'Free to Accepted Participants',
    },
  ],
}
