import { Link } from 'react-router-dom'

import ReactRotatingText from 'react-rotating-text'
import { Player } from '@lottiefiles/react-lottie-player'

import styles from './styles.module.scss'
import classNames from 'classnames'

export const TrainCourseBanner = props => {
  const {
    bannerTitle,
    rotatingWords,
    bannerSubTitle,
    isSmallSubtitle,
    secondSubTitle,
    thirdSubTitle,
    additionalData,
    button,
    afterButtonText,
  } = props
  return (
    <div className={styles.trainCourseBanner}>
      <div className="container-fluid">
        <div className="row align-items-center jusify-content-between">
          <div className="col-12 text-center">
            <h2>
              <span className="text-blue-gradient">{bannerTitle}</span>{' '}
              <ReactRotatingText
                className={styles.rotatingText}
                color="#000"
                items={rotatingWords}
              />
              . <br />
              <span
                className={classNames(
                  { [styles.smallSubtitle]: isSmallSubtitle },
                  'text-blue-gradient'
                )}
              >
                {bannerSubTitle}
              </span>
            </h2>
            {secondSubTitle &&
              secondSubTitle.map((item, idx) => {
                return (
                  <h4 className={styles.secondSubTitle} key={idx}>
                    {item}
                  </h4>
                )
              })}
            {thirdSubTitle && (
              <h5 className={styles.thirdSubTitle}>{thirdSubTitle}</h5>
            )}
            {additionalData &&
              additionalData.map((item, idx) => (
                <div
                  className={styles.additionalData}
                  key={`additionalData-${idx}`}
                >
                  {item}
                </div>
              ))}
            {button.isExternal ? (
              <a
                className={
                  styles.button +
                  ' btn btn-primary-gradient text-upper text-white text-bold py-10 px-30 mt-10 rounded-0'
                }
                href={button.link}
                target="_blank"
                rel="noreferrer"
              >
                {button.title ? button.title : 'Interest Form'}
              </a>
            ) : (
              <Link
                className={
                  styles.button +
                  ' btn btn-primary-gradient text-upper text-white text-bold py-10 px-30 mt-10 rounded-0'
                }
                to={button.link}
              >
                {button.title ? button.title : 'Interest Form'}
              </Link>
            )}
            {afterButtonText && (
              <p>
                <small>{afterButtonText}</small>
              </p>
            )}
            <div className={styles.player}>
              <Player
                autoplay={true}
                loop={true}
                controls={true}
                src="https://lottie.host/aaf44d3f-68c3-4305-b74c-ae4405dbed20/IRbaW2I4gJ.json"
              ></Player>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
