import { Fragment } from "react";

export const faqs = [
  {
    id: 1,
    qu: "Who is eligible to apply?",
    an: "Actively teaching U.S. high school educators. As our mission is to increase diversity in STEM, educators at schools with students from backgrounds traditionally underrepresented in STEM and those at schools in STEM deserts are encouraged to apply!",
  },
  {
    id: 2,
    qu: "Will I have homework?",
    an: "Homework is required and does count toward successful course completion. Homework review sessions will be available for extra support.",
  },
  {
    id: 3,
    qu: "Do I have to attend all lectures and labs?",
    an: "Attendance is mandatory. Attendance will be taken at the beginning of the lab section and will be factored into successful completion. Prior approval of excused absences will be considered on a case by case basis.",
  },
  {
    id: 4,
    qu: "What if I have to miss a class?",
    an: (
      <Fragment>
        A recording of the lecture will be available if you have to miss the
        lecture. For missed lectures or labs due to an emergency, you must email
        the program team as soon as possible at:{" "}
        <a
          href="mailto:train.pd@the-cs.org"
          className="text-blue-gradient text-bold"
        >
          train.pd@the-cs.org
        </a>{" "}
        to request an excused absence. Documentation may be required.
      </Fragment>
    ),
  },
  {
    id: 5,
    qu: "What is the grading scale?",
    an: "We believe in creating a failure-free environment focused on learning, so this course is graded on a pass-fail scale.",
  },
];
