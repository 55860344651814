import styles from "./styles.module.scss";

export const SchoolPartnerInfo = () => {
  return (
    <div className={styles.trainAwsPdApplication}>
      <div className="container">
        <div className={styles.iframeContainer}>
          <iframe
            title="School Partner Info Form"
            src="https://airtable.com/embed/app4UbKOfvKzT0N39/shrZftcfIUKZUgpVb"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
