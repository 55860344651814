import styles from './styles.module.scss';

export const Subscribe = () => {
  return (
    <div className={styles.subscribe}>
      <div className="container">
        <h1 className={styles.subscribe__title}>Subscribe to our mailing list</h1>
        <p  className={styles.subscribe__desc}>Please fill out the form to join our mailing list!</p>
        <div className={styles.subscribe__form} id="fd-form-6088a1d675b152316cddc99f"></div>
      </div>
    </div>
  )
}
