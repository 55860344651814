import classNames from "classnames";
import styles from "./styles.module.scss";

export const Professors = (props) => {
  const { data } = props;
  return (
    <div className={styles.professors}>
      <div className="container">
        <h4 className={styles.title}>{data?.title}</h4>
        <div className={styles.tabs}>
          <div
            className={classNames(styles.tabPills, "nav")}
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            {data?.professors?.slice(0, 1).map((item, idx) => (
              <button
                className={classNames(styles.pill, idx === 0 ? "active" : "")}
                id={`v-pills-${item?.id}-tab`}
                data-toggle="pill"
                data-target={`#v-pills-${item?.id}`}
                type="button"
                role="tab"
                aria-controls={`v-pills-${item?.id}`}
                aria-selected={idx === 0 ? "true" : "false"}
              >
                <span className={styles.tabTitle}>
                  <img
                    src={item?.img?.src}
                    alt={item?.img?.alt}
                    className={styles.avatar}
                  />
                  <span className={styles.name}>{item?.name}</span>
                </span>
              </button>
            ))}
            <p className="mt-10 mb-0">Some mentors from previous programs</p>
            {data?.professors?.slice(1).map((item, idx) => (
              <button
                className={classNames(styles.pill, idx === 0 ? "active" : "")}
                id={`v-pills-${item?.id}-tab`}
                data-toggle="pill"
                data-target={`#v-pills-${item?.id}`}
                type="button"
                role="tab"
                aria-controls={`v-pills-${item?.id}`}
                aria-selected={idx === 0 ? "true" : "false"}
              >
                <span className={styles.tabTitle}>
                  <img
                    src={item?.img?.src}
                    alt={item?.img?.alt}
                    className={styles.avatar}
                  />
                  <span className={styles.name}>{item?.name}</span>
                </span>
              </button>
            ))}
          </div>
          <div
            className={classNames(styles.tabContent, "tab-content")}
            id="v-pills-tabContent"
          >
            {data?.professors?.map((item, idx) => (
              <div
                className={classNames(
                  styles.tabPane,
                  "tab-pane fade",
                  idx === 0 ? "show active" : ""
                )}
                id={`v-pills-${item?.id}`}
                role="tabpanel"
                aria-labelledby={`v-pills-${item?.id}-tab`}
              >
                <div className={styles.top}>
                  <div className="img-highlighted left">
                    <img
                      src={item?.img?.src}
                      alt={item?.img?.alt}
                      className={styles.avatar}
                    />
                  </div>
                  <h5 className={styles.name}>{item?.name}</h5>
                </div>
                <div className={styles.desc}>{item?.desc}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
