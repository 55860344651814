import DODStem from 'assets/img/common/dod-stem.svg'
import eeroQ from 'assets/img/about/sponsors/eero-q.jpg'
import google from 'assets/img/about/sponsors/google-quantum-ai-new.png'
import ibm from 'assets/img/about/sponsors/ibm-quantum.png'
import quantumStartupFoundary from 'assets/img/about/sponsors/quantum-startup-foundary.png'
import oxfordQuantumCircuits from 'assets/img/about/sponsors/oxford-quantum-circuits.png'
import q12 from 'assets/img/about/sponsors/q12.png'
import QuSTEAM from 'assets/img/about/sponsors/QuSTEAM.png'
import UCLA from 'assets/img/about/sponsors/UCLA.png'
import sydney from 'assets/img/about/sponsors/sydney-quantum-academy.png'
import unitaryFund from 'assets/img/about/sponsors/unitary-fund.jpg'

import WCEDC from 'assets/img/about/sponsors/WCEDC3.jpeg'
import nfs from 'assets/img/about/sponsors/nsf-logo.png'
import nycDoyced from 'assets/img/about/sponsors/nyc-doycd.png'
import nycEdc from 'assets/img/about/sponsors/nyc-edc.png'
import qedc from 'assets/img/about/sponsors/qed-c.svg'
import rti from 'assets/img/about/sponsors/rti.svg'
import stemPush from 'assets/img/about/sponsors/stem-push-logo.png'
import microsoft from 'assets/img/common/microsoft.png'
import aws from 'assets/img/common/aws-logo.png'
import mcKinsey from 'assets/img/about/sponsors/mcKinsey.png'
import sandboxAQ from 'assets/img/about/sponsors/sandboxAQ.svg'
import stanfordCset from 'assets/img/train/stanford-cset.png'
import jhu from 'assets/img/common/jhu.png'
import columbiaUni from 'assets/img/common/columbia-university.jpg'
import maryland from 'assets/img/about/sponsors/university-of-maryland.png'
import JROTC from 'assets/img/about/sponsors/jrotc.png'
import keysight from 'assets/img/about/school-partners/keysight.png'
import errorCorp from 'assets/img/about/school-partners/error-corp.svg'
import girlsScout from 'assets/img/about/school-partners/girls-scout.png'
import bpLogo from 'assets/img/about/school-partners/bp-logo.svg'
import ccfLogo from 'assets/img/about/school-partners/cleveland-clinic.png'
import qunnect from 'assets/img/about/school-partners/qunnect.png'
import asu from 'assets/img/about/school-partners/ASU-Logo.png'
import universityArkansas from 'assets/img/about/school-partners/university-of-arkansas.png'
import coloradoSchoolMines from 'assets/img/about/school-partners/colorado-school-of-mines.jpg'
import clevelandStateUniversity from 'assets/img/about/school-partners/cleveland-state-university.png'
import UCIrvine from 'assets/img/about/school-partners/UC-Irvine.png'
import uoMiami from 'assets/img/about/school-partners/university-of-miami.png'
import cuny from 'assets/img/about/school-partners/cuny.png'
import uniFlorida from 'assets/img/about/school-partners/university-of-florida.png'
import uniCincinnati from 'assets/img/about/school-partners/university-of-cincinnati.png'
import ncStateUni from 'assets/img/about/school-partners/nc-state-uni.jpg'
import uniKnoxville from 'assets/img/about/school-partners/university-of-tennessee-knoxville.png'
import infoSys from 'assets/img/train/summer-institute.png'

export const aboutSponsors = [
  { sponsor: DODStem, alt: 'DOD Stem', logoClass: 'DODStem' },
  { sponsor: q12, alt: 'Q12', logoClass: 'q12' },
  { sponsor: google, alt: 'Google', logoClass: 'google' },
  { sponsor: ibm, alt: 'IBM', logoClass: 'ibm' },
  { sponsor: nfs, alt: 'National Science Federation', logoClass: 'nfs' },
  { sponsor: microsoft, alt: 'Microsoft', logoClass: 'microsoft' },
  { sponsor: aws, alt: 'Amazon Web Services', logoClass: 'aws' },
  { sponsor: mcKinsey, alt: 'mcKinsey & Company', logoClass: 'mcKinsey' },
  { sponsor: stanfordCset, alt: 'Stanford Cset', logoClass: 'stanford-cset' },
  { sponsor: jhu, alt: 'QuSTEAM', logoClass: 'QuSTEAM' },
  {
    sponsor: columbiaUni,
    alt: 'columbia University',
    logoClass: 'columbiaUni',
  },
  { sponsor: maryland, alt: 'University of Maryland', logoClass: 'maryland' },
  { sponsor: rti, alt: 'rti', logoClass: 'rti' },
  { sponsor: sandboxAQ, alt: 'SandboxAQ', logoClass: 'sandboxAQ' },
  { sponsor: nycEdc, alt: 'NYC Economic Development', logoClass: 'nycEdc' },
  {
    sponsor: quantumStartupFoundary,
    alt: 'Quantum Startup Foundary',
    logoClass: 'quantumStartupFoundary',
  },
  { sponsor: sydney, alt: 'Sydney', logoClass: 'sydney' },
  { sponsor: unitaryFund, alt: 'Unitary Fund', logoClass: 'unitaryFund' },
  { sponsor: WCEDC, alt: 'Westchester EDC', logoClass: 'WCEDC' },
  {
    sponsor: nycDoyced,
    alt: 'NYC Department of Youth Community Development',
    logoClass: 'nycDoyced',
  },
  { sponsor: qedc, alt: 'Quantum Consortium', logoClass: 'qedc' },
  { sponsor: stemPush, alt: 'Stem Push', logoClass: 'stemPush' },
  {
    sponsor: oxfordQuantumCircuits,
    alt: 'Oxford Quantum Circuits',
    logoClass: 'oxfordQuantumCircuits',
  },
  { sponsor: eeroQ, alt: 'Eero Q', logoClass: 'eeroQ' },
  { sponsor: QuSTEAM, alt: 'QuSTEAM', logoClass: 'QuSTEAM' },
  { sponsor: JROTC, alt: 'JROTC', logoClass: 'jrotc' },
  { sponsor: keysight, alt: 'Keysight Technologies', logoClass: 'keysight' },
  { sponsor: errorCorp, alt: 'Error Corp', logoClass: 'errorCorp' },
  { sponsor: girlsScout, alt: 'Girls Scout', logoClass: 'girlsScout' },
  { sponsor: bpLogo, alt: 'BP', logoClass: 'bpLogo' },
  { sponsor: ccfLogo, alt: 'CCF', logoClass: 'ccfLogo' },
  { sponsor: qunnect, alt: 'Qunnect', logoClass: 'qunnect' },
  { sponsor: asu, alt: 'Aizona State University', logoClass: 'asu' },
  {
    sponsor: universityArkansas,
    alt: 'University of Arkansas',
    logoClass: 'universityArkansas',
  },
  {
    sponsor: coloradoSchoolMines,
    alt: 'Colorado School of Mines',
    logoClass: 'coloradoSchoolMines',
  },
  {
    sponsor: clevelandStateUniversity,
    alt: 'Cleveland State University',
    logoClass: 'clevelandStateUniversity',
  },
  { sponsor: UCIrvine, alt: 'UC Irvine', logoClass: 'UCIrvine' },
  { sponsor: uoMiami, alt: 'University Of Miami', logoClass: 'uoMiami' },
  { sponsor: cuny, alt: 'The City University of New York', logoClass: 'cuny' },
  {
    sponsor: uniFlorida,
    alt: 'University of Florida',
    logoClass: 'uniFlorida',
  },
  {
    sponsor: uniCincinnati,
    alt: 'University of Cincinnati',
    logoClass: 'uniCincinnati',
  },
  { sponsor: ncStateUni, alt: 'NC State University', logoClass: 'ncStateUni' },
  {
    sponsor: uniKnoxville,
    alt: 'University Of Tennessee Knoxville',
    logoClass: 'uniKnoxville',
  },
  { sponsor: infoSys, alt: 'Info Systmer', logoClass: 'infoSys' },
]
