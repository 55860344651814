import ajDiDonato from 'assets/img/about/young-advisory-board/aj-diDonato.jpg'
import annieLuc from 'assets/img/about/young-advisory-board/annie-luc.jpg'
import christieMatthews from 'assets/img/about/young-advisory-board/christie-matthews.jpg'
import franciscaVasconcelos from 'assets/img/about/young-advisory-board/francisca-vasconcelos.jpg'
import guyCassetta from 'assets/img/about/young-advisory-board/guy-cassetta.png'
import sujayShetty from 'assets/img/about/young-advisory-board/sujay-shetty.jpg'
import trevorGullstad from 'assets/img/about/young-advisory-board/trevor-gullstad.jpg'
import kellyEllis from 'assets/img/about/young-advisory-board/kelly-ellis.jpg'
import mcKellWoodland from 'assets/img/about/young-advisory-board/mcKell-woodland.jpg'

import focalSystems from 'assets/img/common/focal-systems.png'
import rhodes from 'assets/img/common/rhodes.png'
import google from 'assets/img/common/google.png'
import salesforce from 'assets/img/common/salesforce.png'
import mdAnderson from 'assets/img/common/md-anderson.webp'
import mongo from 'assets/img/common/mongo.svg'
import sofi from 'assets/img/common/sofi.svg'
import crowe from 'assets/img/common/crowe.svg'
import { Fragment } from 'react'

export const youngProfessionals = [
  {
    avatar: ajDiDonato,
    name: 'AJ DiDonato III.',
    organization: mongo,
    organizationName: 'MongoDB',
  },
  {
    avatar: annieLuc,
    name: 'Annie Luc',
    organization: google,
    organizationName: 'Google',
  },
  {
    avatar: christieMatthews,
    name: 'Christie Matthews',
    organization: focalSystems,
    organizationName: 'Focal Systems',
  },
  {
    avatar: franciscaVasconcelos,
    name: <Fragment>Fran Vasconcelos <br />Rhodes Scholar</Fragment>,
    organization: rhodes,
    organizationName: 'Rhodes',
  },
  {
    avatar: guyCassetta,
    name: 'Guy Cassetta',
    organization: google,
    organizationName: 'Google',
  },
  {
    avatar: kellyEllis,
    name: 'Kelly Ellis',
    organization: sofi,
    organizationName: 'SoFi',
  },
  // {
  //   avatar: natalieEthell,
  //   name: "Natalie Ethell",
  //   organization: microsoft,
  //   organizationName: "Microsoft",
  // },
  {
    avatar: sujayShetty,
    name: 'Sujay Shetty',
    organization: crowe,
    organizationName: 'Crowe',
  },
  {
    avatar: trevorGullstad,
    name: 'Trevor Gullstad',
    organization: salesforce,
    organizationName: 'Sales Force',
  }, {
    avatar: mcKellWoodland,
    name: 'McKell Woodland',
    organization: mdAnderson,
    organizationName: 'MD Anderson Cancer Center',
  },
]
