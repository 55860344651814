import {
  banner,
  aiIntro,
  courseOverview,
  coursesPartner,
  courseSupport,
  faqs,
} from 'data/trainAwsPd'

import { TrainCourseBanner } from 'component/TrainCourse/TrainCourseBanner'
import { AiIntro } from 'component/TrainCourse/AiIntro'
import { CoursesBy } from 'component/TrainCourse/CoursesBy'
import { CourseOverview } from 'component/TrainCourse/CourseOverview'
import { CourseSupport } from 'component/TrainCourse/CourseSupport'
import { Faq } from 'component/TrainCourse/Faq'

import styles from './styles.module.scss'

export const TrainAwsPd = () => {
  return (
    <div className="trainAwsPd">
      <TrainCourseBanner
        bannerTitle={banner.bannerTitle}
        rotatingWords={banner.rotatingWords}
        bannerSubTitle={banner.bannerSubTitle}
        secondSubTitle={banner.secondSubTitle}
        thirdSubTitle={banner.thirdSubTitle}
        button={banner.button}
        isSmallSubtitle={true}
      />
      <AiIntro
        paragraph={aiIntro.paragraph}
        aiList={aiIntro.aiList}
        tagLine={aiIntro.tagLine}
      />
      <CoursesBy
        title={coursesPartner.title}
        logos={coursesPartner.logos}
        isThinner={true}
      />
      <CourseOverview data={courseOverview} />
      <CourseSupport
        paragraph={courseSupport.paragraph}
        logo={courseSupport.logo}
        logoClass={styles.logo}
      />
      <Faq faqs={faqs} />
    </div>
  )
}
