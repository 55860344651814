import virtual from "assets/img/train/icon-virtual.png";
import accredited from "assets/img/train/icon-accredited.png";
import live from "assets/img/train/icon-live.png";
import mit from "assets/img/train/taught-by-mit.png";
import { Fragment } from "react";

export const offerings = [
  { src: virtual, title: "Virtual" },
  {
    src: accredited,
    title: (
      <Fragment>
        Taught by <br />
        Stanford & Columbia PhDs
      </Fragment>
    ),
  },
  {
    src: live,
    title: (
      <Fragment>
        Live <br />
        Instruction
      </Fragment>
    ),
  },
  {
    src: mit,
    title: (
      <Fragment>
        Accredited <br />
        Courses
      </Fragment>
    ),
  },
];
