import classNames from "classnames";
import { TrainBoardAdvisors } from "data/train";

import styles from "./styles.module.scss";

export const BoardAdvisors = () => {
  return (
    <section className={styles.adviseryBoard}>
      <div className="container">
        <div
          className={styles.headline}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <h2>
            <span> AI Board</span> &amp; Advisors
          </h2>
        </div>
        <h4 className="text-center">
          We work with leading experts in AI to ensure our students are inspired
          and receive the most cutting-edge, high quality education possible.
        </h4>
      </div>
      <div className={classNames(styles.containerBig, "container")}>
        <div className="row">
          <div className="col-md-12">
            <ul>
              {TrainBoardAdvisors.map((member, index) => (
                <li
                  data-aos="fade-up"
                  data-aos-duration={500 + 100}
                  key={index}
                >
                  <div className={styles.main}>
                    <div className={styles.pic}>
                      <img src={member.avatar} alt={member.name} />
                    </div>
                    <div className={styles.content}>
                      <h4 className={styles.title}>{member.name}</h4>
                      <p className={styles.position}>{member.position}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
