import { useState } from 'react'
import { Helmet } from 'react-helmet'

import { HomeBanner } from 'component/Home/HomeBanner'
import { StemEducation } from 'component/Home/StemEducation'
import { KeyInitiatives } from 'component/Home/KeyInitiatives'
import { Stories } from 'component/Home/Stories'
import { StudentMapCounter } from 'component/Home/StudentMapCounter'
import { StemWorkforce } from 'component/Home/StemWorkforce'
import { UpcomingPrograms } from 'component/Home/UpcomingPrograms'
import { LearningModel } from 'component/Home/LearningModel'
import { SchoolPartners } from 'component/Home/SchoolPartners'
import { Testimonial } from 'component/Testimonial/Testimonial'
import { InTheNews } from 'component/Home/InTheNews'
import { News } from 'component/Common/News/News'
import { PartnersAndLatestNews } from 'component/Home/PartnersAndLatestNews'
import { SubscriberForm } from 'component/Common/SubscriberForm'
import { PastPrograms } from 'component/Home/PastPrograms'
import { Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import donateImage from 'assets/img/common/donate-popup-image.png'

export const Home = () => {
  const [show, setShow] = useState(true)
  const handleClose = () => setShow(false)

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="The Coding School awarded $3 million grant"
        />
      </Helmet>
      <div className="homePage">
        <HomeBanner />
        <StemEducation />
        <KeyInitiatives />
        <UpcomingPrograms />
        {/* <PastPrograms /> */}
        <Stories />
        <StudentMapCounter />
        <LearningModel />
        <Testimonial />
        <StemWorkforce />
        <SchoolPartners />
        <PartnersAndLatestNews />
        <InTheNews />
        <News />
        {/* <SubscriberForm /> */}

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Body className="pb-30">
            <Button
              className="btn btn-gradient-2 text-white"
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                borderRadius: 0,
              }}
              onClick={handleClose}
            >
              <i className="fa-solid fa-xmark"></i>
            </Button>
            <div className="text-center mt-40">
              <h3 className="fs-22 mb-30">
                During this season of giving, please consider donating to The Coding School to help us continue
                providing scholarships for students with financial need.
              </h3>

              {/* <img
                src={donateImage}
                alt="It's Giving Tuesday"
                className="img-fluid w-100 mb-30 rounded overflow-hidden"
              /> */}

              <Link
                to="/donate"
                className="btn btn-gradient-2 text-white px-30 py-15"
              >
                Donate Now
              </Link>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}
