import classNames from "classnames";

import DOD from "assets/img/common/dod-stem.svg";
import styles from "./styles.module.scss";

export const CourseSupport = (props) => {
  const { paragraph, logo, logoClass } = props;
  return (
    <div
      className={classNames(styles.courseSupport, "bg-blue-gradient rounded")}
    >
      <div className="container">
        <div className={styles.content}>
          <span>
            {paragraph
              ? paragraph
              : "We are grateful for the support and partnership of"}
          </span>
          {logo ? (
            <img src={logo.src} className={logoClass} alt={logo.alt} />
          ) : (
            <img src={DOD} alt="DOD STEM" />
          )}
        </div>
      </div>
    </div>
  );
};
