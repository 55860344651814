import { Fragment } from 'react'
import bg from 'assets/img/research/university-columbia.jpg'

export const banner = {
  bg: bg,
  heading: (
    <Fragment>
      National High School <span className="text-gold">AI Research Program</span>
    </Fragment>
  ),
  subHeading:
    'Develop real-world research skills | Conduct research with university faculty | Continue your AI future',
  description: (
    <Fragment>
      Grades: US-based Incoming 10<sup>th</sup> - 12<sup>th</sup> + Recent High
      School Graduates <br />
      June 17-August 2, 2024
    </Fragment>
  ),
  links: [
    {
      linkText: 'Apply Now',
      link: '/hsrp-application',
      isExternal: false,
    },
  ],
}
