import { Fragment } from "react";

export const courseOverview = [
  {
    tabTitle: "Key Details",
    tabContent: {
      keyPoints: [
        {
          icon: "fa-solid fa-map-location-dot",
          point: "Virtual with live instruction",
        },
        {
          icon: "fa-solid fa-calendar-days",
          point: "September 2024 - April 2025",
        },
        { icon: "fa-solid fa-sack-dollar", point: "Scholarships available" },
        { icon: "fa-solid fa-clipboard-list", point: "Prerequisites: None" },
      ],
    },
  },
  {
    tabTitle: "How It Works",
    tabContent: {
      headline: "Students participate in three weekly components.",
      keyPoints: [
        {
          icon: "fa-solid fa-person-chalkboard",
          point: "1.5 hour Lecture",
        },
        {
          icon: "fa-solid fa-flask",
          point: "1 hour Lab with Teaching Assistant",
        },
        { icon: "fa-solid fa-diagram-project", point: "Homework or project" },
      ],
      description:
        "Additionally, students will have the opportunity to hear from AI experts from academia and industry, meet with college admissions officers from universities around the U.S., join affinity groups, as well as participate in regional meet-ups to meet students in your local community. For supplemental academic support, we'll have weekly office hours and homework review sessions. At the end of the course, each student will complete a capstone project.",
      highlightedPoint: [
        "Upon completion of the course students will be presented with a certificate of completion.",
        "Lecture:  Sundays 3-4:30pm ET",
        "Labs:  Multiple time options available Tues-Sat",
        "Orientation & pre-course sessions start Sept 2024. ",
      ],
    },
  },
  {
    tabTitle: "What Students Learn",
    tabContent: {
      descriptions: [
        "Students will develop foundational skills in PYTHON, data science, and machine learning. They'll experience AI at the intersection of fields such as healthcare, robotics and social media.",
        "In addition to learning real AI skills, students will receive mentorship from AI experts at top universities, companies and national labs including Stanford, Johns Hopkins, U.S. Transportation Command, and the Naval Facilities Engineering Systems Command. Sessions with college admissions officers will also be held to provide invaluable guidance for the college admission process. One of the most important topics students will study in the course is bias in AI in which they will learn what it means to become a responsible AI leader.",
      ],
      topicsCovered: "Key concepts covered:",
      pointsList: [
        "ML Algorithms, such as KNN and k-means clustering",
        "Data visualizations",
        "Reinforcement Learning",
        "Deep Learning",
        "Natural Language Processing (NLP)",
      ],
    },
  },
  {
    tabTitle: "How To Apply",
    tabContent: {
      descriptions: [
        "This course is open to U.S. high school students. To apply, students are required to complete a short application with basic information and submit a 200-word Statement of Interest.",
        "We're looking for students with enthusiasm to learn about the field of artificial intelligence. In your statement, please share how you feel learning these skills will help you in your future.",
      ],
      highlightedPoint: [
        <Fragment>
          <span className="text-bold">
            Students from backgrounds traditionally underrepresented in STEM are
            encouraged to apply!
          </span>
        </Fragment>,
        "No prior coding experience required, so submit your application today!",
      ],
      button: {
        isExternal: true,
        link: "https://airtable.com/appdS9n7K25uof6m4/shrhHmSkMlGPdjUHM",
        buttonText: "Interest Form",
      },
    },
  },
];
