import codeConnects from 'assets/img/home/code-connects-new.png'
import quantumComputing from 'assets/img/home/quantum-computing.png'
import train from 'assets/img/home/train-new.png'
import { Fragment } from 'react'

export const keyPost = [
  {
    name: '1',
    title:
      'codeConnects empowers students through STEM pathways from building foundational Python skills to developing emerging technologies skills to conducting research in the field.',
    img: codeConnects,
    link: '/summer-programs',
    anim: 'left',
    subTitle: (
      <Fragment>
        Classical <br />
        Coding
      </Fragment>
    ),
    isExternal: false,
  },
  {
    name: '1',
    title:
      'Qubit by Qubit makes quantum computing education accessible to K-16 students and members of the workforce through camps, year-long courses, research and internships.',
    img: quantumComputing,
    link: 'https://www.qubitbyqubit.org',
    anim: 'top',
    subTitle: (
      <Fragment>
        Quantum <br />
        Computing
      </Fragment>
    ),
    isExternal: true,
  },
  {
    name: '1',
    title:
      'TRAIN is creating future Responsible AI leaders through first-of-its-kind course: Introduction to Machine Learning (ML) for High School Students and Educators & College Faculty.',
    img: train,
    link: '/train',
    anim: 'right',
    subTitle: (
      <Fragment>
        Artificial <br />
        Intelligence
      </Fragment>
    ),
    isExternal: false,
  },
]
