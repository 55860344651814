import { Fragment } from "react";

import imgOne from "assets/img/home/QxQ_NoLastNames_Photo.jpg";
import imgTwo from "assets/img/home/QxQ_Conference.jpg";
import imgThree from "assets/img/home/blackboard.jpeg";
import imgFour from "assets/img/home/red_blue_hoodie.jpg";
import imgFive from 'assets/img/summer-programs/ai-cloud.jpg'
import imgSix from 'assets/img/summer-programs/tech-camp.jpg'

export const pastPrograms = [
  {
    ppImg: imgOne,
    ppTitle: <Fragment>Introduction to Quantum Computing for High School Students (2020 - <span className="text-lower">present</span>)</Fragment>,
    ppDesc: <Fragment>
      <strong>2023-24 -</strong> Sponsored by Google Quantum AI <br />
      <strong>2021-23 -</strong> Sponsored by IBM Quantum <br />
      <strong>2020-2021 -</strong> Partnered with IBM Quantum and taught by MIT and Oxford quantum researchers, Qubit by Qubit led the first ever global quantum computing course for high school students and above. In this year-long course, over 7,500 students from 130 countries learned skills in quantum mechanics, quantum computation, quantum algorithms and even ran code on a real quantum computer!
    </Fragment>,
    ppLink: "https://www.ibm.com/blogs/research/2020/10/quantum-coding-school/",
    isExternal: true,
  },
  {
    ppImg: imgTwo,
    ppTitle: "Diversity in Quantum Conference",
    ppDesc:
      "Featuring 21 speakers across 7 panels, the QxQ Diversity in Quantum Computing Conference is a one-day virtual event dedicated to creating an inclusive, diverse, and equitable future of quantum computing. We are proud to say over 2,200 attendees from 65 countries joined us for these important conversations. Conversation topics ranged from actionable inclusion strategies, policy discussions, and educational initiatives to authentic, vulnerable discussions about confidence, allyship, and lifting up others in the space. Educators, tech leaders, policy makers, and researchers took the stage to discuss how we all can work together to make quantum computing diverse and inclusive-- starting today.",
    ppLink: "https://www.qubitbyqubit.org/conference",
    isExternal: true,
  },
  {
    ppImg: imgThree,
    ppTitle: <Fragment>Quantum Computing Summer Camp (2020 - <span className="text-lower">present</span>)</Fragment>,
    ppDesc:
      "Sponsored by Google AI and IBM Quantum, QxQ developed the first of its kind Quantum Camp for High School Students. With nearly 300 high school students from diverse backgrounds - 53% female; 43% low SES; and 40% students of color - MIT PhD researchers led this intensive week-long camp exposing students to quantum computing and teaching them tangible quantum skills. Students loved the camp: 98% said they would recommend the camp to a friend; 93% liked or loved the camp; and 88% are interested in studying quantum in the future.",
    ppLink:
      "https://the-cs.medium.com/preparing-for-the-jobs-of-the-future-mit-physicists-are-making-quantum-computing-accessible-to-230e04fb0ff8",
    isExternal: true,
  },
  {
    ppImg: imgFour,
    ppTitle: <Fragment>Quantum Computing Workshop for High School & Middle School Students (2021 - <span className="text-lower">present</span>)</Fragment>,
    ppDesc: <Fragment>2021 - Be one of the first students in the world to learn quantum computing - the technology that will change the world as we know it today! Join us for a free workshop for middle & high school students. In this interactive session developed and led by MIT PhD Dr. Sarah Goodman, we'll learn about the quantum world and how it is being used to make the most powerful computers ever - quantum computers!</Fragment>,
    isExternal: false,
  },
  {
    ppImg: imgFive,
    ppTitle: <Fragment>Introduction to AI For High School Students funded by department of defence (2022 - <span className="text-lower">present</span>)</Fragment>,
    ppDesc:
      "Funded by the Department of Defense and taught by Columbia University Machine Learning researcher, TRAIN led the first-of-its-kind Introduction to AI course for high school students. In these year-long courses, nearly 2,000 students from across the U.S. developed real skills in AI, ML and Responsible AI; had Q&As with industry experts; and developed capstone projects in AI.",
    ppLink: "/train-course",
    isExternal: false,
  },
  {
    ppImg: imgSix,
    ppTitle: <Fragment>Introduction to AI For High School Educators funded by department of defence (2022 - <span className="text-lower">present</span>)</Fragment>,
    ppDesc:
      "Funded by the Department of Defense, this four-week professional development taught  high school educators across the U.S. skills in AI and ML. In addition, participants worked with pedagogical experts from Stanford and Johns Hopkins to create AI lesson plans to take back into their classrooms.",
    ppLink: "/train-pd",
    isExternal: false,
  },
];
