export const Disclaimer = (props) => {
  const { disclaimer } = props;
  return (
    <section className="section-disclaimer my-50">
      <div className="container">
        <p className="text-center text-gray mb-0">
          <small>{disclaimer}</small>
        </p>
      </div>
    </section>
  );
};
