import { Fragment } from 'react'
import img from 'assets/img/research/program-overview.png'

export const programOverview = {
  title: (
    <Fragment>
      Program{' '}
      <span className="text-animated">
        <span>Overview</span>
      </span>
    </Fragment>
  ),
  desc: "Ready to apply your AI skills in real research? Designed for graduates of TCS' TRAIN Introduction to Artificial Intelligence, the National High School AI Research Program is the perfect opportunity to continue learning and developing tangible skills in STEM. Serving as research assistants to professors and PhD candidates, students will contribute to AI/ML research and present their final work at the TCS Summer Research Symposium.",
  img: {
    src: img,
    alt: 'Program Overview',
  },
}
