import { Fragment } from "react";
import zoomClass from "assets/img/research/zoom-students.png";

export const programCompletion = {
  title: (
    <Fragment>
      After completing this{" "}
      <span className="text-animated">
        <span>program...</span>
      </span>
    </Fragment>
  ),
  keypoints: [
    {
      point:
        "of students are more likely to major in a STEM subject in college after this program",
      completion: "96",
      outlineColor: "#eee",
      fillColor: "#22d082",
    },
    {
      point:
        "of students felt more confident in both their coding and research skills after this program",
      completion: "93",
      outlineColor: "#eee",
      fillColor: "#ff9f00",
    },
    {
      point: "of students agree this program has prepared them for college",
      completion: "89",
      outlineColor: "#eee",
      fillColor: "#0e9aff",
    },
  ],
  img: {
    src: zoomClass,
    alt: "Zoom Class",
  },
};
