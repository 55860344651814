import classNames from 'classnames'
import styles from './styles.module.scss'

export const WhatStudentsLearn = props => {
  const { data, isBulletInline, isExpanded } = props
  return (
    <section className={styles.whatStudentsLearn}>
      <div className={styles.overlay}></div>
      <div className="container zi-1">
        {isExpanded ? (
          <div className="row align-items-center">
            <div className="col-12 mb-40 text-center">
              <h3 className={styles.title}>{data?.title}</h3>
            </div>
            <div className="col-12 col-lg-5 d-none d-lg-block">
              <div className="img-highlighted left">
                <img
                  className="img-fluid w-100"
                  src={data?.img?.src}
                  alt={data?.img?.alt}
                />
              </div>
            </div>
            <div className="col-12 col-lg-7">
              {data?.firstDesc && (
                <p className={classNames(styles.desc, 'mb-20')}>
                  {data?.firstDesc}
                </p>
              )}
            </div>
            <div className="col-12 mt-40 ">
              <ul
                className={classNames(styles.keypoints, 'list-unstyled', {
                  [styles.bulletInlined]: isBulletInline,
                })}
              >
                {data?.keypoints?.map((item, idx) => (
                  <li key={idx}>
                    <span className={styles.content}>{item}</span>
                  </li>
                ))}
              </ul>
              <p className={styles.desc}>{data?.desc}</p>
            </div>
          </div>
        ) : (
          <div className="row align-items-center">
            <div className="col-12 col-lg-5 d-none d-lg-block">
              <div className="img-highlighted left">
                <img
                  className="img-fluid w-100"
                  src={data?.img?.src}
                  alt={data?.img?.alt}
                />
              </div>
            </div>
            <div className="col-12 col-lg-7">
              <h3 className={styles.title}>{data?.title}</h3>
              {data?.firstDesc && (
                <p className={classNames(styles.desc, 'mb-20')}>
                  {data?.firstDesc}
                </p>
              )}
              <ul
                className={classNames(styles.keypoints, 'list-unstyled', {
                  [styles.bulletInlined]: isBulletInline,
                })}
              >
                {data?.keypoints?.map((item, idx) => (
                  <li key={idx}>
                    <span className={styles.content}>{item}</span>
                  </li>
                ))}
              </ul>
              <p className={styles.desc}>{data?.desc}</p>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
