const CircularChart = ({ className, completion, fillColor, outlineColor }) => {
  return (
    <div className={className}>
      <svg viewBox="0 0 36 36">
        <path
          stroke={outlineColor ? outlineColor : "#eee"}
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
          stroke-width="1.5"
        ></path>
        <path
          stroke-dasharray={`${completion}, 100`}
          stroke={fillColor}
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
          stroke-width="2.8"
          stroke-linecap="round"
        ></path>
        <text x="18" y="22.50">
          {completion}%
        </text>
      </svg>
    </div>
  );
};

export default CircularChart;
