import ibmq from "assets/img/common/ibmq.png";
import quantumLondon from "assets/img/common/quantum-london.webp";
import ace from "assets/img/common/ace.png";
import techGig from "assets/img/common/techgig.png";
import cacm from "assets/img/common/cacm.png";
import agnesIrwinSchool from "assets/img/common/agnes-irwin-school.png";
import businessInsider from "assets/img/common/business-insider.svg";
import quantumInsider from "assets/img/common/quantum-insider.png";
import insideQuantum from "assets/img/home/inside-quantum.jpg";
import spie from "assets/img/home/SPIE.jpg";
import mckinsey from "assets/img/home/in-the-news/mckinsey-digital.jpg";
import keira from "assets/img/home/in-the-news/forbes-founder.jpg";
import warren from "assets/img/home/in-the-news/warren.jpg";
import springer from "assets/img/home/in-the-news/logo-springernature.svg";
import edtech from "assets/img/home/in-the-news/edtech.png";

export const featuredPost = [
  {
    title: "EdTech Magazine",
    img: edtech,
    description:
      "Quantum computing instruction is filtering down to middle and high school students.",
    link: "https://edtechmagazine.com/k12/article/2023/05/why-k-12-schools-must-invest-teaching-quantum-computing-today",
  },
  {
    title: "Springer Nature",
    img: springer,
    description:
      "Five educators are reimagining undergraduate teaching in materials science and engineering.",
    link: "https://www.nature.com/articles/s41578-023-00621-6",
  },
  {
    title: "McKinsey Digital",
    img: mckinsey,
    description:
      "Five lessons from AI on closing quantum’s talent gap—before it’s too late.",
    link: "https://www.mckinsey.com/capabilities/mckinsey-digital/our-insights/five-lessons-from-ai-on-closing-quantums-talent-gap-before-its-too-late",
  },
  {
    title: "The Downey Patriot",
    img: warren,
    description:
      "Warren High JROTC partners with TCS to be the first in country to complete AI course.",
    link: "https://www.thedowneypatriot.com/articles/warren-high-jrotc-first-in-country-to-complete-ai-course",
  },
  {
    title: "Forbes",
    img: keira,
    description:
      "Forbes: Founder, CEO of The Coding School Named to Forbes’ 30 Under 30: Social Impact",
    link: "https://www.forbes.com/sites/oliviapeluso/2022/11/29/under-30-2023-social-impact-meet-the-young-leaders-building-bold-businesses-and-a-better-tomorrow/?sh=d2408fc1708a",
  },
  {
    title: "IBM",
    img: ibmq,
    description:
      "IBM Quantum and Qubit by Qubit partner again to offer quantum computing course",
    link: "https://research.ibm.com/blog/quantum-coding-school",
  },
  {
    title: "Business Insider",
    img: businessInsider,
    description:
      "Preparing for the Jobs of the Future: The Coding School and MIT Physicists Are Making Quantum Computing Accessible",
    link: "https://markets.businessinsider.com/news/stocks/preparing-for-the-jobs-of-the-future-the-coding-school-and-mit-physicists-are-making-quantum-computing-accessible-to-high-school-students-this-summer-1029279911",
  },
  {
    title: "ACE-Ed",
    img: ace,
    description:
      "The Coding School & IBM Quantum offering free Quantum course to 5,000 students",
    link: "https://ace-ed.org/the-coding-school-ibm-quantum-offering-free-quantum-course-to-5000-students/",
  },
  {
    title: "TechGig",
    img: techGig,
    description:
      "IBM and The Coding School to offer free quantum education to 5000 students",
    link: "https://content.techgig.com/ibm-and-the-coding-school-to-offer-free-quantum-education-to-5000-students/articleshow/78538803.cms",
  },
  {
    title: "CACM",
    img: cacm,
    description:
      "MIT Researchers Lead High School Educational Initiative on Quantum Computing",
    link: "https://cacm.acm.org/careers/246701-mit-researchers-lead-high-school-educational-initiative-on-quantum-computing/fulltext?mobile=false",
  },
  {
    title: "Agnes Irwin School",
    img: agnesIrwinSchool,
    description:
      "Two Juniors Accepted Into First-Ever Quantum Computing Course",
    link: "https://www.agnesirwin.org/about/news/news-detail-page/~board/news/post/two-juniors-accepted-into-first-ever-quantum-computing-course",
  },
  {
    title: "Quantum London",
    img: quantumLondon,
    description: "MIT researchers and The Coding School are offering a first-of-it's kind a virtual Quantum Computing camp.",
    link: "https://quantum-london5.passle.net/post/102g9gf/quantum-summer-camp",
  },
  {
    title: "SPIE (international society for optics and photonics)",
    img: spie,
    description:
      "From enjoying the World Cup on a cutting-edge Samsung Quantum OLED TV to exploring the Quantum Realm alongside Ant-Man, or even using Finish Quantum Detergent for cleaning dishes, the term Quantum has permeated into everyday households.",
    link: "https://spie.org/news/photonics-focus/mayjune-2023/teaching-about-quantum-science?SSO=1",
  },
  {
    title: "Quantum Insider",
    img: quantumInsider,
    description:
      "TQD Quantum Ethics Exclusive: Qubit by Qubit’s Mission is to Train a Diverse Quantum Workforce",
    link: "https://thequantuminsider.com/2021/03/01/tqd-quantum-ethics-exclusive-qubit-by-qubits-mission-is-to-train-a-diverse-quantum-workforce/",
  },
  {
    title: "Inside Quantum Technology:",
    img: insideQuantum,
    description:
      "The study of quantum computing is a rapidly developing domain that holds the promise of transforming information processing as we know it.",
    link: "https://www.insidequantumtechnology.com/news-archive/inside-quantum-technologys-inside-scoop-quantum-and-quantum-education/",
  },
];
