import eastsideCollege from "assets/img/about/school-partners/eastside-college.jpg";
import IMTerrell from "assets/img/about/school-partners/i-m-terrell.jpg";
import jerichoHighSchool from "assets/img/about/school-partners/jericho-hs.png";
import KHPES from "assets/img/about/school-partners/khpes.png";
import kirbySchool from "assets/img/about/school-partners/kirby-school.jpeg";
import larchmontCharterSchool from "assets/img/about/school-partners/larchmont-charter-school.png";
import GTO from "assets/img/about/school-partners/gto.jpg";
import sch1576 from "assets/img/about/school-partners/sch1576.png";
import monti from "assets/img/about/school-partners/monti.jpg";
import losAmigos from "assets/img/about/school-partners/los-amigos.jpg";
import mississippiSchool from "assets/img/about/school-partners/mississippi-school.jpg";
import mnohs from "assets/img/about/school-partners/mnohs.png";
import motCharter from "assets/img/about/school-partners/mot-charter.png";
import nestIt from "assets/img/about/school-partners/nest-education-it-school.jpg";
import pembinaTrails from "assets/img/about/school-partners/pembina-trails.png";
import pietermaritzburg from "assets/img/about/school-partners/pietermaritzburg-girls-high-school.jpg";
import randolphMaconAcademy from "assets/img/about/school-partners/randolph-macon-academy.png";
import seacostCollegiate from "assets/img/about/school-partners/seacoast-collegiate-high-school.png";
import smokyHills from "assets/img/about/school-partners/smoky-hill-high-school.png";
import thomasAquinas from "assets/img/about/school-partners/thomas-aquinas-high-school.jpg";
import bryanMAWR from "assets/img/about/school-partners/bryn-mawr-school.jpg";
import tjca from "assets/img/about/school-partners/tjca.jpg";
import tomujin from "assets/img/about/school-partners/tomujin.jpg";
import unite from "assets/img/about/school-partners/unite.png";
import hartford from "assets/img/about/school-partners/hartford-public-schools.png";
import CSMD from "assets/img/about/school-partners/CMSD.jpeg";

export const aboutPartners = [
  {
    partnerLogo: eastsideCollege,
    alt: "East Side College",
  },
  {
    partnerLogo: CSMD,
    alt: "Cleveland Metropolitan School District",
  },
  { partnerLogo: IMTerrell, alt: "I-M-Terrell" },
  { partnerLogo: jerichoHighSchool, alt: "Jericho High School" },
  { partnerLogo: KHPES, alt: "KHPES" },
  { partnerLogo: kirbySchool, alt: "Kirby School" },
  {
    partnerLogo: larchmontCharterSchool,
    alt: "Larchmont Charter School",
  },
  { partnerLogo: GTO, alt: "GTO" },
  { partnerLogo: sch1576, alt: "School 1576" },
  { partnerLogo: monti, alt: "Monti" },
  { partnerLogo: losAmigos, alt: "Los Amigos" },
  {
    partnerLogo: mississippiSchool,
    alt: "Mississippi School",
  },
  { partnerLogo: mnohs, alt: "MNOSH" },
  { partnerLogo: motCharter, alt: "MOT Charter" },
  {
    partnerLogo: nestIt,
    alt: "NEST Education IT School",
  },
  { partnerLogo: pembinaTrails, alt: "Pembina Trails" },
  {
    partnerLogo: pietermaritzburg,
    alt: "Pietermaritzburg Girls High School",
  },
  {
    partnerLogo: randolphMaconAcademy,
    alt: "Randolph Macon Academy",
  },
  {
    partnerLogo: seacostCollegiate,
    alt: "Seacost Collegiate",
  },
  {
    partnerLogo: smokyHills,
    alt: "Smoky Hills High School",
  },
  {
    partnerLogo: thomasAquinas,
    alt: "Thomas Aquinas High School",
  },
  {
    partnerLogo: bryanMAWR,
    alt: "Bryn Mawr School",
  },
  { partnerLogo: tjca, alt: "TJCA" },
  { partnerLogo: tomujin, alt: "Tomujin" },
  { partnerLogo: unite, alt: "UNITE" },
  {
    partnerLogo: hartford,
    alt: "West Hartford Public Schools",
  },
];
