export const socialLinks = [
  {
    socialIcon: <i className="fa-brands fa-instagram"></i>,
    socialLink: "https://www.instagram.com/the_coding_school/?hl=en",
  },
  {
    socialIcon: <i className="fa-brands fa-linkedin-in"></i>,
    socialLink: "https://www.linkedin.com/school/3655283/",
  },
  {
    socialIcon: <i className="fa-brands fa-facebook-f"></i>,
    socialLink: "https://www.facebook.com/thecodingschool",
  },
  {
    socialIcon: <i className="fa-brands fa-x-twitter"></i>,
    socialLink: "https://twitter.com/qubitbyqubit",
  },
];
