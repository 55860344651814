import { Fragment } from "react";

import courseBenefitsImg from "assets/img/home/past-programs-1.jpg";

export const courseBenefits = {
  sectionTitle: (
    <Fragment>
      Course{" "}
      <span className="text-animated">
        <span>Benefits</span>
      </span>
    </Fragment>
  ),
  sectionBoldDesc:
    "Be among the first students in the world to develop real skills in emerging technologies.",
  sectionDesc:
    "Our goal is to help students understand more about these fascinating emerging fields and be at the forefront of the technology revolution, so they are prepared for the future of work and positioned in the most positive way possible for college and beyond.",
  benefitsList: [
    "Learn real-world emerging tech skills",
    "Meet global industry leaders & experts who are shaping the emerging technologies landscape",
    "Alumni opportunities for internships and research",
    "Increase college competitiveness",
    "Create capstone projects",
    "Earn high school transcript credit",
  ],
  img: {
    src: courseBenefitsImg,
    alt: "Course Benefits",
  },
  // bannerLinks: [
  //   {
  //     linkText: (
  //       <Fragment>
  //         <span>
  //           Quantum Computing
  //           <span>Register Now</span>
  //         </span>
  //       </Fragment>
  //     ),
  //     link: "https://www.qubitbyqubit.org/course-application",
  //     isExternal: true,
  //   },
  //   {
  //     linkText: (
  //       <Fragment>
  //         <span>
  //           Artificial Intelligence
  //           <span>Apply Now</span>
  //         </span>
  //       </Fragment>
  //     ),
  //     link: "/train-course-application",
  //     isExternal: false,
  //   },
  // ],
};
