import { featuredLogo } from "data/home";
import Slider from "react-slick";
import { CarouselNext } from "component/Common/CarouselNext";
import { CarouselPrev } from "component/Common/CarouselPrev";

import styles from "./styles.module.scss";

export const InTheNews = () => {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    nextArrow: <CarouselNext />,
    prevArrow: <CarouselPrev />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <div className={styles.featuredLogo}>
      <div className="container-fluid">
        <div className="visual-text text-center mb-50">
          <h3 className={styles.title}>In the News</h3>
        </div>

        <Slider {...settings} className={styles.slider}>
          {featuredLogo.map((logo, idx) => {
            return (
              <img key={idx} src={logo["src"]} alt="Featured" className="img-fluid" />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
