import { Fragment } from 'react'

import courseBenefitsImg from 'assets/img/courses/ai.jpg'

export const elevateLearning = {
  sectionBoldDesc:
    'Elevate Your Learning Experience with Our Quantum Scholars or AI Scholars Track!',
  sectionDesc: (
    <Fragment>
      Are you ready to take your AI or Quantum journey to the next level? Our
      new <strong>Quantum Scholars Track or AI Scholars Track</strong> offers
      you the opportunity to dive deeper into your studies, gain exclusive
      support from experts and industry leaders, and showcase your hard work
      with distinction—all while preparing for your future college or career
      goals. This add-on experience is designed to supplement your course with
      meaningful opportunities that go beyond the standard curriculum. You’ll
      receive individualized support to help you craft and present a high
      quality capstone project that you can use to apply to college,
      internships, or job opportunities.
    </Fragment>
  ),
  benefitsList: [
    '11 opportunities for exclusive time with instructors and industry experts',
    '4 opportunities for one-on-one support, mentorship, and feedback to guide you through your capstone project',
    'A recording of your capstone presentation to include in portfolios and applications',
    'Monthly engagement events such as CEO coffee chats, fireside chats with faculty and industry experts, and panels with program alumni',
    'A guaranteed spot in one of our future programs',
    'An item of your choice from The Coding School store',
  ],
  psText: [
    'In order to register for this opportunity, be sure to select the two-semester course option and either full payment or payment plan as your payment selection.',
    // 'Quantum Scholars or AI Scholars Track Price: $2,805 USD',
  ],
  img: {
    src: courseBenefitsImg,
    alt: 'Course Benefits',
  },
  bannerLinks: [
    {
      linkText: (
        <Fragment>
          <span>
            Interest Form
            {/* <span>Register Now</span> */}
          </span>
        </Fragment>
      ),
      isExternal: true,
      link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
    },
    // {
    //   linkText: (
    //     <Fragment>
    //       <span>
    //         Artificial Intelligence
    //         <span>Apply Now</span>
    //       </span>
    //     </Fragment>
    //   ),
    //   link: "/train-course-application",
    //   isExternal: false,
    // },
  ],
}
