import classNames from "classnames";

import styles from "./styles.module.scss";
import { KeyDetails } from "./components/KeyDetails";
import { HowToApply } from "./components/HowToApply";
import { HowItWorks } from "./components/HowItWorks";
import { WhatStudentsLearn } from "./components/WhatStudentsLearn";
import { Scholarships } from "./components/Scholarships"

export const CourseOverview = (props) => {
  const { data } = props;
  return (
    <div className={styles.coursesOverview}>
      <div className="container">
        <h3 className={styles.heading}>Course Overview</h3>
        <div className={styles.coTabs}>
          <div className="row align-items-center">
            <div className="col-12 col-lg-5 mb-30 mb-lg-0">
              <div
                className={classNames(styles.cotLinks, "nav row")}
                role="tablist"
              >
                {data.map((item, idx) => {
                  return (
                    <a
                      id={`co-tab-${idx + 1}`}
                      data-toggle="tab"
                      href={`#coTab${idx + 1}`}
                      role="tab"
                      aria-controls="coTab"
                      aria-selected={idx === 0 ? "true" : "false"}
                      className={classNames(
                        "col-12 col-md-6 col-lg-12 mb-lg-15 mb-15",
                        { active: idx === 0 }
                      )}
                    >
                      <span className={styles.cotWrap}>
                        <h6 className={styles.tabTitle}>
                          <span className={styles.numbering}>{idx + 1}</span>
                          {item.tabTitle}
                        </h6>
                      </span>
                    </a>
                  );
                })}
              </div>
            </div>

            <div className="col-12 col-lg-7">
              <div
                className={classNames(styles.tabContent, "tab-content")}
                id="co-tab-content"
              >
                {data.map((item, idx) => {
                  return (
                    <div
                      className={classNames("tab-pane fade show", {
                        active: idx === 0,
                      })}
                      id={`coTab${idx + 1}`}
                      role="tabpanel"
                      aria-labelledby={`co-tab-${idx + 1}`}
                      key={idx}
                    >
                      {idx === 0 && <KeyDetails data={item.tabContent} />}
                      {idx === 1 && <HowItWorks data={item.tabContent} />}
                      {idx === 2 && (
                        <WhatStudentsLearn data={item.tabContent} />
                      )}
                      {idx === 3 && <HowToApply data={item.tabContent} />}
                      {idx === 4 && <Scholarships data={item.tabContent} />}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
