import styles from "./styles.module.scss";

export const TrainAwsPdApplication = () => {
  return (
    <div className={styles.trainAwsPdApplication}>
      <div className="container">
        <div className={styles.iframeContainer}>
           <iframe
            id="JotFormIFrame-242396111330143"
            title="Intro to Machine Learning Bootcamp Part I - Summer 2024"
            // src="https://form.jotform.com/240505645179156"
            src="https://form.jotform.com/242396111330143"
          >
          </iframe>
        </div>
      </div>
    </div>
  );
};
