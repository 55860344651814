import Slider from 'react-slick'
import { CarouselNext } from 'component/Common/CarouselNext'
import { CarouselPrev } from 'component/Common/CarouselPrev'

import {
  studentTestimonials,
  schoolPartnersTestimonials,
  instructorTestimonials,
  testimonialsTab,
} from 'data'

import styles from './styles.module.scss'
import classNames from 'classnames'

export const Testimonial = () => {
  var settings = {
    dots: true,
    arrows: true,
    autoplay: false,
    nextArrow: <CarouselNext />,
    prevArrow: <CarouselPrev />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
  }

  return (
    <div
      className={styles.testimonialSec}
      data-aos="fade-up"
      data-aos-duration="500"
    >
      <div className="container">
        <div className="visual-text text-center mb-30">
          <h3 className={styles.heading}>Testimonials</h3>
        </div>

        <ul className={classNames(styles.tabs, 'nav nav-tabs')}>
          {testimonialsTab.map((e, i) => {
            return (
              <li key={i}>
                <a
                  className={i === 0 ? 'active' : null}
                  data-toggle="tab"
                  href={e['href']}
                >
                  {e['text']}
                </a>
              </li>
            )
          })}
        </ul>
      </div>

      <div
        className={styles.testimoniaSecBg}
        style={{
          backgroundImage: `url("/images/testimonial-bg-new.png")`,
        }}
      >
        <div className="container">
          {/* <svg
            className={styles.quoteIcon}
            viewBox="0 0 512 512"
            width="100"
            title="quote-left"
          >
            <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z" />
          </svg> */}
          <div className={classNames(styles.tabContent, 'tab-content')}>
            <div id="students" className="tab-pane active">
              <div className={styles.testimonials}>
                <Slider className={styles.slider} {...settings}>
                  {studentTestimonials.map((item, index) => {
                    return (
                      <div className={styles.sliderContent} key={index}>
                        <img src={`${item['image']}`} alt={item['name']} />
                        <p className="mb-20">{item['testimony']}</p>
                        <h6>{item['name']}</h6>
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </div>

            <div id="partner-school" className="tab-pane">
              <div className={styles.testimonials}>
                <Slider className={styles.slider} {...settings}>
                  {schoolPartnersTestimonials.map((item, index) => {
                    return (
                      <div className={styles.sliderContent} key={index}>
                        <img src={`${item['image']}`} alt={item['name']} />
                        <p className="mb-20">{item['testimony']}</p>
                        <h6>{item['name']}</h6>
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </div>

            <div id="instructors" className="tab-pane">
              <div className={styles.testimonials}>
                <Slider className={styles.slider} {...settings}>
                  {instructorTestimonials.map((item, index) => {
                    return (
                      <div className={styles.sliderContent} key={index}>
                        <img src={`${item['image']}`} alt={item['name']} />
                        <p className="mb-20">{item['testimony']}</p>
                        <h6>{item['name']}</h6>
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
