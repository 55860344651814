import { Fade } from "react-awesome-reveal";
import founder from "assets/img/about/keira-new-updated.jpg";
import founderBg from "assets/img/about/founder-bg.png";

import styles from "./styles.module.scss";

export const OurFounder = () => {
  return (
    <div className={styles.ourFounderBlock}>
      <div className="container">
        <div className="row align-items-center position-relative">
          <Fade top>
            <img
              className={styles.founderVerticalText}
              src={founderBg}
              alt="founder"
            />
          </Fade>
          <div className="col-md-6">
            <Fade left>
              <img
                className={styles.founderPhoto}
                src={founder}
                alt="Founder"
              />
            </Fade>
          </div>

          <div className="col-md-6">
            <Fade right>
              <div className={styles.ourFounderContent}>
                <h3 className={styles.heading}>Our Founder</h3>
                <p>
                  <b>Kiera Peltz</b> is on a mission to prepare the next
                  generation for the future of work. She began learning how to
                  code while working at the White House on the Innovation and
                  Technology team and has been passionate about equitable
                  computer science education ever since. With a background in
                  policy, workforce development, and research, Kiera believes
                  computer science education is critical for everyone, not just
                  those planning on entering STEM fields, and is committed to
                  ensuring every individual has access to high quality,
                  accessible technical training. Kiera has been named a 2023
                  Forbes 30 Under 30 Leader in Social Impact. She is the lead PI
                  on a 3-year grant from the U.S. Department of Defense NDEP and
                  is on the advisory board for the NSF-funded QuSTEAM. She
                  graduated magna cum laude from Brown University and holds two
                  master degrees from the University of Cambridge, where she
                  graduated with Distinction, and from Tsinghua University. She
                  is a Gates-Cambridge and Schwarzman Scholar.
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};
