import { Fragment } from "react";
import img from "assets/img/research/program-overview.png";

export const programOverview = {
  title: (
    <Fragment>
      Program{" "}
      <span className="text-animated">
        <span>Overview</span>
      </span>
    </Fragment>
  ),
  desc: (
    <Fragment>
      This program offers an{" "} <span className="text-bold">unprecedented opportunity</span> for high school students to develop tangible technical skills in one of the world's most sought after areas of tech - Data Science - which has applications across every industry from medicine to sports. Transforming from zero knowledge to a full skill set in data science, students will be trained in conducting research and have the opportunity to work on current research being conducted at the Columbia University Data Science Institute. Serving as research assistants to{" "} professors and PhD candidates, students will develop a research project and presentation to showcase in their college applications.
    </Fragment>
  ),
  img: {
    src: img,
    alt: "Program Overview",
  },
};
