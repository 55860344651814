import classNames from "classnames";

import Slider from "react-slick";
import { CarouselNext } from "component/Common/CarouselNext";
import { CarouselPrev } from "component/Common/CarouselPrev";

import styles from "./styles.module.scss";

export const StudentsTestimonials = (props) => {
  const { data, isImgFirst, titleClass, descriptionClass } = props;

  var settings = {
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CarouselNext iconClassName={styles.arrow} />,
    prevArrow: <CarouselPrev iconClassName={styles.arrow} />,
  };

  return (
    <section className={styles.studentsTestimonials}>
      <div className="container">
        <div className="row align-items-end">
          <div
            className={classNames(
              "col-12 col-lg-8",
              isImgFirst ? "order-lg-2" : ""
            )}
          >
            <header className={styles.sectionInfo}>
              <h4 className={classNames(styles.title, titleClass)}>
                {data?.title}
              </h4>
            </header>
            <div className={styles.sliderWrap}>
              <p className={classNames(styles.desc, descriptionClass)}>
                {data?.desc}
              </p>
              <Slider {...settings} className={styles.testimonialSlider}>
                {data?.testimonials?.map((item, idx) => (
                  <div className={styles.testimonyWrap} key={idx}>
                    <span className={styles.quote}>&ldquo;</span>
                    <p className={styles.testimony}>{item?.testimony}</p>
                    <p className={styles.testifier}>{item?.testifier}</p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div
            className={classNames(
              "col-12 col-lg-4 d-none d-lg-block",
              isImgFirst ? "order-lg-1" : ""
            )}
          >
            <img
              src={data?.studentImg}
              className={classNames(styles.studentImg, "img-fluid")}
              alt="Student"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
