import classNames from "classnames";
import styles from "./styles.module.scss";

export const Offerings = (props) => {
  const { data, iconClassName } = props;
  return (
    <div className={styles.trainOfferings}>
      <div className="container">
        <div className={styles.wrap}>
          <div className={classNames(styles.offeringsRow, 'row')}>
            {data?.map((item, idx) => (
              <div
                className={classNames(styles.item, "col-6 col-lg-3")}
                key={idx}
              >
                <div className={styles.content}>
                  {item?.src && <img src={item?.src} alt={item?.title} />}
                  {item?.svg && (
                    <div className={iconClassName}>{item?.svg}</div>
                  )}
                  <h4 className={styles.title}>{item?.title}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
