import { Fragment } from "react";

export const courseOverview = [
  {
    tabTitle: "Key Details",
    tabContent: {
      keyPoints: [
        {
          icon: "fa-solid fa-map-location-dot",
          point: "Virtual with live instruction",
        },
        {
          icon: "fa-solid fa-calendar-days",
          point: (
            <Fragment>
              {/* <span className="d-block">Fall 2024</span> */}
              {/* <span className="d-block">Sept 29 - Oct 12</span> */}
              Coming soon
            </Fragment>
          ),
        },
        { icon: "fa-solid fa-sack-dollar", point: "FREE for participants" },
        {
          icon: "fa-solid fa-clipboard-list",
          point: "Prerequisites: Community College, HBCU & MSI STEM educators",
        },
      ],
    },
  },
  {
    tabTitle: "How It Works",
    tabContent: {
      headline: "Educators participate in three daily components.",
      keyPoints: [
        {
          icon: "fa-solid fa-person-chalkboard",
          point: "Lecture",
        },
        {
          icon: "fa-solid fa-flask",
          point: "Lab with Teaching Assistant",
        },
        { icon: "fa-solid fa-diagram-project", point: "Homework or project" },
      ],
      description:
        "By the end of the 2-week bootcamp, educators will have ML skills and knowledge to take back into their classrooms to continue the education for their students.",
      highlightedPoint: [
        "Upon successful completion of the 2-week bootcamp, educators will receive a stipend of $500 and a certificate of completion.",
        <Fragment>
          An optional <i>Introduction to ML Part II Bootcamp</i> Will follow in order to reinforce and further advance skills in ML.
        </Fragment>,
      ],
    },
  },
  {
    tabTitle: "What you'll learn",
    tabContent: {
      descriptions: [
        "Educators will develop foundational skills in Python, data science, and machine learning. They'll experience AI at the intersection of fields such as healthcare, robotics and social media.",
        "One of the most important topics covered in the course is bias in AI in which educators will learn what it means to become a Responsible AI leader.",
      ],
      topicsCovered: "Key concepts covered during parts I & II bootcamps:",
      pointsList: [
        "ML Algorithms, such as KNN and k-means clustering",
        "Data visualizations",
        "AI Ethics",
        "Reinforcement Learning",
        "Deep Learning",
        "Natural Language Processing (NLP)",
      ],
    },
  },
  {
    tabTitle: "How To Apply",
    tabContent: {
      descriptions: [
        "This course is open to community college, HBCU, & MSI STEM faculty. To apply, educators are required to complete a short application form.",
        "We're looking for participants with enthusiasm to learn skills in the field of AI and a passion to share AI/ML education with your students.",
      ],
      highlightedPoint: [
        <Fragment>
          <span className="text-bold">
            Applications are accepted on a rolling basis - the earlier you apply
            the better chance you have of acceptance.
          </span>
        </Fragment>,
      ],
      button: {
        buttonText: 'Interest Form',
        isExternal: true,
        link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
      },
    },
  },
];
