import styles from './styles.module.scss'

export const StemEducation = () => {
  return (
    <div
      className="container mt-50 mb-100"
      data-aos="fade-up"
      data-aos-duration="500"
    >
      <div className={styles.visualText}>
        <h4 className="text-brand">
          We&apos;re reimagining STEM education and workforce development to
          empower every student with the technical skills necessary for the
          <span className="highlight-text"> jobs of the future.</span>
        </h4>
        <p className="text-center">
          We focus on skill building and workforce development in technologies
          that are going to change the world over the next decade, such as
          Quantum Computing, Artificial Intelligence, and Big Data. With a
          commitment to <span className="text-bold">accessible</span>,{' '}
          <span className="text-bold">
            supportive computer science education
          </span>
          , we offer learning pathways and programs for individuals at every
          stage of their STEM journey, including:{' '}
          <span className="text-gold text-italic text-bold">
            K-12 students and educators, community college and university
            students and faculty, and members of the workforce
          </span>
          .
        </p>
      </div>
    </div>
  )
}
