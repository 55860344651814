import classNames from "classnames";

import styles from "./styles.module.scss";

export const CoursesBy = (props) => {
  const { title, logos, isThinner } = props;
  return (
    <div className={styles.coursesBy}>
      <div className="container">
        <div className={classNames({ [styles.thinner]: isThinner })}>
          <h3 className={classNames(styles.subHeading, "text-blue-gradient")}>
            {title}
          </h3>
          <ul className={classNames(styles.cbList, "list-unstyled mb-0")}>
            {logos.map((item, idx) => {
              return (
                <li key={idx}>
                  <img className="img-fluid" src={item.img} alt={item.alt} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
