import { useState, useEffect } from "react";
import { bannerWords } from "data";

import "./HomeBannerAnimation.scss";

export const HomeBannerAnimation = () => {
  const [word, setWord] = useState(null);

  useEffect(() => {
    const wordCount = bannerWords.length;
    var index = 0;
    const interval = setInterval(function () {
      setWord(bannerWords[index++ % wordCount]);
    }, wordCount * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="scene">
      <div className="wordsWrap">
        <span className="word">{word}</span>
      </div>
      <div className="cube">
        <div className="cube__face cube__face--top"> </div>
        <div className="cube__face cube__face--bottom"> </div>
        <div className="cube__face cube__face--left"> </div>
        <div className="cube__face cube__face--right"> </div>
        <div className="cube__face cube__face--front"> </div>
        <div className="cube__face cube__face--back"> </div>
      </div>
    </div>
  );
};
