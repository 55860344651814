import { Fragment } from "react";

import { Banner } from "component/SummerPrograms/Banner";
import { BecomeFutureLeader } from "component/SummerPrograms/BecomeFutureLeader";
import { EmergingTechPrograms } from "component/SummerPrograms/EmergingTechPrograms";

import { banner, emergingTech } from "data/summerPrograms";

export const SummerPrograms = () => {
  return (
    <div className="summerPrograms">
      <Banner data={banner} />
      <BecomeFutureLeader
        data={
          <Fragment>
            This summer don't just learn how to code… become a{" "}
            <span>future leader</span> in emerging tech
          </Fragment>
        }
      />
      <EmergingTechPrograms data={emergingTech} />
    </div>
  );
};
