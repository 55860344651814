import { Banner } from 'component/Research/Banner'
import { ProgramOverview } from 'component/Research/ProgramOverview'
import { ProgramDetails } from 'component/Research/ProgramDetails'
import { WhatStudentsLearn } from 'component/Research/WhatStudentsLearn'
import { Offerings } from 'component/Courses/Offerings'
import { HowToApply } from 'component/TrainCourse/CourseOverview/components/HowToApply'
import { StepsToApply } from 'component/Research/StepsToApply'
import { Logistics } from 'component/Research/Logistics'

import {
  banner,
  programOverview,
  programDetails,
  whatStudentsLearn,
  offerings,
  howToApply,
  stepsToApply,
  logistics,
  techRequirements,
} from 'data/hsAiResearch'

import styles from './styles.module.scss'

export const HighSchoolResearch = () => {
  return (
    <div className={styles.research}>
      <Banner data={banner} hasOverlay={true} />
      <ProgramOverview data={programOverview} />
      <ProgramDetails data={programDetails} />
      <WhatStudentsLearn
        data={whatStudentsLearn}
        isResearch={true}
        isBulletInline={false}
        isExpanded={true}
      />
      <Offerings data={offerings} iconClassName={styles.icon} />
      <div className="bg-gray py-50 text-center">
        <div className="container">
          <HowToApply data={howToApply} />
        </div>
      </div>
      <StepsToApply data={stepsToApply} />
      <Logistics data={logistics} hasBgOverlay={true} />
      <div className="bg-gray py-50 text-center">
        <div className="container">
          <HowToApply data={techRequirements} />
        </div>
      </div>
    </div>
  )
}
