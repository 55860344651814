import { Fragment } from "react";

import lecture from "assets/img/courses/webinar.png";
import lab from "assets/img/courses/flask.png";
import homework from "assets/img/courses/clipboard.png";

export const courseComponents = {
  sectionTitle: (
    <Fragment>
      Course{" "}
      <span className="text-animated">
        <span>Components</span>
      </span>
    </Fragment>
  ),
  sectionDescription: "Courses consists of three weekly components",
  courseComponents: [
    { img: lecture, title: "Lecture" },
    { img: lab, title: "Hands-on Lab" },
    { img: homework, title: "Homework" },
  ],
  courseText:
    "Optional office hours and homework review sessions will be available for extra support.",
};
