import { Data, Research, Scholar, University } from 'component/Common/Icons'

export const offerings = [
  {
    svg: <Data />,
    title: 'Continue learning AI & data science',
  },
  {
    svg: <Research />,
    title: 'Participate in research projects',
  },
  {
    svg: <Scholar />,
    title: 'Receive mentorship from university faculty and PhDs',
  },
  {
    svg: <University />,
    title: 'Increase college competitiveness',
  },
]
