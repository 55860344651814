export const footerLinks = [
  {
    label: "Home",
    link: "/",
    active: false,
  },
  {
    label: "About us",
    link: "/about_us",
    active: false,
  },
  {
    label: "Get involved",
    link: "/involved",
    active: false,
  },
  {
    label: "Donate",
    link: "/donate",
    active: false,
    external: false,
  },
  // {
  //   label: "CodeConnects",
  //   link: "https://codeconnects.org",
  //   active: false,
  //   external: true,
  // },
  {
    label: "Qubit by Qubit",
    link: "https://www.qubitbyqubit.org",
    active: false,
    external: true,
  },
  {
    label: "Train",
    link: "/train",
    active: false,
  },
];
