import classNames from "classnames";
import styles from "./styles.module.scss";

export const CreatedBy = (props) => {
  const { data } = props;
  return (
    <section className={classNames(styles.createdBy, "bg-blue-gradient")}>
      <div className="container">
        <div className={styles.wrap}>
          <p className={styles.desc}>{data?.tagline}</p>
          <ul className={classNames(styles.logos, "p-0 m-0")}>
            {data?.logos?.map((item, idx) => (
              <li key={idx}>
                <img src={item?.src} alt={item?.alt} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
