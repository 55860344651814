import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

export const BringAi = () => {
  return (
    <section className={styles.bringAi}>
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div
              className={styles.tagline}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <h4>High School Educators & School Administrators</h4>
            </div>
            <div
              className={styles.headline}
              data-aos="fade-up"
              data-aos-duration="600"
            >
              <h2>
                BRING <span className="text-brand-light">AI</span> TO YOUR
                SCHOOL
              </h2>
            </div>
            <div
              className={styles.desc}
              data-aos="fade-up"
              data-aos-duration="700"
            >
              <p>
                Our goal is to provide every high school student with real world
                AI skills. Currently, there are not enough qualified high school
                educators to teach AI in high schools. We&#8217; ve partnered
                with 100+ schools and school districts to bring education in
                emerging technologies to students around the U.S. and globe. Be
                one of the first schools to offer students the chance to learn
                about the exciting world of AI.
              </p>
              <Link
                to="/school-partner-info"
                className="btn btn-gradient-2 text-white"
              >
                Connect With Us
              </Link>
            </div>
          </div>
          <div className="col-md-5"></div>
        </div>
      </div>
    </section>
  );
};
