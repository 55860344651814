import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { headerLinks } from 'data/links'

import { ArrowDown } from 'component/Icons'
import TCSLogo from 'assets/img/common/TCS-logo.png'

import styles from './styles.module.scss'

export const Header = () => {
  const location = useLocation()
  let route = location.pathname.slice(1)

  const renderClass = () => {
    if (route === '' || route === 'train' || route === 'train-course') {
      return styles.transparentBg
    } else {
      return
    }
  }

  return (
    <header>
      {route === '' && (
        <div className={styles.alertBanner}>
          <div className="container">
            <p>
              📢 Summer 2025 courses announced soon -{' '}
              {/* <Link to={'/course-application'}>Sign up today</Link>! */}
              <a href="https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY" target="_blank" rel='noreferrer'>
                2025 Interest Form
              </a>
            </p>
          </div>
        </div>
      )}
      <nav
        className={classNames(
          styles.topNav,
          renderClass(),
          'navbar navbar-expand-lg'
        )}
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={TCSLogo} className={styles.logo} alt="Logo" />
          </Link>
          <button
            className={styles.navbarToggler}
            type="button"
            data-toggle="collapse"
            data-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className={styles.icon}></span>
            <span className={styles.icon}></span>
            <span className={styles.icon}></span>
          </button>

          <div className="collapse navbar-collapse" id="navbar">
            <ul className="nav navbar-nav align-items-center ml-auto">
              {headerLinks.map((item, idx) => {
                return (
                  <li
                    className={classNames('nav-item', {
                      // eslint-disable-next-line no-useless-computed-key
                      ['dropdown']: item.dropdownItems,
                    })}
                    key={`nav-item-${idx}`}
                  >
                    {item.isExternal ? (
                      <a
                        className={classNames(
                          { [item.isButton]: item.isButton },
                          'nav-link'
                        )}
                        href={item.link}
                        role={item.dropdownItems && 'button'}
                        data-toggle={item.dropdownItems && 'dropdown'}
                        aria-expanded={item.dropdownItems && 'false'}
                        onClick={
                          item?.dropdownItems
                            ? e => e.preventDefault()
                            : () => {}
                        }
                        target={item?.newTab ? '_blank' : ''}
                        rel={item?.newTab ? 'noreferrer' : ''}
                      >
                        {item.label} {item.dropdownItems && <ArrowDown />}
                      </a>
                    ) : (
                      <Link
                        className={classNames(
                          { [item.isButton]: item.isButton },
                          'nav-link'
                        )}
                        to={item.link}
                        onClick={
                          item?.dropdownItems
                            ? e => e.preventDefault()
                            : () => {}
                        }
                      >
                        {item.label} {item.dropdownItems && <ArrowDown />}
                      </Link>
                    )}
                    {item.dropdownItems && (
                      <div className="dropdown-menu">
                        {item.dropdownItems.map((dropdownItem, index) => {
                          return (
                            <>
                              {dropdownItem.isExternal ? (
                                <a
                                  className="dropdown-item"
                                  href={dropdownItem.link}
                                  key={index}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {dropdownItem.label}
                                </a>
                              ) : (
                                <Link
                                  className="dropdown-item"
                                  to={dropdownItem.link}
                                  key={index}
                                >
                                  {dropdownItem.label}
                                </Link>
                              )}
                            </>
                          )
                        })}
                      </div>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
