import mit from "assets/img/common/mitnews.svg";
import los from "assets/img/common/los.svg";
import businessInsider from "assets/img/common/business-insider.svg";
import insideQuantum from "assets/img/common/inside-quantum.svg";
import quantumTech from "assets/img/common/quantum-tech.svg";
import builtLa from "assets/img/common/built-la.svg";
import american from "assets/img/common/american.svg";
import techGig from "assets/img/common/tech-gig.svg";
import sLogo from "assets/img/common/s-logo.svg";

export const featuredLogo = [
  {
    src: mit,
  },
  {
    src: los,
  },
  {
    src: businessInsider,
  },
  {
    src: insideQuantum,
  },
  {
    src: quantumTech,
  },
  {
    src: builtLa,
  },
  {
    src: american,
  },
  {
    src: techGig,
  },
  {
    src: sLogo,
  },
];
