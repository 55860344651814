import virtual from "assets/img/train/icon-virtual.png";
import live from "assets/img/train/icon-live.png";
import accredited from "assets/img/train/icon-accredited.png";

import styles from "./styles.module.scss";

export const Offerings = () => {
  return (
    <div className={styles.trainOfferings}>
      <div className="container">
        <div className={styles.wrap}>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className={styles.content}>
                <img src={virtual} alt="Virtual" />
                <h4 className={styles.title}>Virtual</h4>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className={styles.content}>
                <img src={live} alt="Live Instruction" />
                <h4 className={styles.title}>Live Instruction</h4>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className={styles.content}>
                <img src={accredited} alt="Accredited Course" />
                <h4 className={styles.title}>Accredited Course</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
