import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const EmergingCourse = (props) => {
  const { data } = props;
  return (
    <div className={styles.emergingCourse}>
      <div className={classNames(styles.containerBig, "container")}>
        <header>
          <h4 className={styles.sectionTitle}>
            {data?.sectionData?.sectionHeading}
          </h4>
          <p className={styles.sectionDescription}>
            {data?.sectionData?.sectionDescription}
          </p>
        </header>
      </div>

      <div className={styles.cardArea}>
        <div className="container">
          <div
            className={classNames(styles.coursesTabs, 'nav mb-30')}
            role="tablist"
          >
            {data?.coursesData?.map((item, idx) => (
              <a
                id={`${idx}-tab`}
                data-toggle="tab"
                href={`#courses-${idx}`}
                role="tab"
                aria-controls={`${idx}`}
                aria-selected="false"
                className={classNames(
                  styles.tabTitle,
                  idx === 0 ? 'active' : null
                )}
                key={idx}
              >
                <span>
                  {item?.sectionTitle}
                </span>
              </a>
            ))}
          </div>
          <div className="tab-content mb-50" id="coursesTabContent">
            {data?.coursesData?.map((item, idx) => (
              <div
                className={classNames(
                  'tab-pane fade',
                  idx === 0 ? 'show active' : null
                )}
                id={`courses-${idx}`}
                role="tabpanel"
                aria-labelledby={`${Object.keys(item)}-tab`}
                key={idx}
              >
                <div className="row">
                  {item?.cards?.map((item, idx) => (
                    <div className={classNames('col-12 col-lg-6', item.wrapperClassName)} key={idx}>
                      <div className={styles.cardWrap}>
                        <div className={styles.imgWrap}>
                          <img src={item?.cardImg} alt={item?.cardTitle} />
                          <h5 className={styles.cardTitle}>{item?.cardTitle}</h5>
                        </div>
                        <div className={styles.contentWrap}>
                          <p className={styles.cardSubtitle}>{item?.cardSubtitle}</p>
                          <ul
                            className={classNames(styles.keyPoints, "list-unstyled")}
                          >
                            {item?.keyPoints.map((item, idx) => (
                              <li key={idx}>
                                <span>{item}</span>
                              </li>
                            ))}
                          </ul>
                          <div className={styles.buttons}>
                            {item?.button?.map((item, idx) => {
                              return item.external ? (
                                <a
                                  key={idx}
                                  href={item.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={classNames(
                                    styles.button,
                                    "btn btn-gradient-2 text-white rounded-0"
                                  )}
                                >
                                  {item.text}
                                </a>
                              ) : (
                                <Link
                                  key={idx}
                                  to={item.link}
                                  className={classNames(
                                    styles.button,
                                    "btn btn-gradient-2 text-white rounded-0"
                                  )}
                                >
                                  {item.text}
                                </Link>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
