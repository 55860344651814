import { Fragment } from 'react'

import bg from 'assets/img/involved/volunteer.png'

import quantumComputing from 'assets/img/home/upcoming-programs-5.jpg'
import techCampMiddleSchool from 'assets/img/summer-programs/emerging-tech-new.jpg'
import techCamp from 'assets/img/summer-programs/tech-camp.jpg'

import quantumComputingLab from 'assets/img/home/quantum-computing-lab.png'
import dataScienceResearch from 'assets/img/home/k-12-research-opportunity.jpg'
import techCampHighSchool from 'assets/img/summer-programs/emerging-tech-3.webp'
import hspAiBigData from 'assets/img/summer-programs/ai-big-data.jpg'
import ppeArtificialIntelligence from 'assets/img/summer-programs/quantum-research.jpg'
import hspAiResearch from 'assets/img/summer-programs/ai-research.jpg'

import quantumImmersion from 'assets/img/summer-programs/uni-quantum-career-2.jpg'

import aiCloud from 'assets/img/summer-programs/ai-cloud.jpg'
import quantumComputingPd from 'assets/img/summer-programs/quantum-computing-pd.jpg'

import emergingTechImg from 'assets/img/summer-programs/emerging-tech-new-2.jpg'
import aiBigData from 'assets/img/summer-programs/ai-big-data-2.jpg'
import quantumComputingProgram from 'assets/img/summer-programs/quantum-computing.jpg'

import iconAI from 'assets/img/summer-programs/icon-ai.png'
import iconCybersecurity from 'assets/img/summer-programs/icon-cybersecurity.png'
import iconHealthcare from 'assets/img/summer-programs/icon-healthcare.png'
import iconWebDevelopment from 'assets/img/summer-programs/icon-web-development.png'
import iconArtAndTec from 'assets/img/summer-programs/icon-art-tech.png'

import etSlideOne from 'assets/img/summer-programs/et-slide-1.png'
import etSlideTwo from 'assets/img/summer-programs/et-slide-2.png'
import etSlideThree from 'assets/img/summer-programs/et-slide-3.png'
import etSlideFour from 'assets/img/summer-programs/et-slide-4.png'

import aiSlideOne from 'assets/img/summer-programs/ai-slide-1.jpg'

export const emergingTech = {
  header: {
    headerTitle: 'Our Emerging Tech Programs',
    headerDesc: (
      <Fragment>
        Accredited by WASC & partnered with the White House, DoD, Microsoft,
        Google Quantum AI, IBM Quantum, Stanford & more, we offer the{' '}
        <span className="text-bold text-brand">
          most supportive, in-depth & engaging
        </span>{' '}
        tech education for K-12 & beyond
      </Fragment>
    ),
  },
  sectionsButtons: [
    { sectionId: 'middleSchool', sectionName: 'Middle school' },
    { sectionId: 'highSchool', sectionName: 'High school' },
    { sectionId: 'university', sectionName: 'University' },
    { sectionId: 'educators', sectionName: 'Educators' },
  ],
  sectionsDetail: [
    {
      sectionData: {
        sectionId: 'virtualSummerCamps',
        sectionHeading: 'Virtual Summer Camps',
        cards: [
          {
            isHorizontal: false,
            cardClass: 'col-12 col-xl-4 mb-30 mb-xl-0',
            cardImg: emergingTechImg,
            cardTitle: ' Emerging Tech',
            cardSubtitle: (
              <Fragment>
                <span className="text-gold d-block">Our signature camp!</span>
                <span className="d-block">Two-week camp</span>
                Grades: 6 - 12
              </Fragment>
            ),
            keyPoints: [
              'Learn cutting-edge tech skills',
              'Code projects in AI, Cybersecurity, Healthcare+Tech, Art+Tech & more!',
              'Develop foundational Python skills',
            ],
            button: [
              {
                external: false,
                triggerModal: true,
                text: 'Learn More',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
            modalData: {
              title: (
                <Fragment>
                  Emerging Technologies Camp
                  <span className="d-block">July 8 - 19, 2024</span>
                  <span className="d-block">Grades: Incoming 6th - 12th</span>
                  <span className="d-block">Virtual</span>
                </Fragment>
              ),
              headerButton: {
                title: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
                isExternal: true,
              },
              desc: (
                <Fragment>
                  <h3>About the Camp</h3>
                  <p>
                    This is a jam packed two-week camp, in which students are
                    introduced to the breadth and depth of some of the most
                    cutting-edge technologies in today's world. Students will
                    learn the fundamentals of coding plus develop knowledge and
                    skills in emerging technology fields rarely taught to K-12
                    students, such as AI and Healthcare+Tech. Each day is filled
                    with hands-on activities and project building, allowing
                    students to gain real-world skills and work together
                    simulating a day-in-the-life of professional software
                    engineers.
                  </p>
                  <h3>What Students Learn</h3>
                  <p>
                    The purpose of the Emerging Tech Camp is for students to
                    develop real, usable foundational coding skills and gain
                    exposure to technology fields that are at the forefront and
                    shaping the technology revolution.
                  </p>
                  <p>
                    In the first week, students focus on learning the
                    fundamentals of coding in the programming language, Python -
                    one of the most widely used programming languages. They will
                    learn concepts fundamental to all programming languages,
                    including variables, conditionals, booleans, loops,
                    functions, and more.
                  </p>
                  <p>
                    In Week 2, students apply the coding skills they learned in
                    Week 1 to some of the most cutting-edge tech fields today.
                    Each day, they learn skills in a different emerging
                    technology and work on hands-on activities and projects in
                    the given field including Artificial Intelligence,
                    Cybersecurity, Data Science, Web Development and Art+Tech.
                    Students receive an Emerging Technologies certificate at the
                    end of the camp.
                  </p>
                  <p>The fields they will be exposed to each day are:</p>
                  <ul className="list-unstyled">
                    <li>
                      <img src={iconAI} alt="Artificial Intelligence" />
                      <span>Artificial Intelligence</span>
                    </li>
                    <li>
                      <img src={iconCybersecurity} alt="Cybersecurity" />
                      <span>Cybersecurity</span>
                    </li>
                    <li>
                      <img src={iconHealthcare} alt="Healthcare + Tech" />
                      <span>Healthcare + Tech</span>
                    </li>
                    <li>
                      <img src={iconWebDevelopment} alt="Web Development" />
                      <span>Web Development</span>
                    </li>
                    <li>
                      <img src={iconArtAndTec} alt="Art + Tech" />
                      <span>Art + Tech</span>
                    </li>
                  </ul>
                  <p>
                    Some of the projects students will learn to do using
                    programming include:
                  </p>
                </Fragment>
              ),
              slides: [
                { src: etSlideOne, alt: 'Emerging Tech Slide' },
                { src: etSlideTwo, alt: 'Emerging Tech Slide' },
                { src: etSlideThree, alt: 'Emerging Tech Slide' },
                { src: etSlideFour, alt: 'Emerging Tech Slide' },
              ],
              logistics: [
                {
                  title: 'Grades',
                  info: (
                    <Fragment>
                      Incoming 6th - 12th
                      <span className="d-block">
                        Students will be divided into small groups based on
                        grade and skill level.
                      </span>
                    </Fragment>
                  ),
                },
                {
                  title: 'Dates',
                  info: (
                    <Fragment>
                      Two-week camp: July 8 - 19, 2024
                      <span className="d-block">
                        Students will meet daily Monday - Friday, 9am - 12pm PST
                        (12-3pm EST)
                      </span>
                    </Fragment>
                  ),
                },
                { title: 'Prerequisites', info: <Fragment>None</Fragment> },
                {
                  title: 'Tuition',
                  info: (
                    <Fragment>
                      $695 with early registration.
                      {/* <small className="text-italic d-block">*Register for 2 or more camps and save $50 on each additional camp!</small> */}
                    </Fragment>
                  ),
                },
              ],
              buttons: [
                {
                  external: true,
                  text: '2025 Interest Form',
                  link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
                },
              ],
              psTexts: [
                {
                  title: 'Tech Requirements',
                  desc: [
                    'Summer programs are instructed live in English virtually during the EST time zone over the dates listed. Students will require access to reliable broadband on a device that is audio and video capable, ideally from a quiet and distraction-free environment. Devices may include computers, laptops, notebooks, and Chromebooks. Students will need to access Zoom and Google Co-lab from their devices to participate fully in the course.',
                  ],
                },
                {
                  title: 'Scholarships',
                  desc: [
                    'Limited scholarship funds are available to cover the cost of tuition for eligible, US-based students that exemplify need and alignment with The Coding School’s mission. Students from traditionally underrepresented backgrounds in STEM are encouraged to apply. The deadline to apply for a scholarship is May 31. Scholarship awardees will be notified by June 7.',
                    <Fragment>
                      Completing an application does not guarantee that an
                      applicant will receive a scholarship. Scholarships will be
                      distributed using a lottery system on a rolling basis
                      until the application closes on May 31. Applicants will be
                      notified by June 7 at the latest of one of three possible
                      outcomes: scholarship recipient, waitlist, or not selected
                      for a scholarship. If selected, recipients will have
                      72-hours to accept their spot. If waitlisted, applicants
                      will be contacted if a recipient does not accept their
                      scholarship, or if additional scholarships are funded.{' '}
                      <a
                        href="https://form.jotform.com/240518684104151"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gold"
                      >
                        Click here to apply for a scholarship.
                      </a>
                    </Fragment>,
                  ],
                },
              ],
            },
            dataTarget: 'emergingTech',
          },
          {
            isHorizontal: false,
            cardClass: 'col-12 col-xl-4 mb-30 mb-xl-0',
            cardImg: aiBigData,
            cardTitle: ' AI & Big Data',
            cardSubtitle: (
              <Fragment>
                {/* <span className="d-block">July 22 - 26</span> */}
                Grades: 9 - 12
              </Fragment>
            ),
            keyPoints: [
              'Learn real AI/ML skills',
              'Wrangle Big Data',
              <Fragment>
                Train computers to <span className="text-italic">think</span>
              </Fragment>,
              <Fragment>
                Learn modeling and build{' '}
                <span className="text-italic">smart</span> machines
              </Fragment>,
            ],
            button: [
              {
                external: false,
                triggerModal: true,
                text: 'Learn More',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
            modalData: {
              title: (
                <Fragment>
                  Artificial Intelligence & Big Data Camp
                  <span className="d-block">July 22 - 26, 2024</span>
                  <span className="d-block">Grades: Incoming 9th - 12th</span>
                  <span className="d-block">Virtual</span>
                </Fragment>
              ),
              headerButton: {
                title: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
                isExternal: true,
              },
              desc: (
                <Fragment>
                  <h3>About the Camp</h3>
                  <p>
                    Want to train computers to recognize visual cues and predict
                    outcomes? Interested in learning the technology behind
                    self-driving? Want to understand how Netflix chooses which
                    movies to recommend to you? This camp is for you!
                  </p>
                  <p>
                    In this camp, students delve into the exciting field of
                    Machine Learning (ML), a subfield of Artificial
                    Intelligence. Through hands-on learning, they work on
                    projects, such as image classification (where they will
                    train an ML algorithm to identify different types of
                    organisms), all while developing programming skills in
                    Python.
                  </p>
                  <h3>What Students Learn</h3>
                  <p>
                    Students develop foundational knowledge in Machine Learning.
                    Through project-based learning, students learn how to
                    utilize large datasets and program ML algorithms.
                  </p>
                  <p>Through the camp, students will:</p>
                  <ul className="not-inlined">
                    <li>
                      Develop real-world skills in scikit-learn - the most
                      popular and useful tool for ML
                    </li>
                    <li>
                      Learn to use the programming language, Python, for ML
                    </li>
                    <li>
                      Train MLalgorithms, such as decision trees and bayesian
                      networks
                    </li>
                    <li>Mitigate bias in ML outcomes</li>
                  </ul>
                </Fragment>
              ),
              slides: [{ src: aiSlideOne, alt: 'AI Slide' }],
              logistics: [
                {
                  title: 'Grades',
                  info: (
                    <Fragment>
                      Incoming 9th - 12th graders
                      <span className="d-block">
                        Students will be divided into small groups based on
                        grade.
                      </span>
                    </Fragment>
                  ),
                },
                {
                  title: 'Dates',
                  info: (
                    <Fragment>
                      One-week camp: July 22 - 26, 2024
                      <span className="d-block">
                        Students will meet daily Monday - Friday, 9am - 12pm PST
                        (12 - 3pm EST)
                      </span>
                    </Fragment>
                  ),
                },
                { title: 'Prerequisites', info: <Fragment>None</Fragment> },
                {
                  title: 'Tuition',
                  info: (
                    <Fragment>
                      $495
                      {/* <small className="text-italic d-block">*Register for 2 or more camps and save $50 on each additional camp!</small> */}
                    </Fragment>
                  ),
                },
              ],
              buttons: [
                {
                  external: true,
                  text: '2025 Interest Form',
                  link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
                },
              ],
              psTexts: [
                {
                  title: 'Tech Requirements',
                  desc: [
                    'Summer programs are instructed live in English virtually during the EST time zone over the dates listed. Students will require access to reliable broadband on a device that is audio and video capable, ideally from a quiet and distraction-free environment. Devices may include computers, laptops, notebooks, and Chromebooks. Students will need to access Zoom and Google Co-lab from their devices to participate fully in the course.',
                  ],
                },
                {
                  title: 'Scholarships',
                  desc: [
                    'Limited scholarship funds are available to cover the cost of tuition for eligible, US-based students that exemplify need and alignment with The Coding School’s mission. Students from traditionally underrepresented backgrounds in STEM are encouraged to apply. The deadline to apply for a scholarship is May 31. Scholarship awardees will be notified by June 7.',
                    <Fragment>
                      Completing an application does not guarantee that an
                      applicant will receive a scholarship. Scholarships will be
                      distributed using a lottery system on a rolling basis
                      until the application closes on May 31. Applicants will be
                      notified by June 7 at the latest of one of three possible
                      outcomes: scholarship recipient, waitlist, or not selected
                      for a scholarship. If selected, recipients will have
                      72-hours to accept their spot. If waitlisted, applicants
                      will be contacted if a recipient does not accept their
                      scholarship, or if additional scholarships are funded.{' '}
                      <a
                        href="https://form.jotform.com/240518684104151"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gold"
                      >
                        Click here to apply for a scholarship.
                      </a>
                    </Fragment>,
                  ],
                },
              ],
            },
            dataTarget: 'aiBig',
          },
          {
            isHorizontal: false,
            cardClass: 'col-12 col-xl-4 mb-30 mb-xl-0',
            cardImg: quantumComputingProgram,
            cardTitle: 'Quantum Computing',
            cardSubtitle: (
              <Fragment>
                {/* <span className="d-block">July 22 - Aug 2</span> */}
                Grades: 6 - 12 + University
              </Fragment>
            ),
            keyPoints: [
              'One-week camps',
              'Learn real Quantum skills',
              'Code on a Quantum Computer!',
              'Lead by Quantum Researchers',
            ],
            button: [
              {
                external: true,
                text: 'Learn More',
                link: 'https://www.qubitbyqubit.org/programs',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
          },
        ],
      },
    },
    {
      sectionData: {
        sectionId: 'middleSchool',
        sectionHeading: 'Middle School',
        cards: [
          {
            isHorizontal: true,
            cardClass: 'col-12 col-xl-6 mb-30',
            cardImg: quantumComputing,
            cardTitle: 'Quantum Computing Camp',
            cardSubtitle: (
              <Fragment>
                Grades: 6 - 9<span className="d-block">July 22 - 26</span>
              </Fragment>
            ),
            keyPoints: [
              'Be one of the first middle school students in the world to learn quantum computing',
              'Code on a real quantum computer!',
            ],
            button: [
              {
                external: true,
                text: 'Learn more',
                link: 'https://www.qubitbyqubit.org/middle-school-camp',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
          },
          {
            isHorizontal: true,
            cardClass: 'col-12 col-xl-6 mb-30',
            cardImg: techCampMiddleSchool,
            cardTitle: 'Emerging Tech Camp',
            cardSubtitle: (
              <Fragment>
                Grades: 6 - 12
                <span className="d-block">July 8 - 19</span>
              </Fragment>
            ),
            keyPoints: [
              'Learn skills in cutting-edge tech fields',
              'Create projects in AI, Cybersecurity, Healthcare & Tech, Web Development, Art+Tech and more!',
            ],
            button: [
              {
                external: false,
                text: 'Learn more',
                triggerModal: true,
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
            dataTarget: 'emergingTech',
          },
        ],
      },
      testimonials: {
        background: bg,
        testimony:
          'I loved learning the challenging concepts in quantum computing and to be able to apply what I learned during the hands-on activity. I am looking forward to further developing my understanding and my skills in quantum computing and be able to apply this to my future.',
        testifier: '8th grade student, Quantum Computing Camp',
      },
    },
    {
      sectionData: {
        sectionId: 'highSchool',
        sectionHeading: 'High School Programs',
        cards: [
          {
            isHorizontal: true,
            cardClass: 'col-12 col-xl-6 mb-30',
            cardImg: ppeArtificialIntelligence,
            cardTitle: 'Quantum Computing Camp',
            cardSubtitle: (
              <Fragment>
                Grades: 9 - 12+
                {/* <span className="d-block">July 29 - Aug 2</span> */}
              </Fragment>
            ),
            keyPoints: [
              'Be one of the first high school students in the world to learn quantum computing',
              'Learn real quantum skills',
              'Run code on a quantum computer!',
            ],
            button: [
              {
                external: true,
                text: 'Learn more',
                link: 'https://www.qubitbyqubit.org/hs-college-camps',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
          },
          {
            isHorizontal: true,
            cardClass: 'col-12 col-xl-6 mb-30',
            cardImg: dataScienceResearch,
            cardTitle: 'Data Science Research with Columbia University',
            cardSubtitle: (
              <Fragment>
                Grades: 10 - 13
                {/* <span className="d-block">July 8 - Aug 9</span> */}
              </Fragment>
            ),
            keyPoints: [
              'Increase college competitiveness',
              'Develop in-depth data science skills',
              'Collaborate with university faculty',
              'Create research project to showcase',
            ],
            button: [
              {
                external: false,
                text: 'Learn more',
                link: '/research',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
          },
          {
            isHorizontal: true,
            cardClass: 'col-12 col-xl-6 mb-30',
            cardImg: techCampHighSchool,
            cardTitle: 'Emerging Tech Camp',
            cardSubtitle: (
              <Fragment>
                Grades: 6 - 12
                {/* <span className="d-block">July 8 - 19</span> */}
              </Fragment>
            ),
            keyPoints: [
              'Learn cutting-edge tech fields',
              'Create projects in AI, cybersecurity, web dev',
              'Healthcare+tech & art+tech',
              'Develop foundational Python skills',
            ],
            button: [
              {
                external: false,
                triggerModal: true,
                text: 'Learn More',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
            dataTarget: 'emergingTech',
          },
          {
            isHorizontal: true,
            cardClass: 'col-12 col-xl-6 mb-30',
            cardImg: hspAiBigData,
            cardTitle: 'AI/Big Data Camp',
            cardSubtitle: (
              <Fragment>
                Grades: 9 - 12
                {/* <span className="d-block">July 22 - 26</span> */}
              </Fragment>
            ),
            keyPoints: [
              'Learn Artificial Intelligence skills',
              'Program Machine Learning algorithms',
              'Analyze & wrangle Big Data',
              "Train computers to 'think'",
            ],
            button: [
              {
                external: false,
                triggerModal: true,
                text: 'Learn More',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
            dataTarget: 'aiBig',
          },
          // {
          //   isHorizontal: true,
          //   cardClass: "col-12 col-xl-6 mb-30",
          //   cardImg: quantumComputingLab,
          //   cardTitle: "High School Quantum Research",
          //   cardSubtitle: (
          //     <Fragment>
          //       Grades: 10 - 13
          //       {/* <span className="d-block">June 17 - Aug 2</span> */}
          //       <span className="d-block text-gold">Prerequisite: QxQ Quantum Computing Course</span>
          //     </Fragment>
          //   ),
          //   keyPoints: [
          //     "Quantum lab experience",
          //     "Continue developing Quantum skills",
          //     "Work with Quantum professors",
          //     "Develop a research project",
          //   ],
          //   button: [
          //     {
          //       external: true,
          //       text: "Interest Form",
          //       link: "https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY",
          //     },
          //   ],
          // },
          // {
          //   isHorizontal: true,
          //   cardClass: "col-12 col-xl-6 mb-30",
          //   cardImg: hspAiResearch,
          //   cardTitle: "High School AI Research",
          //   cardSubtitle: (
          //     <Fragment>
          //       Grades: 10 - 13
          //       {/* <span className="d-block">June 17 - Aug 2</span> */}
          //       <span className="d-block text-gold">Prerequisite: TRAIN intro to AI Course</span>
          //     </Fragment>
          //   ),
          //   keyPoints: [
          //     "Conduct AI research",
          //     "Continue developing AI skills",
          //     "Work with university faculty",
          //     "Develop a research project",
          //   ],
          //   button: [
          //     {
          //       external: true,
          //       text: "Interest Form",
          //       link: "https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY",
          //     },
          //   ],
          // },
          // {
          //   isHorizontal: true,
          //   cardClass: "col-12 col-xl-6 mb-30",
          //   cardImg: ppeQuantumComputing,
          //   cardTitle: "Pre-AP CS Course",
          //   cardSubtitle: (
          //     <Fragment>
          //       Grades: 9 - 13
          //       <span className="d-block">July 17 - 28</span>
          //     </Fragment>
          //   ),
          //   keyPoints: [
          //     "Learn Java programming",
          //     "Gain confidence for school courses",
          //     "Develop coding projects",
          //     "Aligns with AP Computer Science material",
          //   ],
          //   button: [
          //     {
          //       external: true,
          //       text: "Learn more",
          //       link: "https://codeconnects.org/summercamps23.html",
          //     },
          //   ],
          // },
        ],
      },
      testimonials: {
        background: bg,
        testimony:
          'I gained significant research experience, and I learned an entire coding language. This program boosts my college app, and only spiked my interest in statistics and data science more with real life applications to the concepts I was already fascinated about.',
        testifier: '11th grade student, Data Science Research Program',
      },
    },
    {
      sectionData: {
        sectionId: 'university',
        sectionHeading: 'University Students Programs',
        cards: [
          {
            isHorizontal: true,
            cardClass: 'col-12 col-xl-6 mb-30',
            cardImg: quantumComputing,
            cardTitle: 'Quantum Computing Camp',
            cardSubtitle: (
              <Fragment>
                Grades: 9 - 12 + University
                <span className="d-block">July 29 - Aug 2</span>
              </Fragment>
            ),
            keyPoints: [
              'Learn real quantum skills',
              'Construct circuits and quantum gates',
              'Run code on a quantum computer!',
            ],
            button: [
              {
                external: true,
                text: 'Learn more',
                link: 'https://www.qubitbyqubit.org/hs-college-camps',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
          },
          {
            isHorizontal: true,
            cardClass: 'col-12 col-xl-6 mb-30',
            cardImg: quantumImmersion,
            cardTitle: 'Early Quantum Career Immersion',
            cardSubtitle: (
              <Fragment>
                University students
                <span className="d-block">
                  Summer 2024 registration now closed
                </span>
              </Fragment>
            ),
            keyPoints: [
              'Complete a research project or be placed in an industry internship',
              'Develop critical skills for a future career in quantum',
              'Learn about academic and career pathways from quantum mentors',
            ],
            button: [
              {
                external: true,
                text: 'Learn more',
                link: 'https://www.qubitbyqubit.org/eqci',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shre71VatNJI2oo0a',
              },
            ],
          },
          // {
          //   isHorizontal: true,
          //   cardClass: "col-12 col-xl-6 mb-30",
          //   cardImg: dataScienceResearch,
          //   cardTitle: "Data Science Research",
          //   cardSubtitle: (
          //     <Fragment>
          //       Grades: 10 - 13
          //       <span className="d-block">July 8 - August 9</span>
          //     </Fragment>
          //   ),
          //   keyPoints: [
          //     "Columbia University professors",
          //     "In-depth data science skills",
          //     "Conduct real-world research",
          //     "Develop a research project",
          //   ],
          //   button: [
          //     {
          //       external: false,
          //       text: "Learn more",
          //       link: "/research",
          //     },
          //   ],
          // },
          // {
          //   isHorizontal: true,
          //   cardClass: "col-12 col-xl-6 mb-30",
          //   cardImg: preApCs,
          //   cardTitle: "Pre-AP CS",
          //   cardSubtitle: (
          //     <Fragment>
          //       Grades: 9 - 13
          //       <span className="d-block">July 17 - 28</span>
          //     </Fragment>
          //   ),
          //   keyPoints: [
          //     "Learn Java programming",
          //     "Gain confidence for school courses",
          //     "Develop coding projects",
          //     "Aligns with AP Computer Science",
          //   ],
          //   button: [
          //     {
          //       external: true,
          //       text: "Learn more",
          //       link: "https://codeconnects.org/summercamps23.html",
          //     },
          //   ],
          // },
        ],
      },
      testimonials: {
        background: bg,
        testimony:
          'Qubit by Qubit has given me the opportunity to be introduced to quantum computing at a pace that is digestible and has provided insight on where I see myself in the field of quantum computing…Being surrounded by students that share your similar background is very empowering and has definitely supported my learning process.',
        testifier: 'University student, Quantum Computing Internship Program',
      },
    },
    {
      sectionData: {
        sectionId: 'educators',
        sectionHeading: 'Educator Summer PD',
        cards: [
          {
            isHorizontal: false,
            cardClass: 'col-12 col-xl-4 mb-30 mb-xl-0',
            cardImg: techCamp,
            cardTitle: 'Artificial Intelligence PD',
            cardSubtitle: (
              <Fragment>
                US Public School Educators
                <span className="d-block">July 22 - Aug 2, 2024</span>
              </Fragment>
            ),
            keyPoints: [
              'Learn about the cutting edge field of artificial intelligence',
              'Explore how to integrate AI/ML into your existing classes',
              'Develop a network of STEM Educators',
              'CEU Opportunity',
              'Create an account to apply by clicking the button below',
            ],
            button: [
              {
                external: false,
                text: 'Learn more',
                link: '/train-2-weeks-pd',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
          },
          {
            isHorizontal: false,
            cardClass: 'col-12 col-xl-4 mb-30 mb-xl-0',
            cardImg: aiCloud,
            cardTitle: (
              <Fragment>
                Intro to Machine Learning Bootcamp
                <small className="text-thin"> with</small> AWS
              </Fragment>
            ),
            cardSubtitle: (
              <Fragment>
                Community College, HBCU & MSI Faculty
                <span className="d-block">
                  {/* June 3 - 14 <span className="mx-10">|</span> July 8 - 19 */}
                  September 29 - October 12
                </span>
                2025 bootcamps announced soon
              </Fragment>
            ),
            keyPoints: [
              'Learn tangible artificial intelligence and machine learning skills',
              'Earn a stipend and CEU credits from Stanford University',
              'Collaborate with a community of educators nationwide',
            ],
            button: [
              {
                external: false,
                text: 'Learn more',
                link: '/train-aws-pd',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
          },
          {
            isHorizontal: false,
            cardClass: 'col-12 col-xl-4 mb-30 mb-xl-0',
            cardImg: quantumComputingPd,
            cardTitle: 'Quantum Computing PD',
            cardSubtitle: (
              <Fragment>
                Middle & High School Educators
                <span className="d-block">Aug 5 - 9, 2024</span>
              </Fragment>
            ),
            keyPoints: [
              'Learn real quantum skills',
              'Bring quantum to your students',
              'FREE 1-week course',
              'Community of STEM educators',
              'Code on a quantum computer',
            ],
            button: [
              {
                external: true,
                text: 'Learn more',
                link: 'https://www.qubitbyqubit.org/hs-pd',
              },
              {
                external: true,
                text: '2025 Interest Form',
                link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
              },
            ],
          },
        ],
      },
      testimonials: {
        background: bg,
        testimony:
          'This program was a true accelerator in my understanding, knowledge and ability to integrate ML and AI concepts into my teaching and learning. It is an intensive, and through this immersion in ML concepts and coding, you begin to think like a coder! I enjoyed this intensive PD for expanding my thinking about ML concepts broadly, and also how to apply them to my classroom and with my students. My colleagues, the experts, the program manager, all made this very accessible.',
        testifier: (
          <Fragment>
            High School Educator, TR
            <span className="text-bold text-gold">AI</span>N PD
          </Fragment>
        ),
      },
    },
  ],
}
