const Research = ({ className, color }) => {
  return (
    <div className={className}>
      <svg width="512" height="512" x="0" y="0" viewBox="0 0 470 470">
        <g>
          <g>
            <path
              d="M360.828,384.339c1.705-1.374,2.798-3.478,2.798-5.839c0-0.066,0-341,0-341c0-4.143-3.357-7.5-7.5-7.5H139.141   C133.79,12.647,117.605,0,98.52,0C79.742,0,63.311,12.514,57.906,30H33.874c-4.143,0-7.5,3.357-7.5,7.5v425   c0,4.143,3.357,7.5,7.5,7.5h238.187c2.425,0,4.527-1.092,5.902-2.794L360.828,384.339z M279.626,444.328V386h58.327   L279.626,444.328z M98.52,15c10.663,0,19.922,6.105,24.482,15H74.036C78.64,21.052,88.011,15,98.52,15z M41.374,45h84.646v52.5   c0,6.893-5.607,12.5-12.5,12.5s-12.5-5.607-12.5-12.5v-30c0-4.143-3.357-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v30   c0,15.163,12.337,27.5,27.5,27.5s27.5-12.337,27.5-27.5V45h207.606v326h-76.5c-4.143,0-7.5,3.357-7.5,7.5V455H41.374V45z"
              fill={color}
            />
            <path
              d="M296.126,345.5c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5H93.874c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5   H296.126z"
              fill={color}
            />
            <path
              d="M93.874,300.5h202.252c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5H93.874c-4.143,0-7.5,3.357-7.5,7.5   S89.731,300.5,93.874,300.5z"
              fill={color}
            />
            <path
              d="M93.874,255.5h202.252c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5H93.874c-4.143,0-7.5,3.357-7.5,7.5   S89.731,255.5,93.874,255.5z"
              fill={color}
            />
            <path
              d="M93.874,210.5h202.252c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5H93.874c-4.143,0-7.5,3.357-7.5,7.5   S89.731,210.5,93.874,210.5z"
              fill={color}
            />
            <path
              d="M93.874,165.5h202.252c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5H93.874c-4.143,0-7.5,3.357-7.5,7.5   S89.731,165.5,93.874,165.5z"
              fill={color}
            />
            <path
              d="M396.126,30h-10c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h2.5v410h-80.072c-4.143,0-7.5,3.357-7.5,7.5   s3.357,7.5,7.5,7.5h87.572c4.143,0,7.5-3.357,7.5-7.5v-425C403.626,33.357,400.269,30,396.126,30z"
              fill={color}
            />
            <path
              d="M436.126,30h-10c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h2.5v410h-2.5c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5   h10c4.143,0,7.5-3.357,7.5-7.5v-425C443.626,33.357,440.269,30,436.126,30z"
              fill={color}
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Research;
