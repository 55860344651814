import jamesFitch from "assets/img/about/board-of-advisors/james-fitch.jpg";
import yvetteMedina from "assets/img/about/board-of-advisors/yvette-medina.jpeg";
import davidTouretzky from "assets/img/about/board-of-advisors/david-s-touretzky.jpg";
import nathanielBastian from "assets/img/about/board-of-advisors/nathaniel-d-bastian.jpg";
import benIsecke from "assets/img/about/board-of-advisors/ben-isecke.jpg";
import andrewOliver from "assets/img/about/board-of-advisors/andrew-oliver.jpg";
import manuelaVeloso from "assets/img/about/board-of-advisors/manuela-veloso.jpg";
import halAbelson from "assets/img/about/board-of-advisors/hal-abelson.jpg";
import jeremyHoward from "assets/img/about/board-of-advisors/jeremy-howard.jpg";

export const TrainBoardAdvisors = [
  {
    avatar: jamesFitch,
    name: "Chief Master Sgt. James Fitch II",
    position: "Command Chief, Air Force Research Laboratory",
  },
  {
    avatar: yvetteMedina,
    name: "Yvette Medina",
    position: "Director, AI Bootcamps Program, Mark Cuban Foundation",
  },
  {
    avatar: davidTouretzky,
    name: "Dr. Dave Touretzky",
    position:
      " Research Professor at Carnegie Mellon University and Chair of AI4K12",
  },
  {
    avatar: nathanielBastian,
    name: "Dr. Nathaniel Bastian",
    position:
      "Assistant Professor of Operations Research& Data Science at West Point",
  },
  {
    avatar: andrewOliver,
    name: "Andrew Oliver",
    position: "Cloud and AI SWE at Microsoft",
  },
  {
    avatar: benIsecke,
    name: "Ben Isecke",
    position: "President of NJ CSTA & CS Teacher at Bergen County Academies",
  },
  // {
  //   avatar: manuelaVeloso,
  //   name: "Dr. Manuela Veloso",
  //   position: "Head of AI Research at J.P. Morgan & President AAA",
  // },
  // {
  //   avatar: halAbelson,
  //   name: "Dr. Hal Abelson",
  //   position: "Computer Science Professor at MIT",
  // },
  // {
  //   avatar: jeremyHoward,
  //   name: "Jeremy Howard",
  //   position: "Co-Founder at fast.ai",
  // },
];
