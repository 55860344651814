export const whoShouldJoin = {
  words: [
    "a Policymaker",
    "an Entertainer",
    "a Healthcare provider",
    "a Banker",
    "an Entrepreneur",
    "an Engineer",
    "a Teacher",
    "an Architect",
    "an Attorney",
    "a Scientist",
    "an Astronaut",
    "an Artist",
  ],
  text: "AI will impact nearly every field imaginable. We've designed this course so all high school students - irrespective of their background or future goals - will gain critical skills in AI.",
};
