export const faqs = [
  {
    id: 1,
    qu: "Who is eligible to apply?",
    an: "Actively teaching community college, HBCU, MSI & 4-year university CS faculty.",
  },
  {
    id: 2,
    qu: "Do I have to attend all lectures and labs?",
    an: "Attendance is mandatory. Attendance will be taken at the beginning of the lab section and will be factored into successful completion. Prior approval of excused absences will be considered on a case by case basis.",
  },
  {
    id: 3,
    qu: "What if I have to miss a class?",
    an: "A recording of the lecture will be available if you have to miss the lecture. For missed lectures or labs due to an emergency, you must email the program team as soon as possible at: train@the-cs.org to request an excused absence. Documentation may be required.",
  },
  {
    id: 4,
    qu: "What is the grading scale?",
    an: "We believe in creating a failure-free environment focused on learning, so this course is graded on a pass-fail scale.",
  },
];
