import { Fragment } from 'react'
import img from 'assets/img/summer-programs/uni-quantum-career.jpg'

export const whatStudentsLearn = {
  img: {
    src: img,
    alt: 'What StudentsLearn',
  },
  title: (
    <Fragment>
      What Students{' '}
      <span className="text-animated">
        <span>Learn</span>
      </span>
    </Fragment>
  ),
  firstDesc: (
    <Fragment>
      <span className="d-block mb-20">
        During the research experience, students will develop foundational
        research skills and complete a project to be presented at the Summer
        Research Symposium.
      </span>{' '}
      The research training component will predominantly be a review of
      Introduction to Artificial Intelligence material with a specific lens
      towards preparing students for research in AI. Potential topics may
      include:
    </Fragment>
  ),
  keypoints: [
    <Fragment>
      <span className="text-bold">Foundational Data Science Principles: </span>
      Engage with the core aspects of data science, including data wrangling,
      cleaning, and engineering to prepare data for analysis and modeling.
    </Fragment>,
    <Fragment>
      <span className="text-bold">Comprehensive Modeling Techniques: </span>Gain
      exposure to a wide range of modeling approaches including supervised,
      unsupervised, and reinforcement learning.
    </Fragment>,
    <Fragment>
      <span className="text-bold">Deep Learning Fundamentals: </span>Dive into
      the basics of deep learning, exploring how these powerful models can be
      applied to tasks including computer vision and natural language processing
      tasks.
    </Fragment>,
    <Fragment>
      <span className="text-bold">Advanced Model Optimization: </span>Learn
      about advanced topics in model development, including hyperparameter
      tuning, validation techniques, and strategies for improving model
      performance.
    </Fragment>,
    <Fragment>
      <span className="text-bold">AI Ethics: </span>Understand the importance of
      ethical considerations in AI, focusing on the development of responsible
      and fair AI systems that respect privacy and ensure equity
    </Fragment>,
  ],
}
