import { Fragment } from 'react'

export const stepsToApply = {
  title: (
    <Fragment>
      Steps{' '}
      <span className="text-animated">
        <span>to Apply</span>
      </span>
    </Fragment>
  ),
  steps: [
    'Complete the application and upload the Statement of Interest at the link below.',
    'Complete supplementary materials, as requested.',
    'Applicants will be contacted if they advance to the final selection process.',
  ],
  links: [{ link: '/hsrp-application', text: 'Apply', isExternal: false }],
}
