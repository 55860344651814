import styles from "./styles.module.scss";

export const PartnersCollaborators = () => {
  return (
    <section className={styles.partnersCollaborators}>
      <div className="container-fluid">
        <div className="row">
          <div className="offset-md-2"></div>
          <div className="col-md-6">
            <div
              className={styles.tagline + " mb-20"}
              data-aos="fade-right"
              data-aos-duration="500"
            >
              <h3>Our Partners &amp; Collaborators</h3>
            </div>
            <div
              className={styles.desc}
              data-aos="fade-right"
              data-aos-duration="700"
            >
              <p className="mb-40">
                To ensure the most engaging, high quality AI experience, we are
                proudly partnered with:
              </p>
            </div>
            <div className="row">
              <div className="col-12 col-md-10">
                <ul
                  className={styles.partners + " justify-content-center"}
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  <li>
                    <img
                      src={require("assets/img/train/stanford-cset.png")}
                      alt="Partner"
                    />
                  </li>
                  <li>
                    <img
                      src={require("assets/img/train/john-hopkins.png")}
                      alt="Partner"
                    />
                  </li>
                  <li>
                    <img
                      src={require("assets/img/common/aws-logo.png")}
                      alt="Partner"
                    />
                  </li>
                </ul>
                <ul
                  className={styles.partners}
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  <li>
                    <img
                      src={require("assets/img/train/summer-institute.png")}
                      alt="Partner"
                    />
                  </li>
                  <li>
                    <img
                      src={require("assets/img/train/partner-2.png")}
                      alt="Partner"
                    />
                  </li>
                  <li>
                    <img
                      src={require("assets/img/train/partner-3.png")}
                      alt="Partner"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={styles.tagline + " mb-20"}
              data-aos="fade-right"
              data-aos-duration="500"
            >
              <h3>Our Sponsor</h3>
            </div>
            <div
              className={styles.desc}
              data-aos="fade-up"
              data-aos-duration="`1000"
            >
              <p>
                We are honored that our high school AI project has been supported by a
                three-year grant award from the United States{" "}
                <a
                  className="text-blue-gradient-2 self-underlined position-relative d-inline-block"
                  href="https://dodstem.us/about/partners/?tab=grantees"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Department of Defense
                </a>
                ,{" "}
                <a
                  href="https://www.cto.mil/news/47-million-grants-ndep/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-gradient self-underlined position-relative d-inline-block"
                >
                  National Defense Education Program
                </a>
              </p>
            </div>
            <ul className={styles.sponsor}>
              <li data-aos="fade-right" data-aos-duration="500">
                <img
                  src={require("assets/img/train/sponsors-logo.png")}
                  alt=""
                />
              </li>
            </ul>
          </div>
          <div className="offset-md-1"></div>
          <div className="col-md-3">
            <div className={styles.pic}>
              <img
                src={require("assets/img/train/earth-and-connections.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
