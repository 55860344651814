import { Helmet } from "react-helmet";
import styles from "./styles.module.scss";

export const TrainPdApplication = () => {
  return (
    <div className={styles.trainPdApplication}>
      <Helmet>
        <script src="https://www.cognitoforms.com/f/iframe.js"></script>
      </Helmet>
      <div className="container">
        <div className={styles.iframeContainer}>
          <iframe
            title="Train PD Interest Form"
            src="https://www.cognitoforms.com/f/WJKd0Xqu20um4fv__lKtFA/134"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
