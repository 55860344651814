import classNames from 'classnames'
import styles from './styles.module.scss'

export const Loader = () => {
  return (
    <div className={classNames(styles.loader, styles.loader3)}>
      <div>
        <div>
          <div>
            <div>
              <div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
