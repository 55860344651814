import styles from "./styles.module.scss";

export const CourseComponents = (props) => {
  const { data } = props;

  return (
    <section className={styles.courseComponentsSection}>
      <div className="container">
        <h5 className={styles.sectionTitle}>{data?.sectionTitle}</h5>
        <p className={styles.sectionDescription}>{data?.sectionDescription}</p>
        <ul className={styles.courseComponents}>
          {data?.courseComponents?.map((item, idx) => {
            return (
              <li key={idx}>
                <img src={item.img} alt={item.title} />
                <span>{item.title}</span>
              </li>
            );
          })}
        </ul>
        <p className={styles.desc}>{data?.courseText}</p>
      </div>
    </section>
  );
};
