import classNames from "classnames";
import styles from "./styles.module.scss";

export const CarouselPrev = (props) => {
  const { className, iconClassName, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <span className={classNames(styles.arrow, iconClassName)}></span>
    </div>
  );
};
