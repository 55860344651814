import styles from "./styles.module.scss";

export const EQCIApplication = () => {
  return (
    <div className={styles.trainAwsPdApplication}>
      <div className="container">
        <div className={styles.iframeContainer}>
          <iframe
            id="JotFormIFrame-240506552349153"
            title="The Coding School's Early Quantum Career Immersion Program"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allowfullscreen="true"
            allow="geolocation; microphone; camera"
            src="https://form.jotform.com/240506552349153"
          >
          </iframe>
        </div>
      </div>
    </div>
  );
};
