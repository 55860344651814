import classNames from "classnames";
import styles from "./styles.module.scss";

export const HowItWorks = (props) => {
  const { data } = props;
  return (
    <div className={styles.howItWorks}>
      <p className={classNames(styles.keyDetailsDesc, "text-center")}>
        {data.headline}
      </p>
      <ul className={classNames(styles.keyDetails, "row")}>
        {data.keyPoints.map((item, idx) => {
          return (
            <li className="col-12 col-md-6 col-lg-4 mb-15" key={idx}>
              <span className={styles.kdWrap}>
                <i className={classNames(styles.icon, item.icon)}></i>
                <span>{item.point}</span>
              </span>
            </li>
          );
        })}
      </ul>
      <p className={styles.keyDetailsDesc}>{data.description}</p>

      {data.highlightedPoint.map((item, idx) => {
        return (
          <p className={styles.featuresDesc} key={idx}>
            <i className={classNames(styles.icon, "fa-solid fa-brain")}></i>
            <span>{item}</span>
          </p>
        );
      })}
    </div>
  );
};
