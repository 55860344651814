import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export const VideoSection = () => {
  return (
    <div className={styles.videoSection}>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12 col-md-6">
                 <p>The Coding School is on a mission to empower 1 million students by 2030, working to make STEM education and careers more accessible and the future STEM workforce more inclusive. Check out this video to learn more about our work and consider making a gift to support our mission.</p>
                 <Link className='btn btn-gradient-2 rounded-pill text-white px-30 py-15 fs-18' to={'/donate'}>Donate</Link>
                </div>
                <div className="col-12 col-md-6 mt-20 mt-md-0">
                    <video className={styles.video} autoPlay={false} controls controlsList="nodownload">
                      <source src="https://blog.the-cs.org/tcs-assets/tcs-video.mp4" type="video/mp4"></source>
                    </video>
                </div>
            </div>
        </div>
    </div>
  )
}
