import { Data, Research, Scholar, University } from "component/Common/Icons";

export const offerings = [
  {
    svg: <Data />,
    title: "Learn data science - one of the fastest growing fields of CS",
  },
  {
    svg: <Research />,
    title: "Participate in research projects utilizing data analysis",
  },
  {
    svg: <Scholar />,
    title: "Receive mentorship from university faculty and PhDs",
  },
  {
    svg: <University />,
    title: "Increase college competitiveness",
  },
];
