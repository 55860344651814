import unc from "assets/img/common/unc-chapel-hill.png";
import columbiaUni from "assets/img/common/columbia-university.jpg";

export const createdBy = {
  tagline: "Research Programs created by professors and researchers at:",
  logos: [
    { src: unc, alt: "UNC Chapel Hill" },
    { src: columbiaUni, alt: "columbia University" },
  ],
};
