import { CircularChart } from "component/Common/Icons";
import styles from "./styles.module.scss";

export const ProgramCompletion = (props) => {
  const { data } = props;
  return (
    <section className={styles.programCompletion}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6">
            <h3 className={styles.title}>{data?.title}</h3>
            <ul className={styles.keypoints}>
              {data?.keypoints?.map((item, idx) => (
                <li key={idx}>
                  <CircularChart
                    completion={item?.completion}
                    outlineColor={item?.outlineColor}
                    fillColor={item?.fillColor}
                    className={styles.icon}
                  />
                  <span className={styles.content}>{item?.point}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-12 col-lg-6 d-none d-lg-block">
            <div className="img-highlighted right">
              <img
                className="img-fluid w-100"
                src={data?.img?.src}
                alt={data?.img?.alt}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
