import { useState, useEffect } from 'react';
import classNames from "classnames";
import { ArrowDown } from "component/Icons";

import { aboutSponsors } from "data";

import styles from "./styles.module.scss";

export const AboutSponsors = () => {
  const [expanded, setExpanded] = useState(false);
  const [logos, setlogos] = useState([]);
  const [remainingLogos, setRemaininglogos] = useState([]);
  const splitLogos = () => {
    if (window.innerWidth > 575 && window.innerWidth < 992) {
      setlogos(aboutSponsors.slice(0, 8));
      setRemaininglogos(aboutSponsors.slice(8));
    }
    else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
      setlogos(aboutSponsors.slice(0, 10));
      setRemaininglogos(aboutSponsors.slice(10));
    }
    else if (window.innerWidth >= 1200 && window.innerWidth < 1400) {
      setlogos(aboutSponsors.slice(0, 12));
      setRemaininglogos(aboutSponsors.slice(12));
    }
    else if (window.innerWidth >= 1400) {
      setlogos(aboutSponsors.slice(0, 16));
      setRemaininglogos(aboutSponsors.slice(16));
    }
    else {
      setlogos(aboutSponsors.slice(0, 6));
      setRemaininglogos(aboutSponsors.slice(6));
    }
  }
  useEffect(() => {
    splitLogos();
  }, [])

  return (
    <div className={styles.ourPartnersBlock + " mt-80 position-relative"}>
      <div className="container mb-50">
        <div
          className={classNames(styles.textDetail, "shadowedBox")}
          id="partners"
        >
          <h2 className={classNames(styles.subTitle, 'mb-20')}>Our Sponsors and Partners</h2>
          <p className={styles.aboutUsSmallHeading}>
            It takes a village to develop 21st century education in emerging
            technologies. We are grateful for the support from our sponsors and
            partners who believe in our mission and help us ensure the future
            tech-ready workforce is{" "}
            <span className="highlight-text text-bold">
              diverse, inclusive, and equitable.
            </span>
          </p>
        </div>
      </div>
      <div className="container-fluid">
        <div className={styles.sponsorLogosWrap}>
          <div className={styles.sponsorLogos}>
            {logos.map((val, idx) => {
              let { sponsor, logoClass, alt } = val;
              return (
                <img
                  key={idx}
                  className={classNames(styles.avatar, 'img-fluid', logoClass)}
                  src={
                    sponsor
                      ? sponsor
                      : "https://via.placeholder.com/80"
                  }
                  alt={alt}
                />
              );
            })}
          </div>
          <div className={classNames(styles.sponsorLogos, styles.remaining, { [styles.visible]: expanded })}>
            {remainingLogos.map((val, idx) => {
              let { sponsor, logoClass, alt } = val;
              return (
                <img
                  key={idx}
                  className={classNames(styles.avatar, 'img-fluid', logoClass)}
                  src={
                    sponsor
                      ? sponsor
                      : "https://via.placeholder.com/80"
                  }
                  alt={alt}
                />
              );
            })}
          </div>
          <button className={classNames(styles.collapseBtn, 'btn btn-gradient-2')} onClick={() => setExpanded(!expanded)}>
            And many more <span className={classNames(styles.arrow, { [styles.expanded]: expanded })}><ArrowDown /></span>
          </button>
        </div>
      </div>
    </div>
  );
};
