import classNames from "classnames";

import { featuredPost } from "data";
import { Carousel } from "../Carousel";
import { CarouselNext } from "../CarouselNext";
import { CarouselPrev } from "../CarouselPrev";

import styles from "./styles.module.scss";

export const News = () => {
  var settings = {
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    nextArrow: <CarouselNext />,
    prevArrow: <CarouselPrev />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className={styles.news}>
      <div className="container-fluid">
        <div className={styles.featuredPost}>
          <Carousel settings={settings}>
            {featuredPost.map((item, index) => {
              return (
                <div
                  className={classNames(
                    styles.featuredPostItem,
                    "shadowed-box"
                  )}
                  key={index}
                >
                  <div className={styles.img}>
                    <img src={item["img"]} alt="Featured" />
                  </div>
                  <div className={styles.desc}>
                    <h4>{item["title"]}</h4>
                    <p>{item["description"]}</p>
                    <a
                      className={classNames(
                        styles.link,
                        "btn btn-gradient-2 text-white"
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item["link"]}
                    >
                      Read More
                    </a>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </section>
  );
};
