import { Fragment } from "react";
import wasc from "assets/img/common/wasc.png";
import ucag from "assets/img/common/uc-ag-guide.png";

export const accredited = {
  mainTitle: (
    <Fragment>
      Our Courses Are{" "}
      <span className="text-animated">
        <span>Accredited</span>
      </span>
    </Fragment>
  ),
  title: "Earn high school credit while taking our emerging tech courses!",
  desc: "Our courses are accredited by the Western Association of Schools and Colleges (WASC) and approved by the University of California A-G as a college preparatory courses. Students from more than 300 high schools in the U.S. received credit last year for completing the course and you can too with your school's approval.",
  img: [
    { src: wasc, alt: "WASC" },
    { src: ucag, alt: "UCAG" },
  ],
};
