import { Fragment } from "react";

import WASC from "assets/img/common/wasc.png";
import AG from "assets/img/common/uc-ag-guide.png";

export const forSchools = {
  schoolText: (
    <Fragment>
      <p>
        We've partnered with over 300 departments of education, schools &amp;
        school districts to offer our courses for high school credit. ALL
        courses are FREE to school partners and school partner students.
      </p>
      <p>
        Interested in learning how you can offer this course to your students
        for credit?
      </p>
    </Fragment>
  ),
  buttonLink: "https://airtable.com/appAJLurroGXXWDB3/shrKuRrP6Kv1oNnNe",
  logos: [
    { src: WASC, alt: "WASC" },
    { src: AG, alt: "University Of California A-G Guide" },
  ],
};
