import { Fragment } from 'react'

export const faqs = [
  {
    qu: 'Who Should Apply?',
    an: (
      <Fragment>
        <ul>
          <li>
            Young professionals who have been in the workforce for 12 years or
            less
          </li>
          <li>
            Individuals passionate about advancing STEM education and who want
            to contribute to TCS’s mission
          </li>
          <li>
            Leaders who are ready to take on a role in one of three key
            committees:
            <ol>
              <li>Recruitment & Oversight</li>
              <li>Business Development & Partnerships</li>
              <li>Event Planning & Execution</li>
            </ol>
          </li>
          <li>
            Professionals from diverse sectors like financial services,
            consulting, education, technology, and media
          </li>
        </ul>
      </Fragment>
    ),
  },
  {
    qu: 'What Will a Young Professional Get Out of Joining the YPAB?',
    an: (
      <Fragment>
        <ul>
          <li>
            The opportunity to lead and collaborate on strategic initiatives
            within TCS
          </li>
          <li>
            A chance to work closely with TCS&apos;s leadership and board
            members to make a direct impact on the future of STEM education
          </li>
          <li>
            Networking opportunities with professionals across various
            industries, including access to TCS&apos;s Board of Directors and
            students
          </li>
          <li>
            Monthly updates on TCS&apos;s organizational activities and
            involvement in shaping emerging tech education trends
          </li>
          <li>
            Hands-on experience in thought-leadership, fundraising, business
            development, recruitment, and event planning, including organizing
            TCS&apos;s annual gala
          </li>
        </ul>
      </Fragment>
    ),
  },
  {
    qu: 'What are TCS’s Expectations for YPAB Members?',
    an: (
      <Fragment>
        <ul>
          <li>
            Serve as a leader on one of the three committees (Recruitment &
            Oversight, Business Development & Partnerships, or Event Planning &
            Execution)
          </li>
          <li>
            Attend at least 75% of board meetings, which occur bi-monthly, and
            actively participate in events and committee responsibilities
          </li>
          <li>
            Support fundraising activities, including promoting events and
            assisting with gala ticket sales
          </li>
          <li>
            Help recruit new board members and foster business partnerships that
            further TCS&apos;s mission
          </li>
          <li>
            Act as an advocate for TCS&apos;s work and positively represent the
            organization within your professional and personal network
          </li>
        </ul>
      </Fragment>
    ),
  },
]
