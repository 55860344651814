import diverseBg from "assets/img/home/diverse-bg.png";

import styles from "./styles.module.scss";

export const StemWorkforce = () => {
  return (
    <div
      className={styles.stemWorkforce}
      data-aos="fade-in"
      data-aos-duration="1000"
    >
      <div className={styles.overlayImg}>
        <img src={diverseBg} alt="Stem Workforce Background" />
      </div>
      <div className={styles.overlay}></div>
      <div className={styles.keyInitiativesContent + " container"}>
        <div className="row">
          <div className="col-12">
            <div className={styles.visualText + " text-center"}>
              <h4 className="m-auto">
                The future STEM workforce is
                <span className="highlight-text"> diverse.</span>
              </h4>
              <h4 className="w-80 m-auto text-italic mb-30">
                We're making sure of it.
              </h4>
              <h4 className="m-auto">
                <span className="highlight-text">55%</span> of our students are
                from underrepresented backgrounds in STEM.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
