// import evaManolis from "assets/img/about/board-of-advisors/eva-manolis.jpg";
import pamitSurana from 'assets/img/about/board-of-advisors/pamit-surana.jpg'
import raviPendse from 'assets/img/about/board-of-advisors/ravi-pendse.jpg'
import scottAaronson from 'assets/img/about/board-of-advisors/scott-aaronson.png'
import clariceAiello from 'assets/img/about/board-of-advisors/clarice-aiello.jpg'
import jamesWhitfield from 'assets/img/about/board-of-advisors/james-d-whitfield.jpg'
import rayLaflamme from 'assets/img/about/board-of-advisors/ray-laflamme.jpg'
import spirosMichalakis from 'assets/img/about/board-of-advisors/spiros-michalakis.jpg'
import umeshVazirani from 'assets/img/about/board-of-advisors/umesh-vazirani.jpg'
import willOliver from 'assets/img/about/board-of-advisors/will-oliver.jpg'
import jamesFitch from 'assets/img/about/board-of-advisors/james-fitch.jpg'
import davidTouretzky from 'assets/img/about/board-of-advisors/david-s-touretzky.jpg'
import nathanielBastian from 'assets/img/about/board-of-advisors/nathaniel-d-bastian.jpg'
import andrewOliver from 'assets/img/about/board-of-advisors/andrew-oliver.jpg'
import benIsecke from 'assets/img/about/board-of-advisors/ben-isecke.jpg'
import tinaBrower from 'assets/img/about/board-of-advisors/tina-brower-thomas.jpg'
import yvetteMedina from 'assets/img/about/board-of-advisors/yvette-medina.jpeg'
import laurenBecker from 'assets/img/about/young-advisory-board/lauren-becker.jpg'
import gabriela from 'assets/img/about/board-of-advisors/gabriela-guimaraes-weldon.jpg'
import jerryLiu from 'assets/img/about/board-of-advisors/jerry-liu.jpg'
import mikeRosen from 'assets/img/about/board-of-advisors/mike-rosen.jpg'

import manuelaVeloso from 'assets/img/about/board-of-advisors/manuela-veloso.jpg'
import halAbelson from 'assets/img/about/board-of-advisors/hal-abelson.jpg'
import jeremyHoward from 'assets/img/about/board-of-advisors/jeremy-howard.jpg'

import placeholder from 'assets/img/about/board-of-advisors/placeholder.jpg'

export const boardOfAdvisors = [
  {
    avatar: pamitSurana,
    name: 'Pamit Surana',
    designation: 'Chief Revenue Officer & CAO, Gigapower',
  },
  {
    avatar: raviPendse,
    name: 'Ravi Pendse',
    designation: 'CIO and VP of Information Tech at University of Michigan',
  },
  {
    avatar: scottAaronson,
    name: 'Scott Aaronson',
    designation: "Director, UT Austin's Quantum Information Center",
  },
  {
    avatar: clariceAiello,
    name: 'Clarice Aiello',
    designation: 'Assistant Professor of ECE, UCLA QuBiT Lab',
  },
  {
    avatar: jamesWhitfield,
    name: 'James D. Whitfield',
    designation: 'Assistant Professor of Physics, Dartmouth University',
  },
  {
    avatar: rayLaflamme,
    name: 'Ray Laflamme',
    designation:
      'Director, University of Waterloo Institute for Quantum Computing',
  },
  {
    avatar: spirosMichalakis,
    name: 'Spiros Michalakis',
    designation:
      "Physicist, CalTech's Institute for Quantum Information and Matter",
  },
  {
    avatar: umeshVazirani,
    name: 'Umesh Vazirani',
    designation: "Director, UC Berkeley's Quantum Computation Center",
  },
  {
    avatar: willOliver,
    name: 'Will Oliver',
    designation: "Director, MIT's Center for Quantum Engineering",
  },
  {
    avatar: tinaBrower,
    name: 'Tina Louise Brower-Thomas',
    designation: 'Executive Director Center Integrated Quantum Materials',
  },
  {
    avatar: jamesFitch,
    name: 'Chief Master Sgt. James Fitch II',
    designation: 'Command Chief, Air Force Research Laboratory',
  },

  {
    avatar: davidTouretzky,
    name: 'Dr. Dave Touretzky',
    designation:
      'Research Professor at Carnegie Mellon University and Chair of AI4K12',
  },
  {
    avatar: nathanielBastian,
    name: 'Dr. Nathaniel Bastian',
    designation:
      'Assistant Professor of Operations Research & Data Science at West Point',
  },
  {
    avatar: andrewOliver,
    name: 'Andrew Oliver',
    designation: 'Cloud and AI SWE at Microsoft',
  },
  {
    avatar: yvetteMedina,
    name: 'Yvette Medina',
    designation: 'Director, AI Bootcamps Program, Mark Cuban Foundation',
  },
  {
    avatar: benIsecke,
    name: 'Ben Isecke',
    designation: 'President of NJ CSTA & CS Teacher at Bergen County Academies',
  },
  {
    avatar: laurenBecker,
    name: 'Lauren Becker',
    designation: 'Software Engineer, Walt Disney Company',
  },
  {
    avatar: gabriela,
    name: 'Gabriela Guimaraes Weldon',
    designation: 'J.D. Yale Law School, Arnold & Porter Law Firm',
  },
  {
    avatar: jerryLiu,
    name: 'Jerry Liu',
    designation: 'Engineer, Openstore',
  },
  {
    avatar: placeholder,
    name: 'Michael Rosen',
    designation: 'President, MegaTrend Management',
  },
  // {
  //   avatar: evaManolis,
  //   name: "Eva Manolis",
  //   designation: "Co Founder of ShutterFly",
  // },
  // {
  //   avatar: manuelaVeloso,
  //   name: "Dr. Manuela Veloso",
  //   designation: "Head of AI Research at J.P. Morgan & President AAAI",
  // },
  // {
  //   avatar: halAbelson,
  //   name: "Dr. Hal Abelson",
  //   designation: "Computer Science Professor at MIT",
  // },
  // {
  //   avatar: jeremyHoward,
  //   name: "Jeremy Howard",
  //   designation: "Co-Founder at fast.ai",
  // },
]
