import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const NotFound = () => {
  return (
    <div className={styles.NotFound}>
      <div className="container">
        <h1 className="text-blue-gradient">Oops! You seem to be lost.</h1>
        <p>Here are some helpful links:</p>
        <ul className="list-unstyled list-inline">
          <li className="list-inline-item">
            <Link
              className={classNames(styles.button, "text-blue-gradient")}
              to="/"
            >
              Home
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              className={classNames(styles.button, "text-blue-gradient-2")}
              to="/contact"
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
