import bgImage from "assets/img/about/advisory-board.jpg";

import { youngProfessionals } from "data";

import styles from "./styles.module.scss";

export const YoungProfessionals = () => {
  return (
    <div className={styles.professionalBlock}>
      <div className="container">
        <div
          className={styles.background}
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        >
          <h4 className={styles.yabText}>Young Professional Advisory Board</h4>
        </div>

        <div
          className={
            styles.youngProfessionalsBlock + " row justify-content-center"
          }
        >
          {youngProfessionals.map((val, index) => {
            return (
              <div
                className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
                key={index}
              >
                <div className={styles.professionals}>
                  <img
                    className={styles.avatar}
                    src={val.avatar}
                    alt={val.name}
                  />
                  <div className={styles.meta}>
                    <p className={styles.name}>{val.name}</p>
                    <img
                      src={val.organization}
                      className={styles.organization}
                      alt={val.organizationName}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
