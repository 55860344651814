import styles from "./styles.module.scss";

export const BecomeFutureLeader = (props) => {
  const { data } = props;
  return (
    <div className={styles.futureLeader}>
      <div className="container-fluid">
        <p>{data}</p>
      </div>
    </div>
  );
};
