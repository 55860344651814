import { Link } from 'react-router-dom'
import classNames from 'classnames'
import styles from './styles.module.scss'

export const StepsToApply = props => {
  const { data } = props
  return (
    <section className={styles.stepsToApply}>
      <div className="container">
        <h4 className={styles.title}>{data?.title}</h4>
        <div
          className={classNames(
            styles.steps,
            'row mb-20 justify-content-center'
          )}
        >
          {data?.steps?.map((item, idx) => (
            <div className="col-12 col-md-6" key={idx}>
              <div className={styles.wrap}>
                <span className={styles.count}>{idx + 1}</span>
                <p className={styles.content}>{item}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center">
          {data.links.map((button, idx) =>
            button.isExternal ? (
              <a
                href={button.link}
                key={idx}
                target="_blank"
                rel="noreferrer"
                className="btn btn-gradient-2 text-white px-30 btn-lg"
              >
                {button.text}
              </a>
            ) : (
              <Link
                to={button.link}
                key={idx}
                className="btn btn-gradient-2 text-white px-30 btn-lg"
              >
                {button.text}
              </Link>
            )
          )}
        </div>
      </div>
    </section>
  )
}
