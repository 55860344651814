import { Link } from "react-router-dom";
import { useCallback } from "react";
import classNames from "classnames";

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import styles from "./styles.module.scss";

export const Banner = (props) => {
  const { data, isCourses, bannerClassName } = props;
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className={classNames(styles.banner, bannerClassName)}
      style={{ backgroundImage: "url(" + data?.bannerBg + ")" }}
    >
      {isCourses ? (
        <div className={styles.particlesArea}>
          <Particles
            id="tsparticles"
            init={particlesInit}
            options={{
              fullScreen: {
                enable: false,
                zIndex: 0,
              },
              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  push: {
                    quantity: 2,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#38b6ff",
                },
                links: {
                  color: "#38b6ff",
                  distance: 200,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                collisions: {
                  enable: true,
                },
                move: {
                  directions: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 0.4,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 1920,
                  },
                  value: 150,
                },
                opacity: {
                  value: 0.2,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 3 },
                },
              },
              detectRetina: true,
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="container">
        <div
          className={classNames(styles.content, {
            [styles.coursesContent]: isCourses,
          })}
        >
          <h1 className={isCourses ? "text-brand" : "text-blue-gradient"}>
            {data?.heading}
          </h1>
          <h3 className={isCourses ? "bg-blue-gradient" : "text-blue-gradient"}>
            {data?.programs}
          </h3>
          {isCourses ? (
            <div className={styles.coursesBy}>
              <p className={isCourses ? "text-brand" : "text-blue-gradient"}>
                {data?.courseCreatedBy?.para}
              </p>
              <ul className="list-unstyled">
                {data?.courseCreatedBy?.logos.map((item, idx) => (
                  <li key={idx}>
                    <img
                      className="img-fluid"
                      src={item?.src}
                      alt={item?.alt}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <></>
          )}
          <h4 className={isCourses ? "text-brand" : "text-blue-gradient"}>
            {data?.programsTypes}
          </h4>
          <h5
            className={classNames(
              { [styles.programsTypesStyle]: isCourses },
              isCourses ? "text-brand" : "text-blue-gradient"
            )}
          >
            {data?.programsFor}
          </h5>
        </div>
      </div>
      <div className="container-fluid">
        <ul
          className={classNames(
            styles.bannerButtons,
            isCourses ? "pb-50" : "pb-30",
            "list-unstyled mb-0"
          )}
        >
          {data?.bannerLinks?.map((item, idx) => {
            return item?.isScrollable ?
              <li key={idx}>
                <Link
                  className={styles.btnScroll}
                  onClick={() => handleClickScroll(item?.link)}
                >
                  <span>{item?.linkText}</span>
                </Link>
              </li>
              : (
                item?.isExternal ? (
                  <li key={idx}>
                    <a href={item?.link} target="_blank" rel="noreferrer">
                      {item?.linkText}
                    </a>
                  </li>
                ) : (
                  <li key={idx}>
                    <Link to={item?.link}>{item?.linkText}</Link>
                  </li>
                )
              )
          })}
        </ul>
      </div>
    </div>
  );
};
