import { Fragment } from "react";

export const aiIntro = {
  paragraph:
    "AI will be used to solve some of the 21st century's most pressing issues. We want to make sure every high school student is prepared. Introduction to Artificial Intelligence (AI) is a two-semester course designed for high school students to gain cutting-edge knowledge and skills in AI with a focus on Machine Learning (ML). Offered virtually with live instruction by a Harvard researcher, the course is available to U.S. based high school students and educators. Throughout the course, students will work on real-world AI projects, culminating in a capstone project; hear from leading experts in AI from industry and academia; and join a national cohort of future AI leaders.",
  aiList: [
    "Learn real-world AI skills",
    // "FREE for all accepted students",
    "Work with national military labs",
    "Create a capstone project",
    "Meet industry experts",
    "Become a Responsible AI Leader",
    "No prerequisites",
  ],
  tagLine: (
    <Fragment>
      Be a part of the course created to train the nation's{" "}
      <span className="text-black">future Responsible AI leaders!</span>
    </Fragment>
  ),
};
