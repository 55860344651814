import { Fragment } from "react";

export const howToApply = {
  title: (
    <Fragment>
      How To{" "}
      <span className="text-animated">
        <span>Apply</span>
      </span>
    </Fragment>
  ),
  descriptions: [
    "Due to the overwhelming interest in this research program and rigor of the material, students are required to submit a Statement of Interest of 300-500 words that highlights their interest in learning data science and conducting research, future university and career aspirations, and any other relevant information.",
    "We are looking for students with passion, interest and curiosity to learn more about these fields.",
  ],
};
