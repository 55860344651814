import { Fragment } from "react";

export const banner = {
  bannerTitle: "AI will change",
  rotatingWords: [
    "Healthcare",
    "Finance",
    "Policymaking",
    "Conservation",
    "Defense",
    "Education",
    "Aerospace",
    "Entertainment",
  ],
  bannerSubTitle: "BE A PART OF THE AI REVOLUTION.",
  additionalData: [<Fragment><h3 style={{ fontSize: '44px' }} className="font-seg-ui-bold my-30">Introduction to Artificial Intelligence <br /> <small>for High School Students</small></h3> </Fragment >, <Fragment><h3 className="font-seg-ui-reg">September 2025 - April 2026</h3></Fragment>],
  button: {
    title: 'Interest Form',
    isExternal: true,
    link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
  },
};
