export const banner = {
  bannerTitle: "AI will change",
  rotatingWords: [
    "Healthcare",
    "Finance",
    "Policymaking",
    "Conservation",
    "Defense",
    "Education",
    "Aerospace",
    "Entertainment",
  ],
  bannerSubTitle: "Prepare your students to be responsible ai leaders",
  secondSubTitle: ["Virtual", "June 26 - Jul 21, 2023"],
  button: {
    isExternal: true,
    title: "Apply",
    link: "https://airtable.com/shrJhjWH4bfT3aJUm",
  },
};
