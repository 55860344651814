import { Helmet } from 'react-helmet'
import styles from './styles.module.scss'

export const TrainCourseApplication = () => {
  return (
    <div className={styles.trainCourseApplication}>
      <div className="container">
        <div className={styles.iframeContainer}>
          <iframe
            id="JotFormIFrame-240644366222048"
            title="2024-2025 Yearlong Course Application"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allow="geolocation; microphone; camera; fullscreen"
            src="https://form.jotform.com/240644366222048"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
  )
}
