import {
  banner,
  aiIntro,
  courseOverview,
  whoShouldJoin,
  coursesPartner,
  forSchools,
  faqs,
  leadInstructors,
} from "data/trainCourse";

import { TrainCourseBanner } from "component/TrainCourse/TrainCourseBanner";
import { AiIntro } from "component/TrainCourse/AiIntro";
import { CoursesBy } from "component/TrainCourse/CoursesBy";
import { CourseOverview } from "component/TrainCourse/CourseOverview";
import { WhoShouldJoin } from "component/TrainCourse/WhoShouldJoin";
import { LeadInstructors } from "component/TrainCourse/LeadInstructors";
import { ForSchools } from "component/TrainCourse/ForSchools";
import { Faq } from "component/TrainCourse/Faq";

export const TrainCourse = () => {
  return (
    <div className="train-course-page">
      <TrainCourseBanner
        bannerTitle={banner.bannerTitle}
        rotatingWords={banner.rotatingWords}
        bannerSubTitle={banner.bannerSubTitle}
        additionalData={banner.additionalData}
        button={banner.button}
      />
      <AiIntro
        paragraph={aiIntro.paragraph}
        aiList={aiIntro.aiList}
        tagLine={aiIntro.tagLine}
        paraTwo={aiIntro.paraTwo}
      />
      <CoursesBy title={coursesPartner.title} logos={coursesPartner.logos} />
      <CourseOverview data={courseOverview} />
      <WhoShouldJoin words={whoShouldJoin.words} text={whoShouldJoin.text} />
      <LeadInstructors isMultiple={true} data={leadInstructors} headingClassName="mb-40" />
      <ForSchools
        schoolText={forSchools.schoolText}
        buttonLink={forSchools.buttonLink}
        logos={forSchools.logos}
      />
      <Faq faqs={faqs} />
    </div>
  );
};
