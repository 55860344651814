export const programDetails = {
  title: 'This six-week program consists of two main components:',
  card: [
    {
      cardTitle: 'Weeks 1-2:',
      cardDesc: [
        'Students participate in a virtual research training program with TCS Faculty, reviewing material from Introduction to Artificial Intelligence, while also learning useful vocabulary and professional skills to prepare to conduct research with their research hosts.',
      ],
    },
    {
      cardTitle: 'Weeks 3-6:',
      cardDesc: [
        'Students put their machine learning skills into action while assisting researchers with research projects that are overseen by university mentors.',
      ],
    },
  ],
  disclaimer:
    'Upon completion of the program, students are presented with a certificate and will have a research project to showcase on college apps.',
}
