import styles from "./styles.module.scss";

export const TrainBannerBelow = () => {
  return (
    <div className={styles.trainBannerBelow}>
      <div className="container">
        <div className="row mt-70">
          <div className="col-12 col-md-11 mx-auto">
            <div className="shadowedBox">
              <div
                className={styles.tagline}
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <div className="d-flex flex-wrap justify-content-center">
                  <div className="bg-blue-gradient p-15 rounded">
                    <h6 className="mb-0 text-bold">
                      “[Artificial Intelligence] will be the most powerful tool
                      in generations for benefiting humanity.”
                    </h6>
                    <h6 className="my-0">
                      -U.S. National Security Commission on Artificial
                      Intelligence
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className={styles.desc}
                data-aos="fade-up"
                data-aos-duration="600"
              >
                <p className="text-dark">
                  Artificial Intelligence (AI) will change the world as we know
                  it, shaping how business is done, how people connect, and how
                  society functions. It will affect every industry and sector,
                  making it critical that all members of the future workforce
                  have an understanding of AI and its implications. So whether
                  students plan on pursuing careers in politics, business, or
                  healthcare, they all need to learn about AI.{" "}
                  <span className="text-blue-gradient text-bold">
                    That&#8217;s why we&#8217;re providing in-depth,
                    real-world AI skills and knowledge to thousands of high
                    school students and educators plus college faculty throughout the World.
                  </span>
                </p>
              </div>

              <div className={styles.more} data-aos="zoom-in">
                <a href="#introduction">
                  <i className={styles.angleDown}></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
