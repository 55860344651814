import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import classNames from "classnames";
import { aboutPartners } from "data";

import styles from "./styles.module.scss";

export const AboutPartners = () => {
  return (
    <div className={styles.quantumBlock}>
      <div className="container">
        <div className="mt-80 position-relative" id="school-partners">
          <div className={classNames(styles.textDetail, "shadowedBox")}>
            <h2 className={classNames(styles.subTitle, 'mb-20')}>School Partners</h2>
            <p className={styles.aboutUsSmallHeading}>
              As a nationally accredited organization, we partner with K-12
              schools around the U.S. and globally to offer our courses for high
              school credit. We are partnered with 300+ school districts and
              schools who are some of the first schools globally to offer
              courses in emerging technologies, like AI or Quantum Computing.
              Interested in your school joining our network?{" "}
              <Link className="text-gold text-underline" to="/school-partner-info">
                Click here to connect with us.
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-50">
        <Marquee direction="left" speed="50">
          <div className="d-flex mt-50 mb-50 justify-content-center">
            {aboutPartners.map((val, index) => {
              return (
                <div className={styles.PartnerCard} key={index}>
                  <img
                    className={styles.avatar}
                    src={
                      val.partnerLogo
                        ? val.partnerLogo
                        : "https://via.placeholder.com/80"
                    }
                    alt={val.alt}
                  />
                </div>
              );
            })}
          </div>
        </Marquee>
      </div>
    </div>
  );
};
