import { Fragment } from 'react'

export const howToApply = {
  title: (
    <Fragment>
      How To{' '}
      <span className="text-animated">
        <span>Apply</span>
      </span>
    </Fragment>
  ),
  descriptions: [
    'Due to the overwhelming interest in this Research Program, students are required to submit a Statement of Interest of 300-500 words that highlights their interest in continuing to explore AI and conducting research, future university and career aspirations, and any other relevant information.',
    'We are looking for students with passion, interest and curiosity to learn more about these fields. In-person sites may be restricted to students in specific geographic areas, though virtual opportunities are available.',
  ],
}
