import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import classNames from 'classnames'

import Slider from 'react-slick'
import { CarouselPrev } from 'component/Common/CarouselPrev'
import { CarouselNext } from 'component/Common/CarouselNext'

import { programPost, programPostUniversity, programPostEducators } from 'data'

import styles from './styles.module.scss'

export const UpcomingPrograms = () => {
  const upcomingPrograms = [
    {
      University: programPostUniversity,
    },
    {
      'k-12': programPost,
    },
    {
      'educators-workforce': programPostEducators,
    },
  ]

  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CarouselNext className={styles.next} />,
    prevArrow: <CarouselPrev className={styles.prev} />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.upcomingPrograms}>
      <div
        className={styles.brownSec + ' mb-70'}
        id="upcomingPrograms"
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <div className="container">
          <h2>Upcoming Programs</h2>
        </div>
      </div>

      <div className="container" data-aos="fade-up" data-aos-duration="500">
        <div
          className={classNames(styles.upcomingProgramsTab, 'nav mb-30')}
          role="tablist"
        >
          {upcomingPrograms?.map((item, idx) => (
            <a
              id={`${Object.keys(item)}-tab`}
              data-toggle="tab"
              href={`#${Object.keys(item)}`}
              role="tab"
              aria-controls={`${Object.keys(item)}`}
              aria-selected="false"
              className={classNames(
                styles.tabTitle,
                idx === 1 ? 'active' : null
              )}
              key={idx}
            >
              <h6 className={styles.titleContent}>
                {idx === 2 ? 'Educators & Workforce' : Object.keys(item)}
              </h6>
            </a>
          ))}
        </div>
      </div>

      <div className="tab-content mb-50" id="upcomingProgramsTabContent">
        {upcomingPrograms?.map((item, idx) => (
          <div
            className={classNames(
              'tab-pane fade',
              idx === 1 ? 'show active' : null
            )}
            id={`${Object.keys(item)}`}
            role="tabpanel"
            aria-labelledby={`${Object.keys(item)}-tab`}
            key={idx}
          >
            <div className={styles.programPost}>
              <Slider
                {...settings}
                className={classNames(styles.programPostCarousel, styles.centeredCarousel, {
                  // [styles.centeredCarousel]: idx !== 1,
                })}
              >
                {Object.values(item).map(programs =>
                  programs.map((program, idx) => (
                    <div
                      className={styles.programPostItem}
                      key={`${idx}-${program.title}`}
                      data-aos="fade-down"
                      data-aos-duration={1000 * (idx + 1)}
                    >
                      <div className={styles.programPostImg}>
                        <img src={program.img} className="img-fluid" alt="" />
                      </div>
                      <div className={styles.programPostDesc}>
                        <h4>{program.title}</h4>
                        <h5>{program.subTitle}</h5>
                        <ul>
                          {program.list.map((val, i) => {
                            return <li key={i}>{val.listItem}</li>
                          })}
                        </ul>
                        <div className={styles.programsButtons}>
                          {program?.links?.map((item, idx) =>
                            item?.isHashLink ? (
                              <HashLink
                                key={idx}
                                to={item.link}
                                className={classNames(
                                  styles.button,
                                  'btn btn-gradient-2 text-white rounded-0'
                                )}
                              >
                                {item.text}
                              </HashLink>
                            ) : item.isExternal ? (
                              <a
                                key={idx}
                                href={item.link}
                                target="_blank"
                                rel="noreferrer"
                                className={classNames(
                                  styles.button,
                                  'btn btn-gradient-2 text-white rounded-0'
                                )}
                              >
                                {item.text}
                              </a>
                            ) : (
                              <Link
                                key={idx}
                                to={item.link}
                                className={classNames(
                                  styles.button,
                                  'btn btn-gradient-2 text-white rounded-0'
                                )}
                              >
                                {item.text}
                              </Link>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </Slider>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
