import classNames from "classnames";
import styles from "./styles.module.scss";

export const KeyDetails = (props) => {
  const { data } = props;
  return (
    <ul className={classNames(styles.keyDetails, "row")}>
      {data.keyPoints.map((item, idx) => {
        return (
          <li className="col-12 col-md-6 mb-15" key={idx}>
            <span className={styles.kdWrap}>
              <i className={classNames(styles.icon, item.icon)}></i>
              <span>{item.point}</span>
            </span>
          </li>
        );
      })}
    </ul>
  );
};
