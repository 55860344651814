import classNames from "classnames";
import Slider from "react-slick";
import styles from "./styles.module.scss";

export const Carousel = (props) => {
  const { settings, className, children } = props;
  return (
    <Slider className={classNames(styles.slider, className)} {...settings}>
      {children}
    </Slider>
  );
};
