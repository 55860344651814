import background from "assets/img/research/logistics-bg.jpg";
import { Fragment } from "react";

export const logistics = {
  background: background,
  title: "Logistics",
  logisticsList: [
    {
      subTitle: "Grades",
      desc: "Incoming 10th - 12th graders and recent high school graduates",
    },
    {
      subTitle: "Dates",
      desc: "Coming soon",
    },
    {
      subTitle: "Time",
      desc: (
        <Fragment>
          Monday - Friday
          <span className="d-block">
            12 - 4pm ET (9am - 1pm PT)
          </span>
          <small className="text-italic d-block">
            *Must be able to join live sessions
          </small>
        </Fragment>
      ),
    },
    { subTitle: "Location", desc: "Virtual, with live instruction" },
    {
      subTitle: "Prerequisites",
      desc: "No prior STEM experience required. Students will learn all skills required during the program",
    },
    {
      subTitle: "Tuition",
      desc: (
        <Fragment>
          {/* $3995 */}
          <small className="text-italic d-block">
            {/* *Limited need-based scholarships are available. The scholarship application is available in the application form. */}
            *Details coming soon - Scholarships available for students with financial needs.
          </small>
        </Fragment>
      ),
    },
  ],
};
