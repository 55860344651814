import dod from "assets/img/common/dod-stem.svg";
import google from "assets/img/common/google-quantum-ai.png";

import ne from "assets/img/common/ne.png";
import microsoft from "assets/img/common/microsoft.png";

export const partnersLogo = [
  {
    src: dod,
  },
  {
    src: google,
  },
  {
    src: microsoft,
  },
  {
    src: ne,
  },
];
