import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const ForSchools = (props) => {
  const { title, subTitle, desc, schoolText, buttonLink, logos, isCourses, isSemesterCourse, button } = props;
  console.log(title?.thinText)
  return (
    <div
      className={classNames(styles.forSchools, { [styles.courses]: isCourses })}
    >
      <div className={classNames(styles.containerBig, "container")}>
        <header className={classNames({ [styles.isSemesterCourse]: isSemesterCourse })}>
          <h3 className={classNames(styles.heading, "text-blue-gradient")}>
            <span className={styles.textThin}>{title?.thinText ? title?.thinText : 'For'}</span> {title?.regularText ? title?.regularText : 'Schools'}

          </h3>
          {subTitle ? <h4 className={styles.subTitle}>{subTitle}</h4> : null}
          {desc ? <p className="mt-20">{desc}</p> : null}
        </header>
        <div className="row align-items-center justify-content-between">
          {schoolText ?
            <div
              className={classNames(
                !isCourses
                  ? "col-12 col-lg-7 text-center text-lg-left mb-30 mb-lg-0"
                  : "text-center col-12"
              )}
            >
              <div className={styles.schoolBox}>
                {schoolText}
                <a
                  className="btn btn-primary-gradient text-bold text-white text-upper rounded-0 px-15 py-15 px-lg-30 py-lg-20"
                  href={buttonLink}
                >
                  Connect with us
                </a>
              </div>
            </div>
            : null}
          {!isCourses ? (
            <div className={classNames("col-12", schoolText ? 'col-lg-5' : '')}>
              <div className={classNames(styles.rightArea, { [styles.isSemesterCourse]: isSemesterCourse })}>
                {!isSemesterCourse ? <p className="text-small text-center">
                  <small>Nationally Accredited by</small>
                </p> : null}
                <ul className={classNames(styles.logos, { [styles.isSemesterCourse]: isSemesterCourse }, "list-unstyled mb-0")}>
                  {logos?.map((item, idx) => {
                    return (
                      <li key={idx}>
                        <img
                          src={item.src}
                          className="img-fluid"
                          alt={item.alt}
                        />
                      </li>
                    );
                  })}
                </ul>

                {button &&
                  <div className={styles.buttons}>
                    {button?.map((item, idx) => {
                      return item.external ? (
                        <a
                          key={idx}
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classNames(
                            styles.button,
                            'btn btn-primary-gradient text-white rounded-0 py-15'
                          )}
                        >
                          {item.text}
                        </a>
                      ) : (
                        <Link
                          key={idx}
                          to={item.link}
                          className={classNames(
                            styles.button,
                            'btn btn-primary-gradient text-white rounded-0 py-15'
                          )}
                        >
                          {item.text}
                        </Link>
                      )
                    })}
                  </div>
                }
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
