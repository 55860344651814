import { Link } from "react-router-dom";
import classNames from "classnames";
import img from 'assets/img/about/leadership-bg.jpg';

import styles from "./styles.module.scss";

export const SocialEquity = () => {
  return (
    <div className={classNames(styles.socialEquity)}>
      <div className={styles.overlay}></div>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-lg-7">
            <div className={styles.socialEquityContent}>
              <h4 className={classNames(styles.title, 'text-white mb-20')}><span className={styles.titleFirst}>Dedicated to</span> Ensuring Social Equity</h4>
              <p className={classNames(styles.desc, 'text-white')}>
                There are already so many barriers to learning how to code; money
                should not be one of them. At The Coding School we're dedicated to
                ensuring all students have access to high quality STEM education. That's why
                we're pushing the boundaries to offer free courses for 10,000+
                student-cohorts and more. To join our mission,{" "}
                <Link to="/involved" className="text-black">
                  let's connect.
                </Link>
              </p>
            </div>
          </div>

          <div className="col-12 col-lg-5 mt-30 mt-lg-0">
            <div className="img-highlighted right">
              <img className="img-fluid" src={img} alt="Social Equity" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
