import sarah from "assets/img/research/sarah-parker.jpg";

export const leadInstructor = {
  name: "Sarah Parker",
  about:
    "Data Science instruction and daily research task assignments will be led and overseen by Sarah Parker, PhD researcher at the University of North Carolina.",
  moreText:
    "Sarah is a PhD candidate at the University of North Carolina pursuing a PhD in Bioinformatics and Computational Biology. The University of North Carolina is one of the top five federal research institutions in the United States and is known for its innovative approach to conducting research. She previously graduated from Pepperdine University with a BS in Biology and a minor in Computer Science. Her main interests lie in developing computational tools to aid in biological research and scientific communication. Some of her projects include a pipeline for the virtual screening process of drug discovery and a computer game to promote the conservation of giant kelp. Currently, she is developing genomics software, including a project to predict pairs of genes and the genomic elements that regulate them. Some of her other hobbies include baking, singing, and SCUBA diving.",
  img: {
    src: sarah,
    alt: "Sarah Parker",
  },
};
