import classNames from "classnames";
import styles from "./styles.module.scss";

export const Logistics = (props) => {
  const { data, hasBgOverlay } = props;
  return (
    <section
      className={styles.logistics}
      style={{ backgroundImage: "url(" + data?.background + ")" }}
    >
      {hasBgOverlay && <div className={styles.overlay}></div>}
      <div className="container">
        <h4 className={styles.title}>{data?.title}</h4>
        <ul className={classNames(styles.logisticsList, "list-unstyled mb-0")}>
          {data?.logisticsList?.map((item, idx) => (
            <li key={idx}>
              <span className={styles.subTitle}>{item?.subTitle}</span>
              <span className={styles.desc}>{item?.desc}</span>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
