import aboutBg from "assets/img/about/about-us-splash-bg.jpg";
import styles from "./styles.module.scss";

export const AboutBanner = () => {
  return (
    <div
      className={styles.aboutBlockImage}
      style={{ backgroundImage: "url(" + aboutBg + ")" }}
    >
      <div className="container text-center">
        <h1>About Us</h1>
        <h3>Training the diverse STEM leaders, innovators, and builders of tomorrow</h3>
      </div>
    </div>
  );
};
