import { useState, useEffect } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { Loader } from "component/Common/Loader";

export const Stories = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  const fetchPosts = async () => {
    try {
      const response = await fetch("https://blog.the-cs.org/wp-json/wp/v2/posts");
      const result = await response.json();
      setPosts(result);
      setIsLoading(false);
    }
    catch {
      return;
    }

  }
  useEffect(() => {
    fetchPosts();
  }, [])

  return (
    <div className={styles.tcsStories}>
      <div className="container">
        <header data-aos="fade-up" data-aos-duration="500">
          <h2>Stories from The Coding School</h2>
          <h3>Stories about education, people and innovation at TCS</h3>
        </header>
      </div>
      <div className={styles.tcsContainer + " mb-30"}>
        <div className={styles.tcsStoryWrap}>
          {isLoading ?
            <Loader /> :
            posts?.slice(0, 6).map((item, idx) => {
              return (
                <div
                  className={classNames(styles.tcsStoryCard, {
                    [styles.bigCard]: idx === 0 || idx === 5,
                  })}
                  key={idx}
                  data-aos="fade-up"
                  data-aos-duration={1000 * (idx + 1)}
                >
                  <div
                    className={classNames(styles.postCard, {
                      [styles.bigCard]: idx === 0 || idx === 5,
                    })}
                  >
                    <a
                      href={
                        item.acf.custom_link ? item.acf.custom_link : item.link
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className={styles.imgArea}>
                        <img src={item.fimg_url} alt={item.title.rendered} />
                      </div>
                      <div className={styles.cardContent}>
                        <p className={styles.category}>
                          {item.acf.post_category}
                        </p>
                        <p
                          className={styles.title}
                          dangerouslySetInnerHTML={{
                            __html: item.title.rendered,
                          }}
                        ></p>
                      </div>
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div
        className="container text-center"
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <a
          href="http://blog.the-cs.org/"
          className={styles.button + " btn btn-gradient-2 text-white"}
        >
          More TCS Stories
        </a>
      </div>
    </div>
  );
};
