import { Fragment } from 'react'

import student1 from 'assets/img/testimonials/student/1.jpg'
import student2 from 'assets/img/testimonials/student/2.jpg'
import student3 from 'assets/img/testimonials/student/3.jpg'
import student4 from 'assets/img/testimonials/student/4.jpg'
import student5 from 'assets/img/testimonials/student/5.jpg'
import student6 from 'assets/img/testimonials/student/6.jpg'
import student7 from 'assets/img/testimonials/student/7.jpg'
import student8 from 'assets/img/testimonials/student/8.jpg'
import student9 from 'assets/img/testimonials/student/9.jpg'
import student10 from 'assets/img/testimonials/student/10.jpg'
import student11 from 'assets/img/testimonials/student/11.jpg'
import student12 from 'assets/img/testimonials/student/12.jpg'
import student13 from 'assets/img/testimonials/student/13.jpg'
import student14 from 'assets/img/testimonials/student/14.jpg'
import student15 from 'assets/img/testimonials/student/15.jpg'
import student16 from 'assets/img/testimonials/student/16.jpg'
import student17 from 'assets/img/testimonials/student/17.jpg'
import student18 from 'assets/img/testimonials/student/18.jpg'
import student19 from 'assets/img/testimonials/student/19.jpg'
import student20 from 'assets/img/testimonials/student/20.jpg'
import student21 from 'assets/img/testimonials/student/21.png'
import student22 from 'assets/img/testimonials/student/22.jpg'

import partner1 from 'assets/img/testimonials/partner/1.jpg'
import partner2 from 'assets/img/testimonials/partner/2.jpg'
import partner3 from 'assets/img/testimonials/partner/3.jpg'
import partner4 from 'assets/img/testimonials/partner/4.jpg'
import partner5 from 'assets/img/testimonials/partner/5.jpg'
import partner6 from 'assets/img/testimonials/partner/6.jpg'
import partner7 from 'assets/img/testimonials/partner/7.jpg'
import partner8 from 'assets/img/testimonials/partner/8.jpg'
import partner9 from 'assets/img/testimonials/partner/9.jpg'
import partner10 from 'assets/img/testimonials/partner/10.jpg'
import partner11 from 'assets/img/testimonials/partner/11.jpg'
import partner12 from 'assets/img/testimonials/partner/12.jpg'
import partner13 from 'assets/img/testimonials/partner/13.jpg'
import partner14 from 'assets/img/testimonials/partner/14.jpg'
import partner15 from 'assets/img/testimonials/partner/15.png'
import partner16 from 'assets/img/testimonials/partner/16.png'
import partner17 from 'assets/img/testimonials/partner/17.jpg'
import partner18 from 'assets/img/testimonials/partner/18.jpg'

import instructor1 from 'assets/img/testimonials/instructor/1.jpg'
import instructor2 from 'assets/img/testimonials/instructor/2.jpg'
import instructor3 from 'assets/img/testimonials/instructor/3.jpg'
import instructor4 from 'assets/img/testimonials/instructor/4.jpg'
import instructor5 from 'assets/img/testimonials/instructor/5.jpg'
import instructor6 from 'assets/img/testimonials/instructor/6.jpg'
import instructor7 from 'assets/img/testimonials/instructor/7.jpg'
import instructor8 from 'assets/img/testimonials/instructor/8.jpg'
import instructor9 from 'assets/img/testimonials/instructor/9.jpg'
import instructor10 from 'assets/img/testimonials/instructor/10.jpg'
import instructor11 from 'assets/img/testimonials/instructor/11.jpg'
import instructor12 from 'assets/img/testimonials/instructor/12.jpg'
import instructor13 from 'assets/img/testimonials/instructor/13.jpg'
import instructor14 from 'assets/img/testimonials/instructor/14.jpg'

export const studentTestimonials = [
  {
    name: '12th grader, Arizona',
    testimony: (
      <Fragment>
        This camp has honestly been{' '}
        <span className="text-bold">life-changing</span> and{' '}
        <span className="text-bold">eye-opening!</span> I am looking forward to
        exploring quantum for the rest of my life.
      </Fragment>
    ),
    image: student5,
  },
  {
    name: 'High school student, Kenya',
    testimony: (
      <Fragment>
        I will forever be{' '}
        <span className="text-bold">grateful to Qubit by Qubit</span> for giving
        me a chance to take part in this program, and finally{' '}
        <span className="text-bold">
          revolutionize African women and their achievements in the scientific
          and technological community.
        </span>
      </Fragment>
    ),
    image: student3,
  },
  {
    name: '12th grader, California',
    testimony: (
      <Fragment>
        When I grow up, I would like to be a data scientist or software
        engineer. I would love to represent the demographic you usually don’t
        see in those fields as I am a hispanic/Latina woman.{' '}
        <span className="text-bold">
          Luckily, I found this course and am so happy about the fact that I
          expand my knowledge about machine learning every week.
        </span>
      </Fragment>
    ),
    image: student17,
  },
  {
    name: 'High school student, USA',
    testimony: (
      <Fragment>
        While I identify as non-binary, I was assigned female at birth, and many
        individuals perceive me as such.{' '}
        <span className="text-bold">
          [This program's] emphasis on diversity and inclusion really makes me
          feel welcome, and I feel genuinely safe
        </span>{' '}
        in this community.
      </Fragment>
    ),
    image: student6,
  },
  {
    name: '12th grader, Texas',
    testimony: (
      <Fragment>
        <span className="text-bold">I LOVED THE CAMP!</span> There are many
        students such as myself whether that be minority, low income, etc. who,
        by nature of social and systematic marginalization, lack many
        opportunities and chances of exposure...I had never heard of Quantum
        Coding before this camp, let alone thought that I would enjoy it!{' '}
        <span className="text-bold">
          This camp and this scholarship gave me an opportunity and a new liking
          for another field of STEM study that without it, I would not have
          known anything about!
        </span>
      </Fragment>
    ),
    image: student4,
  },
  {
    name: '10th grader, Maryland',
    testimony: (
      <Fragment>
        I love how interactive this AI/ML course is and that the pace isn't too
        slow but I can still retain a lot of information. AI and ML interest me
        because it is exciting to see how different lines of code can interact
        to <span className="text-bold">create something extraordinary.</span>
      </Fragment>
    ),
    image: student18,
  },
  {
    name: 'High school student, Scotland',
    testimony: (
      <Fragment>
        <span className="text-bold">
          I am so thankful as my family are homeless and I would never have been
          able to do this course if it wasn't...free.
        </span>{' '}
        This is the beginning of my journey and future career and it is an
        amazing and exciting opportunity.
      </Fragment>
    ),
    image: student8,
  },
  {
    name: '10th grader, California',
    testimony: (
      <Fragment>
        What I love about this course is how{' '}
        <span className="text-bold">interactive and supportive</span> the
        learning process is. There are so many office hours and homework help
        sessions available, and there's really this sense of a{' '}
        <span className="text-bold">true failure-free environment.</span>
      </Fragment>
    ),
    image: student19,
  },
  {
    name: '10th grader, Massachusetts',
    testimony: (
      <Fragment>
        <span className="text-bold">
          Qubit by Qubit with IBM really changed my life!
        </span>{' '}
        The course strengthened my math skills, increased my desire to explore
        STEM and hopefully one day become an Engineer or a student in college
        studying some type of math or programming heavy field!
      </Fragment>
    ),
    image: student10,
  },
  {
    name: '12th grader, New Jersey',
    testimony: (
      <Fragment>
        <span className="text-bold">I absolutely loved this program</span> and
        it’s one that I’ll never forget. I made connections that I’ll remember
        for a lifetime and it was bitter sweet to say goodbye. As I start new
        programs, I always remember the professionalism and community that this
        program exuded and{' '}
        <span className="text-bold">
          I hope to complete more programs with The Coding School in the future.
        </span>
      </Fragment>
    ),
    image: student20,
  },
  {
    name: 'High school student family, California',
    testimony: (
      <Fragment>
        Wednesday night dinners now include a summary of quantum computing lab
        activities and a preview for my Saturday lab. While our Sunday mornings
        involve gathering in the living room with our laptops, logging into our
        Zoom meeting, & furthering our knowledge in quantum computing.{' '}
        <span className="text-bold">
          This course has created a wonderful opportunity that includes
          academics and family time.
        </span>
      </Fragment>
    ),
    image: student11,
  },
  {
    name: '12h grader, Virginia',
    testimony: (
      <Fragment>
        This course takes an increasingly necessary yet complicated field and{' '}
        <span className="text-bold">
          simplifies it down into a gateway that every student can easily walk
          through
        </span>{' '}
        as they begin their journey into Machine Learning and Artificial
        Intelligence.
      </Fragment>
    ),
    image: student22,
  },
  {
    name: 'University student, England',
    testimony: (
      <Fragment>
        My time at Qubit by Qubit has been nothing short of a{' '}
        <span className="text-bold">truly incredible experience.</span>
      </Fragment>
    ),
    image: student1,
  },
  {
    name: '11th grader, New York',
    testimony: (
      <Fragment>
        I am so lucky that I am learning quantum computing as a high school
        student. Having gone through this course, I feel that I can overcome any
        academic challenge that awaits in my high school, and later college,
        STEM classes. <span className="text-bold">I feel so powerful </span>,
        and this course further developed my interest in conducting research in
        the field.
      </Fragment>
    ),
    image: student2,
  },
  {
    name: '12th grader, Australia',
    testimony: (
      <Fragment>
        This course is <span className="text-bold">absolutely beautiful</span>,
        it is pact with the introductory information into the world of quantum.
      </Fragment>
    ),
    image: student7,
  },
  {
    name: '11th grader, USA',
    testimony: (
      <Fragment>
        In regular STEM classes, when I look around the room, I’m one of the
        only girls. In this class,{' '}
        <span className="text-bold">I was mentored by a female.</span> Learning
        quantum computing from someone that I know shared a similar if not more
        difficult experience in STEM{' '}
        <span className="text-bold">inspired me to believe in myself</span> and
        chase after more complex sciences.
      </Fragment>
    ),
    image: student9,
  },
  {
    name: '11th grader, Turkey',
    testimony: (
      <Fragment>
        It’s the small things that make this course feel inclusive… there are so
        many women here that you don’t feel out of place.{' '}
        <span className="text-bold">
          It’s not a 'he thing.' It’s a smart people thing.
        </span>
      </Fragment>
    ),
    image: student12,
  },
  {
    name: '11th grader, Pennsylvania',
    testimony: (
      <Fragment>
        When I heard about the Qubit by Qubit course, I knew I had to apply.{' '}
        <span className="text-bold">
          This course has challenged my intuitions and exposed me to new
          realities of the micro-world
        </span>
        . I love how this course and the field of quantum computing connect all
        of my favorite subjects: math; physics; and computer science.
      </Fragment>
    ),
    image: student13,
  },
  {
    name: 'University student, Minnesota',
    testimony: (
      <Fragment>
        I appreciated the opportunity to continue learning during my winter
        break,{' '}
        <span className="text-bold">
          interact with diverse students, and engage in material about a topic I
          would not have the chance to learn about otherwise.
        </span>
      </Fragment>
    ),
    image: student14,
  },
  {
    name: '10th grader, Canada',
    testimony: (
      <Fragment>
        As I attended lectures and participated in labs, my excitement for
        quantum computing only grew.
      </Fragment>
    ),
    image: student15,
  },
  {
    name: '12th grader, Maryland',
    testimony: (
      <Fragment>
        This year of learning quantum computing{' '}
        <span className="text-bold">changed my college plans.</span> This course
        cemented my love for physics, and has made me{' '}
        <span className="text-bold">
          pivot to pursue this in higher education
        </span>{' '}
        through undergrad, through a masters and even a PhD.
      </Fragment>
    ),
    image: student16,
  },
  {
    name: '9th grader, Pennsylvania',
    testimony: (
      <Fragment>
        This course has introduced me to concepts that I would have not been
        able to really learn and comprehend until much later in my life.{' '}
        <span className="text-bold">
          This class has opened my eyes to various opportunities in this field
          at an early age.
        </span>
      </Fragment>
    ),
    image: student21,
  },
]

export const schoolPartnersTestimonials = [
  {
    name: 'Maajida Murdock, Math instructor, Baltimore County Public Schools',
    testimony: (
      <Fragment>
        I have seen how presenting opportunities to a student has broadened
        their vision of themself in a STEM field with determination. My
        students' experience with{' '}
        <span className="text-bold">
          Qubit by Qubit brought out a sense of confidence in their ability as a
          coder.
        </span>
      </Fragment>
    ),
    image: partner5,
  },
  {
    name: 'Ms. Boral, Computer Science and Engineering Department Chair/Teacher, Archbishop Molloy HS, New York City',
    testimony: (
      <Fragment>
        One of the things that is so amazing about the TRAIN and QxQ courses is
        that they both have amazing relationships with professors and scientists
        within these fields. Our students are able to listen and participate in
        panel meetings with these scientists and we, as a school, would never be
        able to provide that.{' '}
        <span className="text-bold">
          This is such a big inspiration for them - that somebody cares.
        </span>{' '}
        That a scientist cares about them. About their futures. About their
        knowledge.{' '}
        <span className="text-bold">
          This enables them to truly see themselves as future STEM leaders.
        </span>
      </Fragment>
    ),
    image: partner15,
  },
  {
    name: 'Charles Shryock, Assistant Principal, Bishop McNamara High School',
    testimony: (
      <Fragment>
        <span className="text-bold">
          The Qubit by Qubit teachers have taken a mysterious, futuristic,
          complex subject and made it clear and accessible
        </span>
        . Students say the pacing, workload, and engaging lectures and
        activities are perfect for them. I find it tremendously exciting to see
        their progress, and watch their{' '}
        <span className="text-bold">confidence grow</span> week by week. And I
        am grateful that Qubit by Qubit made it so easy to enroll and support
        new students.
      </Fragment>
    ),
    image: partner9,
  },
  {
    name: 'Jacky Paton, Science Lead, Farmington High School, Connecticut',
    testimony: (
      <Fragment>
        The purpose of this course is to offer opportunities to the students of
        Farmington High School that we couldn’t with the staffing we currently
        have. I have a wide range of students who have anywhere between little
        to a lot of background in coding, but what they don’t have experience in
        is Artificial Intelligence, bias, ethical computer science topics, so{' '}
        <span className="text-bold">
          this course has opened their eyes to the idea that computer science
          and Artificial Intelligence impacts every aspect of our society,
        </span>{' '}
        not just computer science or STEM fields. And so, because of this
        course, these students have learned things they didn’t know they didn’t
        know.
      </Fragment>
    ),
    image: partner16,
  },
  {
    name: 'Brett Tanaka, Computer Science Educational Specialist, Hawaii State Department of Education',
    testimony: (
      <Fragment>
        We appreciate the Introduction to Quantum Computing virtual learning
        opportunities by Qubit by Qubit that are accessible to our students.
        This opportunity is very{' '}
        <span className="text-bold">helpful for our rural schools</span> that
        may not have the capacity to offer computer science courses in new
        emerging CS education areas such as quantum computing. Thank you to the
        Qubit by Qubit Team!
      </Fragment>
    ),
    image: partner10,
  },
  {
    name: 'Principal Penikas, Archbishop Molloy High School, New York City',
    testimony: (
      <Fragment>
        STEM education is a core knowledge these days. What goes into coding;
        what goes into AI; the basic ideas behind quantum computing. I think
        these are concepts that students need to be exposed to early, so they
        can begin to understand what they are. In my experience, if anyone
        understands HOW something works, their interest is greater. Therefore,
        we hope to promote careers in STEM and we want our students to be
        leaders in our future society.{' '}
        <span className="text-bold">
          The Coding School is helping them achieve that.
        </span>
      </Fragment>
    ),
    image: partner17,
  },
  {
    name: 'Nimmi Arunachalam, Palm Beach County, Florida',
    testimony: (
      <Fragment>
        I <span className="text-bold">highly recommend this opportunity</span>{' '}
        to all students and teachers who are curious about the role of quantum
        computing in our futures.
      </Fragment>
    ),
    image: partner1,
  },
  {
    name: 'Heather Pereira, Pleasanton Unified School District, CA',
    testimony: (
      <Fragment>
        Qubit by Qubit has been a wonderful partner to work with. From assisting
        with ensuring that the course is approved by the University of
        California to providing a streamlined and straightforward enrollment
        process, the{' '}
        <span className="text-bold">
          management of the course has been great.
        </span>
      </Fragment>
    ),
    image: partner2,
  },
  {
    name: 'William K. Funderburk, Mississippi School for Mathematics and Science',
    testimony: (
      <Fragment>
        <span className="text-bold">
          My own participation in this course has resulted in an overall better
          physics curriculum at my school!
        </span>{' '}
        and I'm very thankful I met them. I'm so pleased to recommend their
        high-school course in Quantum Computing to any high school.
      </Fragment>
    ),
    image: partner3,
  },
  {
    name: 'Ben Iseke, Bergen County Academies',
    testimony: (
      <Fragment>
        The program runners at Qubit by Qubit are incredibly devoted educators.
        Their willingness to engage with feedback showed how much they wanted
        the program to be deeply excellent, and that dedication showed in every
        aspect of the program that I got a chance to see.{' '}
        <span className="text-bold">
          The program is genuinely incredible, and undoubtedly life-changing for
          many of the students that they work with.
        </span>{' '}
        QxQ is creating the future that we need.
      </Fragment>
    ),
    image: partner4,
  },
  // {
  //   name: "William K. Funderburk",
  //   testimony:
  //     "My own participation in this course has resulted in an overall better physics curriculum at my school! and I'm very thankful I met them. I'm so pleased to recommend their high-school course in Quantum Computing to any high school.",
  //   image: partner6,
  // },
  {
    name: 'Amanda Giuliani, STEM Coordinator and Teacher, Longmont, Colorado',
    testimony: (
      <Fragment>
        Time is always of the essence for teachers, and this program provides
        everything I could possibly need to run the course in my school.{' '}
        <span className="text-bold">
          The content is presented in an engaging way
        </span>
        , and there are ample opportunities to make the online coursework align
        with my busy schedule. I am so thankful I learned about Qubit x Qubit!
      </Fragment>
    ),
    image: partner7,
  },
  {
    name: 'Audrey Wei, CS teacher, Bishop Amat High School',
    testimony: (
      <Fragment>
        I highly{' '}
        <span className="text-bold">recommend all students of the world</span>{' '}
        to take the class to help them prepare for the quantum computing world.
      </Fragment>
    ),
    image: partner8,
  },
  {
    name: 'Chris Lynch, STEM Manager, Arkansas Department of Career Education',
    testimony: (
      <Fragment>
        <span className="text-bold">My students have grown tremendously</span>{' '}
        in understanding quantum circuits, and I enjoy watching them explain
        concepts during the Zoom class meetings. This program is a very
        important first step in creating a cadre of quantum programmers and
        developers as quantum computing moves into the mainstream of information
        processing.
      </Fragment>
    ),
    image: partner11,
  },
  {
    name: 'Michelle Fujie, Mathematics teacher at Lanai High & Elementary School, Lanai, HI',
    testimony: (
      <Fragment>
        I must admit after experiencing the program, I was envious to see that
        The Coding School found the perfect mix allowing students to be actively
        engaged and continuously learning even while on a virtual platform! As a
        math educator of over 11 years,{' '}
        <span className="text-bold">
          I found myself eager to be a student again
        </span>
        , actively taking notes, and engaging in meaningful conversations while
        learning something new every day.
      </Fragment>
    ),
    image: partner12,
  },
  {
    name: 'Dr. Narineh Makijan, Chair/Assistant Vice President, Los Angeles Regional Consortium',
    testimony: (
      <Fragment>
        The Coding School in collaboration with IBM Quantum has created an
        <span className="text-bold">
          innovative introduction to quantum computing course for high school
          students
        </span>{' '}
        that is taught by esteemed MIT and Oxford quantum scientists.
      </Fragment>
    ),
    image: partner13,
  },
  {
    name: 'Chris Lo, Math, Computer Science, Engineering teacher',
    testimony: (
      <Fragment>
        The content was{' '}
        <span className="text-bold">
          thorough, engaging, and very well designed with sparking curiosity and
          learning in mind
        </span>
        . I strongly believe that any visiting teacher to the camp would approve
        of the pedagogy and method of delivery these lessons demonstrated.
      </Fragment>
    ),
    image: partner14,
  },
  {
    name: 'Mr. Skerpon, Technology/Math Teacher, Bishop McNamara High School, Maryland',
    testimony: (
      <Fragment>
        The independent study nature of these courses has really helped a lot of
        these students realize that they can learn outside of school. They’re
        interacting with it in a totally different way - in a way that best fits
        their schedule. And that is an unbelievable benefit. They’re all smart
        enough to learn about these topics themselves -{' '}
        <span className="text-bold">
          they just need help to figure out how to access the information, which
          The Coding School is doing.
        </span>
      </Fragment>
    ),
    image: partner18,
  },
]

export const instructorTestimonials = [
  {
    name: 'Anthony, Google, TCS instructor',
    testimony: (
      <Fragment>
        I wish I had learned coding earlier. In beginning college computer
        science courses you are thrown in and expected to know so much already.
        These kids we’re teaching now have a huge advantage -- they’ll have the
        solid foundation and ability to build their knowledge from the ground up
        -- helping them understand and learn so much more, so much easier.{' '}
        <span className="text-bold">
          The beneficial impact this education at a young age will have on them
          is immeasurable.
        </span>
      </Fragment>
    ),
    image: instructor1,
  },
  {
    name: 'Kelly, Amazon, TCS Advisory Board',
    testimony: (
      <Fragment>
        [At The Coding School] the people who are teaching you are{' '}
        <span className="text-bold">highly qualified</span> people. The best
        students who came out of the best universities and now working at the
        best companies and they’re spending their time wanting to help
        [students] learn more. That’s a resource that not a lot of people could
        ever find in their normal life.
      </Fragment>
    ),
    image: instructor2,
  },
  // {
  //   name: 'A.J., JP Morgan Chase, TCS instructor',
  //   testimony: (
  //     <Fragment>
  //       [At The Coding School] the people who are teaching you are highly
  //       qualified people. The best students who came out of the best
  //       universities and now working at the best companies and they’re spending
  //       their time wanting to help [students] learn more. That’s a resource that
  //       not a lot of people could ever find in their normal life.
  //     </Fragment>
  //   ),
  //   image: instructor3,
  // },
  {
    name: 'Aziza, , MIT, TCS instructor',
    testimony: (
      <Fragment>
        I think it’s very important to introduce high school students to this
        technology. I think this is the way you{' '}
        <span className="text-bold">
          lower the barrier to entry and get people from many different
          backgrounds into the field.
        </span>
      </Fragment>
    ),
    image: instructor4,
  },
  {
    name: 'Sanskriti, North Carolina State University, TCS instructor',
    testimony: (
      <Fragment>
        I am sure I have multiple Noble Prize winners in my labs.
      </Fragment>
    ),
    image: instructor5,
  },
  {
    name: 'Shayda, MIT, TCS instructor',
    testimony: (
      <Fragment>
        The more time I spent teaching, the more I learned about the impact I
        can have on someone’s future. I left this summer with a new passion to
        leverage the power of technology to{' '}
        <span className="text-bold">
          bring students from diverse backgrounds together
        </span>{' '}
        and teach each other that{' '}
        <span className="text-bold">
          we all share so much more in common than we are different.
        </span>
      </Fragment>
    ),
    image: instructor6,
  },
  {
    name: 'Ricky, University of Connecticut, TCS instructor',
    testimony: (
      <Fragment>
        I can’t count how many times a student has asked a question that has me
        pause and think, ‘I’ve never thought of that.’ They prove to me over and
        over that they are the future of quantum.
      </Fragment>
    ),
    image: instructor7,
  },
  {
    name: 'Alev, University of Waterloo , TCS instructor',
    testimony: (
      <Fragment>
        I wish I had this kind of community when I first started.
      </Fragment>
    ),
    image: instructor8,
  },
  {
    name: 'Yao, Harvard, TCS instructor',
    testimony: (
      <Fragment>
        The best part about the TCS community is that{' '}
        <span className="text-bold">
          all of the teaching staff believe in and are passionate about
          education advocacy{' '}
        </span>
        and really making computer science accessible to everyone and making it
        fun at the same time.
      </Fragment>
    ),
    image: instructor9,
  },
  {
    name: 'Isabelle, Microsoft, TCS instructor',
    testimony: (
      <Fragment>
        What’s really awesome about the program is that the instructors are
        mainly college students or recent grads close to the age of the
        students. I think it’s really important that students in middle and high
        school have someone close in age they can look up to as a mentor -
        <span className="text-bold">
          especially if that person looks like them, talks like them and has a
          background similar to them.
        </span>
      </Fragment>
    ),
    image: instructor10,
  },
  // {
  //   name: 'Jack, Duke University, TCS instructor',
  //   testimony: (
  //     <Fragment>
  //       I think what The Coding School does with their 1:1 lessons format and
  //       having instructors be full time mentors and moving at any pace [for the
  //       student] results in learning in the most effective way. It’s something
  //       I’ve never experienced before and it’s super refreshing and effective.
  //     </Fragment>
  //   ),
  //   image: instructor11,
  // },
  {
    name: 'Rishi, Google, TCS instructor',
    testimony: (
      <Fragment>
        I think learning all the [tough] parts of coding and syntax is done
        better with a friend.{' '}
        <span className="text-bold">
          I see myself as a friend and a mentor for the students
        </span>{' '}
        I’ve had a chance to work with. And it’s been a really powerful
        experience.
      </Fragment>
    ),
    image: instructor12,
  },
  {
    name: 'Aaron, Microsoft, TCS instructor',
    testimony: (
      <Fragment>
        It’s so exciting for me to be able to teach someone across the country.
        I see the excitement in his eyes every time he makes a circle bouncing
        across the screen.{' '}
        <span className="text-bold">
          It’s immediately rewarding and inspiring.
        </span>{' '}
        I love it.
      </Fragment>
    ),
    image: instructor13,
  },
]

export const testimonialsTab = [
  {
    href: '#students',
    testifierText: 'Students',
    text: 'Students',
  },
  {
    href: '#partner-school',
    testifierText: 'Partners',
    text: 'Partner Schools',
  },
  {
    href: '#instructors',
    testifierText: 'Instructors',
    text: 'Instructors',
  },
]
