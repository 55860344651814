import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import classNames from "classnames";

import WASC from "assets/img/common/wasc.png";
import UCAG from "assets/img/common/uc-ag-guide.png";

import styles from "./styles.module.scss";

export const SchoolPartners = () => {
  return (
    <div className={styles.schoolPartner + " py-80"}>
      <div className={styles.containerBig + " container"}>
        <div className="row align-items-center">
          <div
            className="col-lg-5 col-md-12"
            data-aos="fade-left"
            data-aos-duration="500"
          >
            <div className={styles.spLeft}>
              <p>Nationally Accredited By: </p>
              <img src={WASC} className="mb-50" alt="wasc" />
              <img src={UCAG} alt="uc-ag-guide" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12">
            <div className={styles.schoolBox}>
              <div className={styles.visualText}>
                <h3 data-aos="fade-up" data-aos-duration="500">
                  School <span className="text-gold">Partners</span>
                </h3>
                <div data-aos="fade-down" data-aos-duration="500">
                  <p>
                    Accredited by the Western Association of Schools and
                    Colleges (WASC), your K-12 students can earn transcript
                    credit for courses in emerging technologies, such as AI and Quantum
                    Computing.
                  </p>
                  <p>
                    Hundreds of schools and districts across the U.S. from the
                    Hawaii Department of Education to the Cleveland School
                    District have partnered with The Coding School to ensure
                    their students receive the highest quality and most
                    cutting-edge STEM education possible. The Coding School's
                    Qubit by Qubit Introduction to Quantum Computing Course is
                    also A-G approved by the University of California as an
                    accepted college preparatory course.
                  </p>
                  <div className={classNames(styles.sbButtons, "mt-30 p-0")}>
                    <Link
                      className={styles.link + " btn btn-outline"}
                      to="/school-partner-info"
                    >
                      CONNECT WITH US
                    </Link>
                    <HashLink
                      className={styles.link + " btn btn-outline"}
                      to="/about_us#school-partners"
                    >
                      SEE OUR SCHOOL PARTNERS
                    </HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
