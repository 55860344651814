import { Player } from "@lottiefiles/react-lottie-player";
import dodStem from "assets/img/train/dod-stem-dark.png";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

export const TrainBanner = () => {
  return (
    <section className={styles.banner}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12 col-xl-7">
            <div className={styles.headline} data-aos="zoom-in">
              <h1>
                <span className={styles.trainLogo}>
                  <span className={styles.tlGrayDark}>TR</span>
                  <span className="text-brand-light">AI</span>
                  <span className={styles.tlGrayDark}>N</span>
                  <span className={styles.tlGrayLight}>ING</span>
                </span>
                <span className="d-block text-blue-gradient">
                  The Next Generation of
                </span>{" "}
                <span className="text-brand-light d-inline-block">AI</span>{" "}
                LEADERS AND EDUCATORS
              </h1>
            </div>
            <div
              className={styles.trainPageTagline + " mt-40 mb-20"}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              {/* <h4>This initiative is proudly funded by</h4>
              <img src={dodStem} alt="DOD Stem" /> */}
              <h4>FREE PD Bootcamp for college faculty – see details below! </h4>
              {/* <h4>2024-25 Intro to AI Course for High School Students - <Link className="text-gold text-italic text-bold" to="/course-application">Sign Up Today!</Link></h4> */}
            </div>
            <div className="text-center mb-20">
              <a href="https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY" target="_blank" rel='noreferrer' className="btn btn-gradient-2 text-white px-20 py-15">
                2025 Interest Form
              </a>  
            </div>
            <h5 className="text-center bg-blue-gradient rounded px-10 py-20">
              Courses for high school students and educators plus college
              faculty
            </h5>
          </div>
          <div className="col-8 col-lg-6 col-xl-5 train-lottier-player mx-auto">
            <Player
              autoplay={true}
              loop={true}
              controls={true}
              src="https://assets2.lottiefiles.com/packages/lf20_wjngbsl7.json"
            ></Player>
          </div>
        </div>
      </div>
    </section>
  );
};
