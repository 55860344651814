import classNames from 'classnames'
import banner from 'assets/img/about/advisory-board.jpg'

import { faqs } from 'data/ypab'
import { ArrowDown } from 'component/Icons'
import { youngProfessionals } from 'data'

import styles from './styles.module.scss'

export const YPAB = () => {
  return (
    <main className={styles.ypab}>
      <section
        className={styles.banner}
        style={{ backgroundImage: `url(${banner})` }}
      >
        <h1 className={styles.yabText}>Young Professionals Advisory Board</h1>
      </section>

      <section className={styles.aboutYpab}>
        <div className="container position-relative">
          <div className={classNames(styles.textDetail, 'shadowedBox')}>
            <h2 className={styles.heading}>Meet our YPAB!</h2>
            <p className={styles.content}>
              Our YPAB is a team of young leaders who are committed to
              supporting TCS&apos;s mission of transforming and democratizing
              STEM education. The YPAB supports TCS&apos;s mission through
              thought leadership, fundraising, recruiting new board members,
              fostering business partnerships, and organizing an annual gala.
            </p>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row justify-content-center">
          <div className={styles.youngProfessionalsBlock}>
            {youngProfessionals.map((val, index) => {
              return (
                <div className={styles.professionals} key={index}>
                  <img
                    className={styles.avatar}
                    src={val.avatar}
                    alt={val.name}
                  />
                  <div className={styles.meta}>
                    <p className={styles.name}>{val.name}</p>
                    <img
                      src={val.organization}
                      className={styles.organization}
                      alt={val.organizationName}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <section className={styles.aboutYpab}>
        <div className="container position-relative">
          <div className={classNames(styles.textDetail, 'shadowedBox')}>
            <h2 className={styles.heading}>
              The Coding School&apos;s Young Professional Advisory Board is
              recruiting new members!
            </h2>
            <p className={styles.content}>
              We&apos;re looking for motivated young professionals to bring
              fresh perspectives, lead strategic efforts, and help expand our
              overall impact. This is an opportunity to drive growth and
              innovation while directly influencing the future of STEM
              education.
            </p>
          </div>
        </div>
      </section>

      <div className={styles.faqSection}>
        <div className="container">
          <div id="accordion">
            {faqs.map((item, idx) => {
              return (
                <div className={classNames(styles.card, 'card')} key={idx}>
                  <div
                    className={classNames(styles.cardHeader, 'card-header p-0')}
                    id={'faq-' + idx}
                  >
                    <h5 className="mb-0">
                      <button
                        className="btn collapsed"
                        data-toggle="collapse"
                        data-target={'#tc-faq' + idx}
                        aria-expanded="false"
                        aria-controls="tc-faq"
                      >
                        <span className={styles.chText}>{item.qu}</span>
                        <ArrowDown />{' '}
                      </button>
                    </h5>
                  </div>

                  <div
                    id={'tc-faq' + idx}
                    className={classNames(styles.tcCollapse, 'collapse')}
                    aria-labelledby={'faq-' + idx}
                    data-parent="#accordion"
                  >
                    <div
                      className={classNames(styles.cardBody, 'card-body p-20')}
                    >
                      <p>{item.an}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <section className={styles.postFaq}>
        <div className="container text-center">
          <h3>
            Ready to apply? Email your resume and a brief statement about your
            interest to <a href="mailto:ypab@the-cs.org">ypab@the-cs.org</a>.
          </h3>

          <p>
            Interviews will be conducted on a rolling basis through mid-November
            and the new YPAB cohort should expect to begin their term on
            December 2, 2024.
          </p>
        </div>
      </section>
    </main>
  )
}
