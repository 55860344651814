import { Fragment } from 'react'

export const banner = {
  bannerTitle: 'AI will change',
  rotatingWords: [
    'Healthcare',
    'Finance',
    'Policymaking',
    'Conservation',
    'Defense',
    'Education',
    'Aerospace',
    'Entertainment',
  ],
  bannerSubTitle: 'Prepare your students to be responsible ai leaders',
  secondSubTitle: [
    'Artificial Intelligence Professional Development for High School Educators',
    'Virtual',
    'July 22 - August 2, 2024',
  ],
  thirdSubTitle: (
    <Fragment>
      <span className="d-block mw-80 fs-18">
        The application for this PD is now closed. <br />
        Please fill out the interest form below to hear about our next AI PD.
      </span>
    </Fragment >
  ),
  button: {
    isExternal: true,
    title: 'Interest Form',
    link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
  },
}
