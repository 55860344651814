import { Fragment } from "react";

export const aiIntro = {
  paragraph: (
    <Fragment>
      AI will solve some of the most pressing issues of the 21st century. We
      want to make sure every high school student is prepared to become
      responsible AI leaders. That's why we've created,{" "}
      <i>Introduction to Artificial Intelligence (AI)</i>, a 4-week professional
      development course designed for all US-based high school teachers to gain
      cutting-edge knowledge and skills in AI and Machine Learning (ML) in order
      to bring this education back into their classrooms. Offered virtually with
      live instruction, the course is available to U.S. based high school
      educators. Throughout the course, educators will work on real-world AI
      projects; hear from leading experts in AI; and join a like-minded
      community of educators.{" "}
    </Fragment>
  ),
  aiList: [
    "Learn real skills in AI/ML",
    "FREE for all accepted educators",
    "Bring ML into your classroom",
    "Stipend provided",
    "CEU opportunity",
    "Work with industry experts",
  ],
  tagLine: (
    <Fragment>
      Be a part of the course created to train the nation's{" "}
      <span className="text-black">future responsible AI leaders!</span>
    </Fragment>
  ),
};
