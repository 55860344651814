import classNames from "classnames";
import styles from "./styles.module.scss";

export const TrainDisclaimer = () => {
  return (
    <section className={classNames(styles.sectionDisclaimer, "mb-50")}>
      <div className="container">
        <p className={classNames(styles.disclaimer, "text-center mb-0")}>
          <small>
            This material is based upon work supported by the National Defense
            Education Program (NDEP) for Science, Technology, Engineering, and
            Mathematics (STEM) Education, Outreach, and Workforce Initiative
            Programs under Grant No. HQ0034-22-1-0006. The views expressed in
            written materials or publications, and/or made by speakers,
            moderators, and presenters, do not necessarily reflect the official
            policies of the Department of Defense nor does mention of trade
            names, commercial practices, or organizations imply endorsement by
            the U.S. Government.
          </small>
        </p>
      </div>
    </section>
  );
};
