import { Fragment } from "react";
import { Link } from "react-router-dom";

export const aiIntro = {
  paragraph:
    <Fragment>Glimpse into the world of data science and machine learning to become a digital detective, unraveling mysteries hidden within datasets and making predictions about the future. From understanding social media trends to crafting the perfect playlist, The Coding School's <span className="text-italic">Foundations of Data Science and Machine Learning</span> course empowers students to wield the power of data and shape tomorrow's innovations while having fun exploring the endless possibilities of technology.</Fragment>,
  aiList: [
    "Learn real-world AI skills",
    "Meet industry experts",
    "Become a Responsible AI Leader",
    "No prerequisites",
  ],
  paraTwo: [
    'Early on students will develop a strong foundation in Python coding before delving into foundational machine learning models and algorithms to gain a deep understanding of data science concepts. From exploring data and conducting exploratory data analysis to creating impactful data visualizations, students will develop practical skills essential to data science and machine learning applications.',

    <Fragment>Students will have the opportunity to engage with industry experts, participate in ethical discussions, and prepare for the college application process. Whether students are intrigued by the algorithms driving our everyday lives or eager to shape the future of technology, <span className="text-italic">Foundations of Data Science and Machine Learning</span> offers an exciting journey into the forefront of innovation. Join us and unlock the potential of data!</Fragment>,

    <Fragment>Offered for the first time as a one-semester course available to high school students across the U.S. and internationally, students have the option to delve deeper into advanced topics in machine learning and artificial intelligence in Semester 2. Don’t miss out on the opportunity to enroll in the two-semester, <Link to="/train-course" className="text-bold text-gold text-italic">Introduction to Artificial Intelligence</Link> course.</Fragment>],
  tagLine: (
    <Fragment>
      Be a part of the course created to train the nation's{" "}
      <span className="text-black">future Responsible AI leaders!</span>
    </Fragment>
  ),
};
