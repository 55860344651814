export const faqs = [
  {
    id: 1,
    qu: "Who is eligible to participate in this course?",
    an: "U.S. high school students in 9th - 12th grades. As our mission is to increase diversity in STEM, students from backgrounds traditionally underrepresented in STEM are encouraged to apply!",
  },
  {
    id: 2,
    qu: "How much homework will be given each week?",
    an: "Homework will be assigned each week at the end of the lecture. Students should be able to complete the homework within 30 minutes - 1 hour.  Optional homework review sessions will be offered each week as well as office hours for extra student support. ",
  },
  {
    id: 3,
    qu: "Do I have to do the homework?",
    an: "Homework is required and does count toward your grade. Homework review sessions will be available for extra support. ",
  },
  {
    id: 4,
    qu: "Do I have to attend all lectures and labs?",
    an: "Attendance is mandatory. Attendance will be taken at the beginning of the lab section and will be factored into your grade. ",
  },
  {
    id: 5,
    qu: "What if I have to miss a class?",
    an: "A recording of the lecture will be available if you have to miss the lecture. For missed lectures or labs due to an emergency, you must email the program team as soon as possible at: train@the-cs.org to request an excused absence. Documentation may be required. ",
  },
  {
    id: 6,
    qu: "What is the grading scale?",
    an: "We believe in creating a failure-free environment focused on learning, so this course is graded on a pass-fail scale unless your school requires a letter grade.",
  },
  {
    id: 7,
    qu: "Is there class during school holidays?",
    an: "The course largely follows school holidays during semester one. There will be no classes or labs during the Thanksgiving week or during winter break.",
  },
];
