import Stanford from "assets/img/common/stanford.png";
import Jhu from "assets/img/common/jhu.svg";

export const coursesPartner = {
  title:
    "Course created in collaboration with educational experts and engineers at",
  logos: [
    { img: Stanford, alt: "Stanford" },
    { img: Jhu, alt: "Jhu" },
  ],
};
