export const ArrowDown = (svgClass) => {
  return (
    <svg
      className={svgClass}
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="KeyboardArrowDownIcon"
      width="24"
      height="24"
    >
      <path
        d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
        fill="black"
      ></path>
    </svg>
  );
};
