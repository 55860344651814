import classNames from "classnames";
import styles from "./styles.module.scss";

export const Accredited = (props) => {
  const { data } = props;
  return (
    <section className={styles.accreditedSection}>
      <div className={classNames(styles.containerBig, "container")}>
        <div className={styles.accreditedWrap}>
          <div className={classNames(styles.content, "text-center")}>
            <h4 className={styles.sectionTitle}>{data?.mainTitle}</h4>
            <h5>{data?.title}</h5>
            <p>{data?.desc}</p>
            <ul className="list-unstyled list-inline mb-0">
              {data?.img?.map((item, idx) => (
                <li className="list-inline-item" key={idx}>
                  <img src={item?.src} alt={item?.alt} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
