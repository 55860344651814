import classNames from "classnames";
import { ArrowDown } from "component/Icons";
import styles from "./styles.module.scss";

export const Faq = (props) => {
  const { faqs } = props;
  return (
    <div className={styles.trainCourseFaq}>
      <div className="container">
        <div id="main-accordion">
          <div className={classNames(styles.card, "card")}>
            <div
              className={classNames(styles.cardHeader, "card-header p-0")}
              id="ma-one"
            >
              <button
                className={styles.maTitle}
                data-toggle="collapse"
                data-target="#ma-one-content"
                aria-expanded="false"
                aria-controls="ma-one-content"
              >
                <h3
                  className={classNames(styles.heading, "text-blue-gradient")}
                >
                  FAQs
                </h3>
                <ArrowDown />
              </button>
            </div>

            <div
              id="ma-one-content"
              className="collapse mt-30"
              aria-labelledby="ma-one"
              data-parent="#main-accordion"
            >
              <div className="card-body">
                <div id="accordion">
                  {faqs.map((item, idx) => {
                    return (
                      <div
                        className={classNames(styles.card, "card")}
                        key={idx}
                      >
                        <div
                          className={classNames(
                            styles.cardHeader,
                            "card-header p-0"
                          )}
                          id={"faq-" + idx}
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn collapsed"
                              data-toggle="collapse"
                              data-target={"#tc-faq" + idx}
                              aria-expanded="false"
                              aria-controls="tc-faq"
                            >
                              <span className={styles.chText}>{item.qu}</span>
                              <ArrowDown />{" "}
                            </button>
                          </h5>
                        </div>

                        <div
                          id={"tc-faq" + idx}
                          className={classNames(styles.tcCollapse, "collapse")}
                          aria-labelledby={"faq-" + idx}
                          data-parent="#accordion"
                        >
                          <div
                            className={classNames(styles.cardBody, "card-body p-20")}
                          >
                            <p>{item.an}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
