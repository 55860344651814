import classNames from "classnames";
import styles from "./styles.module.scss";

export const WhatStudentsLearn = (props) => {
  const { data } = props;
  return (
    <div className={styles.whatStudentsLearn}>
      {data?.descriptions?.map((item, idx) => {
        return (
          <p className={styles.keyDetailsDesc} key={idx}>
            {item}
          </p>
        );
      })}
      {data?.topicsCovered && (
        <p className={styles.keyDetailsDesc}>{data?.topicsCovered}</p>
      )}
      <ul className={styles.keyTopicsList}>
        {data?.pointsList?.map((item, idx) => {
          return (
            <li key={idx}>
              <i className={classNames(styles.icon, "fa-solid fa-brain")}></i>
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
