import { Link } from "react-router-dom";
import { keyPost } from "data";

import styles from "./styles.module.scss";

export const KeyInitiatives = () => {
  return (
    <div className={styles.KeyInitiatives + " mb-80"}>
      <div className={styles.containerBig + " container"}>
        <h3 className="text-center mb-30">Key Initiatives</h3>
        <div className="row">
          {keyPost.map((item, idx) => {
            return (
              <div
                className="col-lg-4 mb-30 mb-lg-0"
                key={idx}
                data-aos="fade-up"
                data-aos-duration={1000 * (idx + 1)}
              >
                <div className={styles.postBox}>
                  <div className={styles.postBoxWrap}>
                    <h5 className={styles.title}>{item["subTitle"]}</h5>
                    <img src={item["img"]} alt="students" />
                    <p className={styles.desc}>{item["title"]}</p>
                    {item["isExternal"] ? (
                      <a
                        href={item["link"]}
                        className="btn btn-gradient-2 text-white text-upper rounded-0 px-20 py-10"
                      >
                        Learn More
                      </a>
                    ) : (
                      <Link
                        to={item["link"]}
                        className="btn btn-gradient-2 text-white text-upper rounded-0 px-20 py-10"
                      >
                        Learn More
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
